import {
  ValidationErrorForMultipleObjects,
  ValidationErrorsForOneObject,
  ValidationErrorsGeneric,
} from '@/models/error.model'
import axios from 'axios'
import { nextTick } from 'vue'
import { useToast } from 'vue-toastification'
import i18n from '../i18n'

export const isValidationError = (error: unknown): boolean => {
  /** Returns true if input is a django validation response */
  if (axios.isAxiosError(error) && error?.response?.status === 400) {
    return true
  }
  return false
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValidationErrorData = (
  error: any,
):
  | ValidationErrorsGeneric
  | ValidationErrorsForOneObject
  | ValidationErrorForMultipleObjects
  | undefined => {
  if (isValidationError(error)) {
    return error?.response?.data
  }
  return undefined
}

export const is406Error = (error: any) => {
  return axios.isAxiosError(error) && error?.response?.status === 406
}

export const toast403orDefault = (
  error: any,
  errorText: string,
  noDefault = false,
) => {
  if (axios.isAxiosError(error)) {
    let msg
    if (error?.response?.status === 403) {
      msg = i18n.global.t('noRightsErrorMsg')
    } else {
      if (noDefault) {
        return true
      }
      msg = errorText
    }
    useToast().error(msg)
    return true
  }
  return false
}

export const flattenValidationErrors = (
  errors: ValidationErrorsGeneric | string | undefined | null,
): string[] => {
  const result: string[] = []
  if (errors === undefined || errors === null) {
    // pass
  } else if (typeof errors === 'string') {
    result.push(errors)
  } else if (Array.isArray(errors)) {
    for (const itemErrors of errors) {
      const itemResult: string[] = flattenValidationErrors(itemErrors)
      result.push(...itemResult)
    }
  } else if (typeof errors === 'object') {
    for (const propertyErrors in errors) {
      const propertyResult: string[] = flattenValidationErrors(
        errors[propertyErrors],
      )
      result.push(...propertyResult)
    }
  } else {
    console.warn(`flattenValidationErrors | Unexpected value: ${errors}`)
  }

  return result
}

export const scrollFirstErrorIntoView = (defaults: {
  additionalQueries: string[]
}) => {
  const errorQueries: string = [
    '.input-error', // used in InputErrorMessages.vue
    '.alert-banner--danger', // AlertBanner.vue with danger type
    ...(defaults?.additionalQueries ?? []),
  ].join(', ')
  nextTick().then(() => {
    const errorElement = document.querySelector(errorQueries)
    errorElement?.scrollIntoView({ behavior: 'smooth' })
  })
}

export interface IGenericErrorHandlerOptions {
  hideValidationErrors?: boolean
  defaultMessage?: string
}

const _genericErrorHandler = (
  error: any,
  options: IGenericErrorHandlerOptions | undefined,
) => {
  let errorMessage = options?.defaultMessage ?? i18n.global.t('dataLoadError')
  const validationErrors = getValidationErrorData(error)

  const isShowDefaultOnly = options?.hideValidationErrors ?? false
  if (!isShowDefaultOnly && !!validationErrors) {
    const flattenedErrors = flattenValidationErrors(validationErrors).join('. ')
    errorMessage = `${errorMessage}: ${flattenedErrors}`
  }
  toast403orDefault(error, errorMessage)
  return validationErrors
}

export const genericFetchErrorHandler = (
  error: any,
  options: IGenericErrorHandlerOptions | undefined,
) => {
  options = {
    defaultMessage: i18n.global.t('dataLoadError'),
    ...(options ?? {}),
  }
  return _genericErrorHandler(error, options)
}

export const genericCreateErrorHandler = (
  error: any,
  options: IGenericErrorHandlerOptions | undefined,
) => {
  options = {
    defaultMessage: i18n.global.t('dataSaveError'),
    ...(options ?? {}),
  }
  return _genericErrorHandler(error, options)
}

export const genericUpdateErrorHandler = (
  error: any,
  options: IGenericErrorHandlerOptions | undefined,
) => {
  options = {
    defaultMessage: i18n.global.t('dataSaveError'),
    ...(options ?? {}),
  }
  return _genericErrorHandler(error, options)
}

export default {
  isValidationError,
  getValidationErrorData,
  flattenValidationErrors,
  is406Error,
  scrollFirstErrorIntoView,
  genericFetchErrorHandler,
}

export default {
  "CheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
  "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "Search for address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsige aadressi"])},
  "When": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millal"])},
  "aboutTheExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täpsem info"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võtan vastu"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastu võetud"])},
  "access": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligipääsetavus"])},
    "byCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sõiduautoga"])},
    "byPublicTransport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühistranspordiga"])},
    "byTourBus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekskursioonibussiga"])},
    "isHardSurfacedRoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõvakattega juurdepääsutee"])},
    "isSignPosted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viidastatud või tähistatud"])},
    "onFoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jalgsi"])}
  },
  "activation": {
    "activateUserError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutaja aktiveerimine ebaõnnestus"])},
    "activateUserSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutaja edukalt aktiveeritud"])},
    "invalidActivationCodeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittesobiv aktiveerimiskood"])}
  },
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa"])},
  "addAnotherDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa allahindlus"])},
  "addAnotherHousingUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa majutusüksus"])},
  "addAnotherPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa veel üks periood"])},
  "addAnotherPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa uus hind"])},
  "addAnotherUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa uus kasutaja"])},
  "addAttachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa faile"])},
  "addBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa ettevõte"])},
  "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa uus"])},
  "addNewBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa uus ettevõte"])},
  "addNewClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa uus klient"])},
  "addNewResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa uus ressurss"])},
  "addNewTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa uus töökäsk"])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa ostukorvi"])},
  "addToCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukalt lisatud ostukorvi"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisatud"])},
  "addedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisamise kuupäev"])},
  "additionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisainfo"])},
  "additionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisateenused / tooted"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aadress"])},
  "address_": {
    "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maja"])},
    "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maakond"])},
    "farmApartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talu/korter"])},
    "villageSuburbTown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küla/linn"])}
  },
  "admin": {
    "companyListPage": {
      "filters": {
        "showTestCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva test teenusepakkujaid"])}
      },
      "headers": {
        "isTestCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test teenusepakkuja"])},
        "lastUserLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimane sisselogimine"])},
        "publishedServicesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaldatud müügiartikleid"])},
        "servicesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikleid"])},
        "usersCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutajaid"])}
      },
      "updateIsTestCompanyErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Teenusepakkuja \"", _interpolate(_named("company")), "\" test staatuse uuendamine ebaõnnestus:"])},
      "updateIsTestCompanySuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Teenusepakkuja \"", _interpolate(_named("company")), "\" test staatuse edukalt uuendatud."])}
    },
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "postponedRefundsPage": {
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loomisaeg"])},
      "retryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proovi uuesti"])},
      "retryRefundErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagastuse korduskatse ebaõnnestus"])},
      "retryRefundSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagastuse korduskatse õnnestus"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edasilükatud tagastused"])}
    },
    "tabs": {
      "commissionReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komisjoni aruanne"])},
      "depositReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagatiste aruanne"])},
      "providerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenusepakkujad"])}
    }
  },
  "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täiskasvanuid"])},
  "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mugavused"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogus"])},
  "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korter"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhiveeri"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olete te kindel?"])},
  "arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saabumine"])},
  "attendants:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osalejate arv:"])},
  "automatically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automaatne"])},
  "availableTickets": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jäänud on ", _interpolate(_named("maxGroupSize")), " vaba kohta"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasi"])},
  "baseMultimedia": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saad lisada, pealkirjastada, muuta kuvamisjärjekorda, kustutada pilte."])}
  },
  "basicInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üldine info"])},
  "bed_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voodi"])},
  "beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voodit"])},
  "bedsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voodi(d)"])},
  "beneficialOwners": {
    "beneficialOwners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegelikud kasusaajad"])},
    "beneficialOwnersLocationInAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täpsema informatsioon tegelike kasusaajate kohta on välja toodud järgnevas seadusaktis paragrahvis 9"])},
    "linkToAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesti rahapesu ja terrorismi rahastamise tõkestamise seadus"])},
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegelikke kasusaajaid pole täpsustatud."])},
    "whyWeCollectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touringery kogub teavet teenusepakkujate tegelike kasusaajate kohta vastavalt kohalikele seadustele ja meie tehingute töötlejate nõuetele."])}
  },
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arveldus"])},
  "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sünniaeg"])},
  "bookKeeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raamatupidamine"])},
  "book_keeping": {
    "goToTrigonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisene Trigoni raamatupidamisprogrammi"])}
  },
  "bookingCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broneering edukalt loodud"])},
  "bookingCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Broneeringu loomine ebaõnnestus"])},
  "bookingForm": {
    "buttons": {
      "addBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa broneering"])}
    },
    "clientSection": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi informatsioon"])}
    },
    "editExistingClientAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te uuendate olemasoleva kliendi kontaktandmeid uue loomise asemel.\nUue kliendi loomiseks tuleb tühjendada aktiivne kliendi valik."])},
    "fields": {
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestvus"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastajate arv"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikkel"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuupäev"])},
      "timeslotDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broneeringu kestvus"])},
      "timeslotStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broneeringu algus"])},
      "timeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aeg"])}
    },
    "loadingDatePickerTimeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laeb saadavusi"])},
    "noTimeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei leidnud vabu aegu"])},
    "removeClientLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eemalda klient"])},
    "serviceSection": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikkel"])}
    },
    "timeslotsNotLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali müügiartikkel ja siis kuupäev, et näha vabu aegu"])}
  },
  "bookingIntegration": {
    "activateConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveeri seos"])},
    "activateRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveeri tubade seosed"])},
    "bookingAgreeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mõistan liidestuse piiranguid ja mõju Booking.com-i kanalile ja nõustun nendega"])},
    "bookingProperty": {
      "checkPropertyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuenda ühenduse olekut"])},
      "refreshRoomRatesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värskendage tube ja hinnaplaane"])}
    },
    "cancelConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustuta seos"])},
    "cancelRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustuta tubade seosed"])},
    "closedRestrictionsCheckboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen kontrollinud oma sulgemise piiranguid saidil Booking.com"])},
    "completedErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookingu liidestust ei õnnestunud lõpule viia. Ühendage seosed lahti ja proovige uuesti või võtke ühendust kasutajatoega"])},
    "completedSuccessMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bookingu liidestus on edukalt lõpule viidud. Loodi ", _interpolate(_named("new_reservations_created")), " uut reserveeringut"])},
    "connectRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühendage tubadega"])},
    "connectToPropertyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looge ühendus majutusüksusega"])},
    "connecting": {
      "activatePropertyFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nüüd, kui vajalikud toimingud saidil Booking.com on tehtud, on aeg hakata meie poolel ühendust looma ja kaardistama."])},
      "activatePropertySecondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esimene asi on luua ühendus oma majutusasutusega saidil booking.com. \nSelleks tuleb booking.com-ist sisestada oma majutusasutuse ID ja vajutada \"Aktiveeri seos\"."])},
      "activatePropertyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusasutusega ühendamine"])},
      "activateRoomsFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui ühendus on aktiveeritud, on aeg kaardistada oma majutusasutuse ruumid Touringerys vastavate majutustega. \nMajutuste aktiveerimiseks peate kaardistama kõik loetletud toad booking.com-st."])},
      "activateRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruumide kaardistamine"])},
      "activeConnectionFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pärast ruumide edukat kaardistamist ja ühendamist hakkab Touringery saadavuse, hindade ja piirangute andmeid booking.com-iga sünkroonima. \nSünkroonimise kiirus sõltub üldisest liidestuse aktiivsusest ja kõigi ühenduste sünkroonimiseks vajalike värskenduste arvust."])},
      "activeConnectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivne ühendus"])},
      "closedRestrictionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enne liidestuse seadistamist on vaja olemasolevad suletud piirangud eemaldada. \nSee on vajalik, kuna API ei võimalda meil varasemaid suletud piiranguid eemaldada ja neid ei ole võimalik Booking.com-i kalendri kaudu eemaldada, kui ühenduvuse pakkuja on aktiveeritud."])},
      "closedRestrictionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suletud piirangud"])},
      "connectToConnectivityProviderLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühenduvuse pakkuja seadistamine ja töötamine"])},
      "connectivityProviderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esimene asi, mida tuleb teha, on ühenduse loomine meie toetatud ühenduvus pakkujaga saidil Booking.com."])},
      "connectivityProviderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühenduvuse pakkuja"])},
      "connectivityProviderZodomusText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toetatud ühenduvuse pakkuja nimi on \"Zodomus\"."])},
      "connectivityTutorialLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selleks vajalikke samme tutvustatakse järgmises Booking.com-i artiklis"])},
      "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selle liidestuse jaoks eeldame, et teil on juba olemasolev Booking.com-i majutusasutus koos ruumide ja nende hinnaplaanidega."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuidas ühendada"])}
    },
    "deleteConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustuta seos"])},
    "description": {
      "centralizedToTouringeryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See integratsioon võimaldab teil Touringerery kaudu hallata oma kinnisvara saadavust, hinda ja piiranguid."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjeldus"])}
    },
    "housingHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali bookingu toale vastav Touringery majutus"])},
    "limitations": {
      "bookingReservationsFunctionalityText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com'is tehtud reserveeringuid kuvatakse Touringery kalendris. \nSamuti on võimalik näha põhilist klienditeavet. Kirjapõhjade ja check-in funktsionaalsust rakendatakse ka nendele reserveeringutele."])},
      "durationPriceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestuspõhiseid hinnamõjusi ei jagata Booking.com-iga. \nNeed mõjutavad hindu ainult Touringery-s."])},
      "initialConnectionUpdatesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esialgsel ühendusel on vaja kõige rohkem värskendusi, kuna kogu hinnakujundus ja saadavus tuleb sel hetkel sünkroonida. \nVärskenduste arv sõltub teie ühendatud majutuste hinnaperioodide keerukusest ja olemasolevatest Touringery reserveeringutest."])},
      "numberOfUpdatesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värskenduste arv"])},
      "priceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnad"])},
      "reservationsLimitedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uued ja olemasolevad tulevased reserveeringud sünkroonitakse Booking.com-ist Touringeryga, kuid nende funktsionaalsus on piiratud meie süsteemis."])},
      "reservationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservatsioonid"])},
      "standardPriceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meie majutuse hinnaperioodidel määratud täishinnad määratakse Booking.com-i tubade hinnaplaanides standardhinnaks."])},
      "standardPricingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com pakub mitut hinnamudeli tüüpi, millest toetame ainult standardset hinnamudelit."])},
      "syncBookingReservationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com-ist tehtud reserveeringute värskendusi hallatakse eraldi ning need ei ole mõjutatud eelnevalt mainitud järjekorrast."])},
      "syncOnDemandText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pärast seda toimub sünkroonimine, kui Touringerys toimub asjakohaseid muudatusi. \nAntud muudatuste hulka kuuluvad näiteks muutused majutuse tegutsemisaegades, hinnastuses, online müügi lõpus ja seotud reserveeringutes."])},
      "syncingFrequencyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touringery muudatuste sünkroonimine Booking.com-iga ei toimu koheselt. \nKõik värskendused lähevad jagatud järjekorda, mis tähendab, et teie Booking.com-i majutusasutuse uuendamiseks kuluv aeg sõltub teie värskenduste positsioonist antud järjekorras."])},
      "syncingTimeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värskendamise sagedus"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piirangud"])}
    },
    "propertyActivationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusasutuse aktiveerimisel tekkis viga"])},
    "propertyActivationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusasutus on edukalt aktiveeritud"])},
    "propertyCancellationnError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seose kustutamine ebaõnnestus"])},
    "propertyCancellationnSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seos edukalt kustutatud"])},
    "propertyHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com keskkonnaga sidumiseks sisestage esmalt oma ettevõtte ID booking keskkonnas. Selle leiate Booking.com admin-lehe päisest logo kõrvalt."])},
    "propertyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusasutuse ID"])},
    "roomIdLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com toa ID"])},
    "roomsActivationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutuste seosed on edukalt aktiveeritud"])},
    "roomsctivationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutuste seoste loomisel tekkis viga"])},
    "statusActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveeritud"])},
    "statusError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veaseisundis"])},
    "statusInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteaktiivne"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com kanalihaldur"])},
    "updateRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuenda tubade seoseid"])},
    "zodomusConnectedCheckboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen määranud Zodomuse ühenduvuse pakkujaks saidil Booking.com"])}
  },
  "bookingWidget": {
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa ostukorvi"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali"])},
    "chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitud"])},
    "errors": {
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun veenduge, et valitud kuupäev/ajavahemik on vaba"])},
      "consecutiveSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun veenduge, et valitud ajavahemik oleks järjestikku"])},
      "slotsMaxDuration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Palun veenduge, et valitud ajavahemik poleks pikem kui ", _interpolate(_named("maxDuration"))])},
      "slotsMinDuration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Palun veenduge, et valitud ajavahemik oleks vähemalt ", _interpolate(_named("minDuration")), " pikk"])}
    },
    "goToBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine ostma"])},
    "groupSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi suurus"])},
    "noAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sellel ajavahemikul pole saadavust"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulemusi pole"])},
    "pickService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige teenus, et näha saadavust"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eemalda"])},
    "whatWillYouBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mida soovite osta?"])},
    "wholeDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " (kogu päev)"])}
  },
  "bookingsCalendar": {
    "onlyTransactionsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näita ainult tehingutega"])},
    "reservationDetails": {
      "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastaja"])},
      "openDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ava broneering"])},
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringu päritolu"])},
      "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erisoovid"])},
      "seeAllBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva kõiki külastaja broneeringuid"])},
      "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koguhind"])}
    }
  },
  "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maja"])},
  "businessDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte kirjeldus"])},
  "businessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte andmed"])},
  "businessName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte nimi"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loobu"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühista reserveering"])},
  "cancelMessageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selgitage tühistamise põhjust"])},
  "cancelOrder": {
    "cancelServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuse tühistamisel tekkis viga"])},
    "cancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimus edukalt tühistatud"])},
    "cannotCancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seda tellimust ei saa hetkel tühistada"])},
    "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas olete kindel, et soovite tellimuse tühistada?"])},
    "confirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühista tellimus"])},
    "removeOrderItemConfirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas olete kindel, et soovite selle osa tellimusest eemaldada?"])},
    "removeOrderItemConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eemalda tellimusest"])},
    "removeOrderItemServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimusest eemaldamisel tekkis viga"])},
    "removeOrderItemSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimusest edukalt eemaldatud"])}
  },
  "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahutavus"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostukorv"])},
  "cartOverview": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ülevaade tellimusele #", _interpolate(_named("referenceNumber"))])},
  "cartPage": {
    "cartConfirmErrorAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostukorvi kinnitamisel tekkis viga"])},
    "cartConfirmSuccessAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostukorv edukalt kinnitatud"])},
    "cartConfirmedOrderExpiresAtAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ostukorv on kinnitatud ning see aegub kell ", _interpolate(_named("expiresAt")), "."])},
    "clientConfirmedOrderExpiresAtAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kliendi informatsioon on lisaks ostukorvile kinnitatud ning see aegub kell ", _interpolate(_named("expiresAt")), "."])},
    "noItemsInCartText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostukorv on tühi"])},
    "orderStatusCancelledAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimus on tühistatud."])},
    "orderStatusPaidAlertWithTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimus on makstud olekus. Lae alla piletid."])},
    "orderStatusPaidAlertWithoutTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimus on makstud olekus."])},
    "orderStatusPendingAlertWithTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehing on edukalt lõpule viidud. lõplik kinnitus tuleb viivitusega e-mailile kohale. Lae alla piletid."])},
    "orderStatusPendingAlertWithoutTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehing on edukalt lõpule viidud. lõplik kinnitus tuleb viivitusega e-mailile kohale."])},
    "orderStatusTimedOutAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimus on aegunud."])}
  },
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategooriad"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategooria"])},
  "ceoOrBoardMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegevjuht võï juhatuse liige"])},
  "changeHousingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuda majutuse andmeid"])},
  "channel": {
    "activateChannelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaliga ühendamine õnnestus."])},
    "activateRoomsSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksuse toad ühendatud edukalt."])},
    "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])}
  },
  "channels": {
    "activateChannelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaliga ühenduse loomine ebaõnnestus."])},
    "activatePropertyRoomsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühenda toad"])},
    "activateRoomsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksuse tubade ühendamine ebaõnnestus."])},
    "booking-widget-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalik vaade"])},
    "bookingActivation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uus Booking.com kanal"])}
    },
    "bookingProperty": {
      "checkPropertyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühenduse oleku värskendamine ebaõnnestus."])},
      "checkPropertySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühenduse olek uuendatud."])},
      "refreshRoomRatesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksuse tubade ja nendega seotud hinnaplaanide värskendamine ebaõnnestus."])},
      "refreshRoomRatesSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksuse tubade ja nendega seotud hinnaplaanide värskendamine õnnestus."])}
    },
    "bookingPropertyChannelTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Majutusüksus ", _interpolate(_named("property"))])},
    "channel-manager-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalihaldur"])},
    "confirmPropertyDeactivationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas olete kindel, et soovite selle kanali deaktiveerida?"])},
    "connectPropertyChannelLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühendage"])},
    "connectRoomsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com-i majutusüksuse tubade ühendamiseks peate valima nende standardse hinnaplaani ja vastava majutusartikli."])},
    "deleteChannelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanali kustutamine ebaõnnestus."])},
    "deleteChannelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal edukalt kustutatud."])},
    "fetchPropertyChannelDetailsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanali andmete laadimine ebaõnnestus."])},
    "header": {
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksuse ID"])}
    },
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühtegi kanalit ei leitud."])},
    "noPropertyRoomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksusest ei leitud tube."])},
    "propertyConnectionNeededForRoomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tube kuvatakse peale majutusüksusega ühenduse loomist."])},
    "ratePlanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnaplaan"])},
    "roomHousingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitud majutusartikli majutuste arv peaks olema võrdne või väiksem kui Booking.com ruumil.\nVastasel juhul sünkroniseerimine ebaõnnestus, sest Touringery proovib panna toale Booking.com pooltlubatust suurema saadavuse."])},
    "roomRatePlanInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnaplaan, mida tuleks Touringery andmete põhjal uuendada. \nToetatakse ainult standartseid hinnaplaane."])},
    "roomTitleWithNameAndId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toa ID"])},
    "statusLabel": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivne"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigane"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteaktiivne"])},
      "PROPERTY_ACTIVATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osaliselt ühendatud"])}
    },
    "tabLabel": {
      "bookingWidget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalik vaade"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalid"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalid"])}
  },
  "checkAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsige saadavust"])},
  "checkIn": {
    "aboutClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi andmed"])},
    "aboutClientStaying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui broneering on ostetud teistele, siis ärge valige järgmist valikut. \nKui broneerisite mitu tuba, siis tehke järgnev valik ainult selle broneeringu jaoks, milles kavatsete peatuda."])},
    "aboutTravelCompanions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täitke vorm kõigi isikute kohta, kes teiega koos reisivad"])},
    "aboutTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisi andmed"])},
    "aboutYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinu andmed"])},
    "addTravelCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa reisikaaslane"])},
    "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisaküsimus"])},
    "additionals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisaküsimused"])},
    "additionalsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saate sõnastada check-in vormile lisaküsimusi ja siduda teenustega, mille puhul neid kuvatakse. Lisaküsimuse vastus on max 1000 tm tekstiväli (u 1 lõik teksti)"])},
    "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täiskasvanu"])},
    "ageGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanusegrupp"])},
    "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas olete kindel?"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küsimuse muutmisel muutub see tagasiulatuvalt ka juba salvestatud küsimustele"])},
    "checkInDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in vorm"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laps"])},
    "confirmCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnitan esitatud andmete õigsust"])},
    "detailsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisi üksikasjad"])},
    "downloadForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadi vorm alla"])},
    "estimatedCheckInTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eeldatav saabumisaeg"])},
    "groupSizeHasChanged": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Külastajate arv on muutunud, esialgse ", _interpolate(_named("originalCount")), " asemel on täpsustatud ", _interpolate(_named("guestsPlural")), ". See võib põhjustada muudatusi teenuse pakkumises"])},
    "hoursRange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ajavahemikus ", _interpolate(_named("check_in_hours_range"))])},
    "housingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutuse info"])},
    "isClientStayingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient peatub selles broneeringus"])},
    "isForConferenceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konverentsil osalemine"])},
    "isNotSubmittedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun sisetage andmed ja esitage vorm"])},
    "isSubmittedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorm on esitatud"])},
    "isWrongReservationTypeAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antud broneeringu staatuse korral check-in vormi esitada ei saa"])},
    "openCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostukorv"])},
    "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisi eesmärk"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vormi salvestamisel tekkis viga"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorm edukalt salvestatud"])},
    "serviceSelectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali müügiartikkel, et näha, millsed küsimused sellele rakenduvad"])},
    "statusSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esitatud"])},
    "statusUnSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esitamata"])},
    "tavelCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisikaaslane"])},
    "tavelCompanions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisikaaslased"])},
    "travelData": {
      "clientHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient"])}
    },
    "travelPurpose": {
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])}
    },
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhkus"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töö"])}
  },
  "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapsi"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali"])},
  "chooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali kategooria"])},
  "chooseSubCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali alamkategooria"])},
  "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodakondsus"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linn/vald"])},
  "cityRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küla/alev/linnaosa/väikelinn"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühjenda"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klient"])},
  "clientBookings": {
    "tableHeaders": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuupäev"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestvus"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastajaid"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hind"])},
      "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erisoovid"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikkel"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allikas"])}
    }
  },
  "clientCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi loomine õnnestus"])},
  "clientCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi loomine ebaõnnestus"])},
  "clientInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi andmed"])},
  "clientSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi andmed on salvestastud"])},
  "clientSavedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga kliendi andmete salvestamisel"])},
  "clientTab": {
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arveldus"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broneeringud"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasiside"])}
  },
  "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendid"])},
  "clients_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi nimi"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suletud"])},
  "color": {
    "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinine"])},
    "blue_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsüaansinine"])},
    "blue_purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violett"])},
    "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roheline"])},
    "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oranž"])},
    "purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lilla"])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punane"])},
    "red_orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porgandipunane"])},
    "red_purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magenta"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värv"])},
    "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollane"])},
    "yellow_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šartröösroheline"])},
    "yellow_orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merevaikkollane"])}
  },
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentaar"])},
  "commentUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentaari lisamine ebaõnnestus"])},
  "commentUpdateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentaar lisatud!"])},
  "companyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõte edukalt loodud"])},
  "companyCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ettevõtte loomine ebaõnnestus"])},
  "companyLocation": {
    "confirmDeleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas olete kindel, et soovite selle asukoha kustutada?"])},
    "createLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asukoha loomine ebaõnnestus"])},
    "createLocationModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo asukoht"])},
    "createLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asukoht edukalt loodud"])},
    "deleteLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asukoha kustutamine ebaõnnestus"])},
    "deleteLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asukoht edukalt kustutatud"])},
    "fetchLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asukoha laadimine ebaõnnestus"])},
    "generalBlockTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üldine informatsioon"])},
    "updateLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asukoha värskendamine ebaõnnestus"])},
    "updateLocationModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuenda asukohta"])},
    "updateLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asukoha värskendamine õnnestus"])}
  },
  "companyLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte logo"])},
  "companyNotifications": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte teavitused"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seadistage ettevõtte e-mailile saadetavaid automaatseid teavitusi"])},
    "notification": {
      "eventCancelDelayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmuse tühistamise edasilükkumine"])},
      "eventCancelDelayedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadetakse iga kord, kui sündmuse automaatne tühistamine on ajutiselt edasi lükkunud. Hilinemise põhjuseks võib olla ootel makse."])},
      "eventCancelPassed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmuse minimaalse täituvuse saavutamine"])},
      "eventCancelPassedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadetakse, kui sündmuse minimaalne osalejate arv on automaatse tühistamise kontrolli ajal täitunud. \nSee tähendab, et antud sündmuse puhul on automaatne tühistamine nüüd välja lülitatud."])},
      "eventCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmuse tühistamine"])},
      "eventCancelledDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadetakse iga kord, kui sündmus automaatselt tühistatakse"])},
      "orderPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uue tellimuse teavitus"])},
      "orderPaidDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadetakse iga kord, kui teenusepakkuja müügiartiklite eest on tasutud"])},
      "partnerInviteResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerluse kutse vastus"])},
      "partnerInviteResponseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadetakse, kui partnerluse kutsele on vastatud"])},
      "partnerPayoutChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerluse väljamaksete osa muutus"])},
      "partnerPayoutChangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadetakse juhul, kui partner on oma väljamaksete protsenti muutnud"])},
      "partnerStatusChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerluse staatuse muutus"])},
      "partnerStatusChangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadetakse juhul, kui partnerluse staatus on muutunud"])},
      "reservationCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringu tühistamine"])},
      "reservationCancelledDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadetakse iga kord, kui klient on oma reserveeringu tühistanud"])}
    },
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada e-mail"])}
  },
  "companyRole": {
    "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa roll"])},
    "alertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin rolli ei saa muuta ega kustutada. Ettevõttes peab olema vähemalt üks admin-rolliga kasutaja"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo uus"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugemine"])},
    "removeRoleConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas olete kindel, et soovite rolli kustutada?"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutmine"])}
  },
  "companyRoles": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
    "permissios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Õigused"])}
  },
  "compucash": {
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash kliendi nimi"])},
    "clientSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash kliendi saladus"])},
    "dataIsStillSyncing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucashi andmed alles uuenevad, palun tulge hiljem tagasi."])},
    "deleteProduct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kustuta Compucashi toode ", _interpolate(_named("productId")), " - ", _interpolate(_named("productName"))])},
    "deleteProductConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas olete kindel, et soovite toote kustutada?"])},
    "deleteProductInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucashi toote kustutamine eemaldab tooteinfo, mis on salvestatud Touringery baasi. Toote kustutamisel EI kustutata seotud Touringery müügiartiklit."])},
    "doubleCheckCompucashData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun kontrollige üle Compucashiga ühendamise andmed. Rohkema info saamiseks võtke ühendust Touringery või Compucashiga."])},
    "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andmed, mis on vajalikud Compucash integratsiooni jaoks, et sünkroonida tooted Compucashist Touringery-sse. Vajalike väärtuste saamiseks palume kontakteeruda Compucashiga."])},
    "makeIntoService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühenda müügiartikliga"])},
    "makeIntoServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühenda Compucashi tooted müügiartiklitega"])},
    "newServiceForCompucashProduct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uus teenus Compucashi tootele ", _interpolate(_named("productId"))])},
    "productDeprecatedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimane Compucash'i sünkroonimisvastus ei sisaldanud seda toodet, mis võib olla tingitud toote kustutamisest või veebimüügi keelamisest. Seotud müügiartikkel on muudetud privaatseks, et vältida toote müüki. Kui te ei vaja enam müügiartiklit, on teil võimalus see müügiartikli seadetes arhiveerida. Kui see on viga, kontrollige toodet Compucashis."])},
    "productID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toote ID"])},
    "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toote nimi"])},
    "serviceSettingsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucashi toote seaded"])},
    "serviceSettingsHeaderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui teenusepakkujal on ühendus Compucashiga, siis on siin võimalus ühendada juba avaldatud müügiartikkel Compucashi tootega"])},
    "serviceSettingsUserAddedRelatedCompucashId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toote ID Compucashis"])},
    "serviceSettingsUserAddedRelatedCompucashIdInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See on Compucashi toote ID, mida kasutatakse Touringery olemasoleva toote sünkroonimiseks Compucashiga, selle asemel et luua uus."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staatus"])},
    "sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sünkroniseeri"])},
    "syncFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucashi sünkroniseerimine ebaõnnestus. Palun võtke ühendust Touringeryga."])},
    "syncFinishedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucashi sünkroniseerimine lõppes edukalt."])},
    "syncStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sünkroniseerimine taustal alustatud"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash integratsiooni seaded"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oodatav KM"])},
    "withoutServiceToggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näita ainult tooteid ilma müügiartiklita"])}
  },
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnita"])},
  "confirmAndPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksma"])},
  "confirmDialog": {
    "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oled sa kindel?"])}
  },
  "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnitus"])},
  "confirmationEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuse e-mail edukalt saadetud"])},
  "confirmationEmailSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-maili saatmisega tekkis probleeme"])},
  "contactServiceProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktinfo"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võta ühendust"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine edasi"])},
  "coordinates": {
    "DD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD (kümnendkraadid)"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koordinaadid"])}
  },
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopeeritud!"])},
  "copyUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopeeri URL"])},
  "countdown": {
    "inPast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minevikus"])}
  },
  "countryOfResidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentsusriik"])},
  "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maakond"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo konto"])},
  "createBulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo hulgi"])},
  "createNewResourceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo uus grupp"])},
  "createServiceDraftError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli mustandi loomine ebaõnnestus"])},
  "currentCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valitud"])},
  "customerLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa kliendi sildid"])},
  "customerLabelsAndComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi sildid ja kommentaar"])},
  "customerRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnang kliendile"])},
  "dataIsSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andmed sisesstatud!"])},
  "dataLoadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andmete laadimine ebaõnnestus"])},
  "dataSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andmete salvestamine ebaõnnestus"])},
  "dataSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andmete salvestamine õnnestus"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuupäev"])},
  "dayNoHourOption": {
    "fromTheStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päeva algusest"])},
    "untilEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päeva lõpuni"])}
  },
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päeva"])},
  "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpptähtaeg"])},
  "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhver"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustuta"])},
  "deleteClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustuta klient"])},
  "deleteResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustuta ressurss"])},
  "deleteTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustuta töökäsk"])},
  "departure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahkumine"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjeldus"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täpsustused"])},
  "disableTrackList": {
    "addItemModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uus sulgemine"])}
  },
  "disabledTrackItem": {
    "allExcludedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõik"])},
    "confirmDeleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas olete kindel?"])},
    "deleteTrackErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulgemise kustutamine ebaõnnestus."])},
    "deleteTrackSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulgemine edukalt kustutatud."])},
    "exclusionTypeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulgemise tüüp"])},
    "form": {
      "endLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpp (välja arvatud)"])},
      "startLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algus (sisse arvatud)"])}
    },
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulgemine"])},
    "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogus"])},
    "trackType": {
      "fullDisable": {
        "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulge kõik külastajad"])},
        "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulge kõik üksused"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulge kõik"])}
      },
      "quantityDisable": {
        "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulge mõned külastajad"])},
        "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulge osad üksused"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulge osad"])}
      }
    },
    "updateItemErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulgemise uuendamine ebaõnnestus."])},
    "updateItemSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulgemine edukalt uuendatud."])},
    "updateModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuenda sulgemist"])}
  },
  "disabledTrackList": {
    "addItemButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa sulgemine"])},
    "createItemErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulgemise loomine ebaõnnestus."])},
    "createItemSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulgemine edukalt loodud."])},
    "experience": {
      "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastajaid"])}
    },
    "housing": {
      "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksused"])}
    },
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulgemisi ei ole."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulgemised"])}
  },
  "discount": {
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanus"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allahindlus"])},
    "inDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päevades"])},
    "inHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tundides"])},
    "numOfDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päevade arv"])},
    "numOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastajate arv"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hind"])},
    "untilDaysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (mitu päeva)"])},
    "untilGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (mitu külastajat)"])},
    "untilSumLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (hinna suurus)"])},
    "untilYearLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (mitu eluaastat)"])}
  },
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soodustused"])},
  "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distants"])},
  "distanceToStop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaugus peatuseni"])},
  "documentExpDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument kehtib kuni"])},
  "documentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumendi number"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehtud"])},
  "downloadInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadi arve alla"])},
  "downloadTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lae alla pilet"])},
  "downloadTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lae alla piletid"])},
  "dragHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["või lohista siia"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopeeri"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestvus"])},
  "durationManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestvus manuaalselt"])},
  "durationPicker": {
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestvus"])}
  },
  "durations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestvused"])},
  "dynamicNameTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " kokku"])},
  "dynamicNight": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ööd"]), _normalize(["öö"]), _normalize(["ööd"])])},
  "dynamicNightWithCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " ööd"]), _normalize([_interpolate(_named("count")), " öö"]), _normalize([_interpolate(_named("count")), " ööd"])])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuda"])},
  "editCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukalt muudetud"])},
  "editUsers": {
    "changeRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaheta rolli"])},
    "editUsersLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuda kasutajat"])},
    "goToRolesView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine rollide vaatesse"])},
    "resendInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada kutse uuesti"])}
  },
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posti aadress"])},
  "emailNotificationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajastatud e-kirjad"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpp"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpukuupäev"])},
  "erfLogo": {
    "altText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Euroopa Regionaalarengu Fondi logo"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmus"])},
  "eventCalendarDetails": {
    "eventReservationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringud"])},
    "noEventPeriodsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmusi ei ole."])},
    "noReservationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringud puuduvad."])}
  },
  "eventCancelAtInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toimub juhul kui ", _interpolate(_named("minCapacity")), " piletit kokku on ostetud hiljemalt ", _interpolate(_named("cancelDuration")), " pärast."])},
  "eventCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmus loomine õnnestus"])},
  "eventDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali, kui soovid luua uue sündmuse. Sündmus on ühekordne müügiartikkel, mis toimub kindlal sinu poolt määratud ajal."])},
  "eventError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmus loomine ebaõnnestus"])},
  "eventOrderItemRefundableUntil": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Täies mahus tagastatav kuni ", _interpolate(_named("until")), " (2 päeva enne sündmust). Pärast seda tühistamisel raha ei tagastata."])},
  "eventResultCard": {
    "priceForGroupText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hind ", _interpolate(_named("groupSize")), "-le"])}
  },
  "eventService": {
    "eventIsCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmus on tühistatud."])},
    "eventSalesEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piletimüük sündmusele on lõppenud."])}
  },
  "eventUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmus uuendamine ebaõnnestus"])},
  "eventUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmus uundamine õnnestus"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmused"])},
  "eventsCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmuste loomine õnnestus"])},
  "eventsCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmuste loomine ebaõnnestus"])},
  "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenus"])},
  "experienceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali, kui soovid luua uue teenuse."])},
  "experienceSearch": {
    "calendarLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laeb saadavusi. See võib veidi aega võtta."])}
  },
  "experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenused"])},
  "farm/apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talu/korter"])},
  "feedbackSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasiside saatmine õnnestus"])},
  "feedbackSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasiside saatmine ebaõnnestus"])},
  "fileUploadFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faili üleslaadimine ebaõnnestus!"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesnimi"])},
  "fixedTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fikseeritud ajavahemik"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parool ununes?"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alates"])},
  "full_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täielik aadress"])},
  "gender": {
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naine"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugu"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mees"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])}
  },
  "general_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üldine info"])},
  "general_information_unfilled_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üldine info peab olema enne täidetud ja salvestatud, et saaksite vormiga edasi minna"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereeri"])},
  "generatedEventsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereeritud sündmused"])},
  "goToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine ostukorvi"])},
  "googleCalendar": {
    "eventModal": {
      "relatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud ressurss"])},
      "relatedService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud teenus"])}
    },
    "settings": {
      "authError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google kontosse sisse logimisel tekkis viga"])},
      "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi sisse"])},
      "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touringery kalendrite endga Google kalendrisse sünkroniseerimiseks tuleb end Google kontoga sisse logida ja tingimustega nõustuda."])},
      "modal": {
        "calendarName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
        "calendarType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendri liik"])},
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui kaua enne sündmuse algust?"])},
        "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näide"])},
        "exampleHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhkemaja"])},
        "exampleHousingUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["majutusüksused 1,2"])},
        "exampleTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koristus"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesnimi"])},
        "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täisnimi"])},
        "googleCalendarDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google kalendri kirjeldus"])},
        "googleCalendarEventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google kalendri sündmuse nimetus"])},
        "googleCalendarSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google kalendri seadistused"])},
        "googleCalendarSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google kalendri nimetus"])},
        "groupSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi suurus"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perenimi"])},
        "moneyjarEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Täna müüdud rahapurke ressursile \"", _interpolate(_named("name")), "\": ", _interpolate(_named("quantity"))])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
        "productEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Täna müüdud \"", _interpolate(_named("name")), "\": ", _interpolate(_named("quantity"))])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogus"])},
        "resourceDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaatne kalender Touringery süsteemist ressursile \"", _interpolate(_named("name")), "\""])},
        "resourceEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": kogus ", _interpolate(_named("quantity"))])},
        "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendri seadete salvestamine ebaõnnestus"])},
        "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendri seaded salvestatud!"])},
        "sendReminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soovin meeldetuletusi enne sündmuste toimumist"])},
        "serviceDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automaatne kalender Touringery süsteemist müügiartiklile \"", _interpolate(_named("name")), "\""])},
        "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli nimi"])},
        "syncActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sünkroniseerimine sisselülitatud"])},
        "taskEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (", _interpolate(_named("serviceName")), ", ", _interpolate(_named("unitName")), ")"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuda Google kalendri seadistust"])},
        "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksus(ed)"])},
        "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun oodake, salvestamine võib hetke aega võtta..."])}
      },
      "table": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivne"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteaktiivne"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimetus"])},
        "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeldetuletused"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olek"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liik"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google kalendri seaded"])}
    },
    "type": {
      "EVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmus"])},
      "EXPERIENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenus"])},
      "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutus"])},
      "MONEYJAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahapurk"])},
      "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaup"])},
      "RESOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressurss"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsk"])}
    }
  },
  "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi nimi"])},
  "groupedServices": {
    "discountPercentageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allahindlus"])},
    "groupedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartiklid"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "requiredLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nõutud"])},
    "selectServicesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali müügiartiklid, mida soovid gruppi lisada"])},
    "selectServicesToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa müügiartikleid"])}
  },
  "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastajaid"])},
  "guestsCountriesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutatute jaotus elukohariigi järgi"])},
  "guestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastajad"])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siin"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tundi"])},
  "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maja"])},
  "housing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutus"])},
  "housingCalendar": {
    "changeReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuda reserveeringut"])},
    "goToCartView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostukorvi vaatesse"])},
    "goToReservationView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringu vaatesse"])},
    "noTimelineEventsInPeriodText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antud perioodil ei ole reserveeringuid ega sulgemisi."])},
    "showInfo": {
      "availableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadaval üksusi"])}
      },
      "disabledUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välistatud üksuseid"])}
      },
      "reservationsCount": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringuga üksusi"])}
      },
      "reservedAndAvailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Reserveeringuga ", "|", " Saadaval) üksusi"])}
      },
      "selectLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva"])},
      "totalAndAvailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Kokku ", "|", " Saadaval) üksusi"])}
      },
      "totalAndUnavailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Kokku ", "|", " Mittesaadaval) üksusi"])}
      },
      "totalUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üksuseid kokku"])}
      }
    },
    "unspecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määramata"])}
  },
  "housingCalendarDetails": {
    "availableGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadaval"])},
    "availableHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadaval"])},
    "closedHousingUnitsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suletud"])},
    "guestsPerUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külaline üksuse kohta"])},
    "housingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutused"])},
    "noReservationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringud puuduvad."])},
    "reservationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringud"])},
    "reservedHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeritud"])},
    "totalGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokku"])},
    "totalHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokku"])}
  },
  "housingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali, kui soovid luua uue majutuse."])},
  "housingOrderCard": {
    "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["täiskasvanu"])},
    "adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["täiskasvanut"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["laps"])},
    "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last"])}
  },
  "housingOvernightEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesti elanikud ööbimiste arv"])},
  "housingOvernightNonEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väliskülastajad ööbimiste arv"])},
  "housingRoom": {
    "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toa mugavused"])},
    "seeAllAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näita kõiki"])}
  },
  "housingSalesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusmaht"])},
  "housingSalesTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sissetulek majutusteenuste müügist"])},
  "housingUnit": {
    "displayAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva kõik toad"])},
    "doorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uksekood"])},
    "housingUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksused"])},
    "identificator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikaator"])},
    "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage antud majutuste arv ning identifikaator"])},
    "infoHelperDoorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uksekoodi (valikuline) saab lisada kirjapõhjas muutujana"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksus"])}
  },
  "housings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutused"])},
  "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitu"])},
  "howManyAreYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui palju teid on?"])},
  "howToGetThere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohalejõudmise juhised"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "idCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isikukood"])},
  "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikaator"])},
  "insertEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage e-maili aadress"])},
  "invitationsSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsete saatmine õnnestus"])},
  "invitationsSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsete saatmine ebaõnnestus"])},
  "inviteNewUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsu kasutajaid"])},
  "inviteUsersForm": {
    "roleAdminInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin – saab ettevõtte piires teha kõike, juhul kui mitu ettevõtet, siis kõigis."])},
    "roleUserInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutaja - saab vaadata, redigeerida talle suunatud töökäske, oma kalendrit(muudatuste kohta saadetakse teatis adminile)."])}
  },
  "inviteUsersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsutud kasutaja registreeritakse Touringery keskkonnas ja ta saab e-maili peale kutse, mille abil oma konto aktiveerida"])},
  "labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sildid"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keel"])},
  "languageEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglise"])},
  "languageEt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesti"])},
  "languageFi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soome"])},
  "languageJa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaapani"])},
  "languageRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vene"])},
  "languageServiceGeneralBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutage väljadele teabe lisamisel rakenduse jaoks valitud keelt."])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perenimi"])},
  "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimati uuendatud"])},
  "lastView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimane külastus"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laeb..."])},
  "loadingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laeb"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asukoht"])},
  "locationPublicTransport": {
    "addTransportLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa ühistransport"])},
    "distanceKmLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Km"])}
  },
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi sisse"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi välja"])},
  "logoExplanationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Logo kuvatakse külgmenüüs, sisselogimisvormis ja registreerimisvormis. Kuvamisel logo suurus skaleerub olenevalt ruumist, kuid maksimaalne kõrgus on 108 pikslit."])},
  "manageAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontohaldus"])},
  "markAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Märgi tehtuks"])},
  "markAsNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Märgi mittetehtuks"])},
  "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum"])},
  "mealPlanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toitlustusplaan"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sõnum"])},
  "minAdvanceTimePastWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaalne etteteatamisaeg on möödunud"])},
  "minParticipantsInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toimub juhul, kui kuupäevaks ", _interpolate(_named("cancelTime")), " on ostetud vähemalt ", _interpolate(_named("minGroupSize")), " piletit"])},
  "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miinimum"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutit"])},
  "moneyJarCalendarDetails": {
    "noPaidOrderItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sel kuupäeval ei ole ostetud ühtegi rahapurki."])},
    "orderItemsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahapurgid"])},
    "totalUnitsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müüdud ühikuid"])}
  },
  "moneyJarDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali, kui soovid luua uue teenuse laadse müügiartikli, mille puhul ei tehta reserveeringuid ega kontrollita saadavust."])},
  "multimedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multimeedia"])},
  "multimediaTab": {
    "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa pilte"])},
    "baseFileLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuni 5 faili korraga"])},
    "fetchMultimediaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli multimeedia laadimine ebaõnnestus"])},
    "fileDeleteExistingHeaderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olemasoleva päise kustutamine ebaõnnestus"])},
    "fileDeleteFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faili nimega \"", _interpolate(_named("heading")), "\" kustutamine ebaõnnestus"])},
    "fileHeadingIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pealkiri on kohustuslik."])},
    "fileHeadingTooLong": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Päise maksimaalne pikkus on 200 tähemärki. Hetkel on see ", _interpolate(_named("length")), " tähemärki."])},
    "fileOverLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faili \"", _interpolate(_named("name")), "\" suurus üle 5MB"])},
    "fileUpdateFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faili nimega \"", _interpolate(_named("heading")), "\" uuendamine ebaõnnestus"])},
    "fileUploadFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faili nimega \"", _interpolate(_named("heading")), "\" üleslaadimine ebaõnnestus"])},
    "fileUploadHeaderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uue oäise üleslaadimine ebaõnnestus"])},
    "fileWrongFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faili \"", _interpolate(_named("name")), "\" formaat ei ole toetatud. Toetatud: SVG, JPG, JEPG, PNG"])},
    "filesSaveFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failide salvestamine ebaõnnestus"])},
    "filesSaveFailedPartially": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osade failide salvestamine ebaõnnestus"])},
    "filesSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failid edukalt salvestatud"])},
    "gallery": {
      "altTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asendustekst"])},
      "altTagHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa asendustekst juhuks, kui fotot ei kuvata"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pealkiri"])},
      "headingHelperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa foto pealkiri. Maksimaalselt 200 tähemärki."])},
      "maxSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soovituslik maksimaalne suurus 5 MB"])},
      "minDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miinimummõõdud: 960 x 540 px"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerii"])}
    },
    "instructions": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juhised"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisage ilusaid ja kvaliteetseid pilte."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pildisuuruse ja mõõtmete osas arvestage lisatud juhtnööridega."])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotofailide nimed peaksid eelistatavalt olema inglise keeles."])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veenduge, et intellektuaalomand kuulub teile ja teil on lubatud multimeedia turismiinfosüsteemi üles laadida. Piltide lisamisel annate veebiplatvormile tasuta lihtlitsensi andmete kasutamiseks."])}
    },
    "isServiceHeaderLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päisepilt"])},
    "ticket": {
      "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa pilt"])},
      "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võid lisada müügiartikli piletile pildiga isikupära"])},
      "minDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miinimummõõdud: 150 px laiem külg"])},
      "noPricesAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnainformatsioon puudub"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pileti eelvaade"])},
      "showPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva eelvaade"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilet"])}
    }
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
  "nearbyTransportStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähim ühistransport"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uus"])},
  "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uus sündmus"])},
  "newResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uus ressurss"])},
  "newService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uus müügiartikkel"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edasi"])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["öö"])},
  "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ööd"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei"])},
  "noAvaiableDatesInMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See kuu ei sisalda saadaolevaid kuupäevi"])},
  "noAvailabilityRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nendel kuupäevadel pole piisavalt saadavust. Tutvu teenusepakkuja teiste pakkumistega "])},
  "noAvailabilityWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nendel kuupäevadel pole piisavalt saadavust. Proovige teisi kuupäevi"])},
  "noEventAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Teil pole ühtegi sündmuse toimumisaega. Kontrollige filtreid või looge vähemalt üks toimumisaeg."])},
  "noExistingClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Olemasolevaid kliente pole"])},
  "noOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valikud puuduvad"])},
  "noPermissionsForRouteAccessError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ligipääs keelatud! \nTeil pole lehe \"", _interpolate(_named("route")), "\" vaatamiseks õigusi."])},
  "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andmeid pole"])},
  "noRightsErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligipääs keelatud. Teil ei ole selle päringu tegemiseks õigusi"])},
  "noSearchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulemusi pole selle otsinguga"])},
  "noTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsud puuduvad"])},
  "notAccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastuvõtmata"])},
  "notDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittetehtud"])},
  "notReadyYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See osa on veel pooleli"])},
  "numberOfRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tubade arv"])},
  "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivne"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ava"])},
  "openDetailView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ava detailvaade"])},
  "openPartnershipDetailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ava partnerivaade"])},
  "openTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatud ajavahemik"])},
  "optionSelectedLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("length")), " valitud"])},
  "order": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimus"])}
  },
  "orderClientPage": {
    "addressDiscaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aadress on vaja sisestada siis, kui soovite arvet"])},
    "agreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen tutvunud ja nõustun veebikeskkonna kasutustingimustega"])},
    "confirmClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi andmete kinnitamisel tekkis viga"])},
    "confirmClientSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi andmed edukalt kinnitatud"])},
    "contactInfoDiscaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutame Teie andmeid ainult vajadusel ühenduse võtmiseks"])},
    "tourTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üldtingimused"])},
    "updateClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuse kliendi uuendamisel tekkis viga"])},
    "updateClientSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi andmed edukalt uuendatud"])}
  },
  "orderConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuse kinnitus"])},
  "orderSummary": {
    "cancellationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuse tühistamine"])},
    "refundableLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagastatav"])},
    "refundedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagastatud"])}
  },
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päritolu"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülevaade"])},
  "overviewCalendar": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
    "calendarSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendrite valik"])},
    "calendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendrid"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päev"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülevaade"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuu"])},
    "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("eventsNr")), " veel"])},
    "multidayEventsInTimeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva mitu päeva kestvad sündmused ajateljel"])},
    "noCalendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendrid puuduvad"])},
    "noOrdersInPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sellel ajavahemikul tellimused puuduvad"])},
    "orderCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuste ülevaade"])},
    "orderNo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tellimus #", _interpolate(_named("referenceNumber"))])},
    "orderType": {
      "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpetatud"])},
      "CONFIRMED_OFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimus"])},
      "CONFIRMED_ORDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnitatud tellimus"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühistatud"])},
      "LOCKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lukustatud"])},
      "OFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakkumine"])}
    },
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimused"])},
    "otherDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teistel päevadel"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähtesta"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali kõik"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täna"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nädal"])}
  },
  "overviewTabs": {
    "bookingCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
    "bookingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringud"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülevaade"])}
  },
  "participants": {
    "maxGroupSizeText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("groupSize")), "/", _interpolate(_named("maxGroupSize")), "-st valitud"])},
    "participantsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osavõtjaid"])}
  },
  "partnership": {
    "acceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktsepteeri partnerlus"])},
    "acceptPartnershipInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui mõlemad pooled on partnerluse aktsepteerinud, kuvatakse teie müügiartikleid teineteise lehtedel. \nSamuti lülitatakse uuesti sisse tulude jagamise funktsionaalsus."])},
    "backToPartnersList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaata kõiki partnerlusi"])},
    "companyHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esimene partner"])},
    "confirmAcceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnitage vastuvõtmine"])},
    "confirmUnacceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinnitage deaktiveerimine"])},
    "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerluse üksikasjad"])},
    "partnerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teine partner"])},
    "payoutPercentageExampleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näiteks aktiivse partnerluse ajal kuvatakse teie partneri müügiartiklite lehtedel lisaks nende omadele ka teie müügiartikleid. \nKui klient avab teie müügiartikli ja lisab selle oma ostukorvi, on teie partneril õigus saada teie määratud väljamaksete osa."])},
    "payoutPercentageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamaksete protsent, mida ettevõte on nõus oma partneriga jagama. \nJagamine toimub ainult siis, kui müügini jõutakse otse partneri müügiartiklite kaudu."])},
    "payoutPercentageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partneri väljamaksete osa"])},
    "payoutPercentageModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerit teavitatakse, kui teie väljamaksete osa muutub."])},
    "statusLabel": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivne"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteaktiivne"])}
    },
    "tabs": {
      "inviteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsed"])},
      "partnerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerid"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerid"])},
    "unacceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiveerige partnerlus"])},
    "unacceptPartnershipInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerluse deaktiveerimine tähendab, et teie müügiartikleid ei kuvata üksteise lehtedel. \nMõlemal poolel keelatakse ka tulude jagamise funktsioon. \nVarasemast tulu jagamisest tulenevaid tulevasi väljamakseid see ei mõjuta."])},
    "updatePartnershipAcceptanceErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerluse värskendamine ebaõnnestus."])},
    "updatePartnershipAcceptanceSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerluse värskendamine õnnestus."])},
    "updatePartnershipPayoutPercentageErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partneri väljamaksete osa värskendamine ebaõnnestus."])},
    "updatePartnershipPayoutPercentageSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partneri väljamaksete osa värskendati edukalt."])},
    "updatePayoutPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuda väljamaksete osa"])}
  },
  "partnershipInvite": {
    "acceptInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võta kutse vastu"])},
    "acceptInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerikutse vastuvõtmine ebaõnnestus."])},
    "acceptInviteRegisterFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui teil ei ole veel Touringery's teenusepakkuja kontot, siis esmalt läbida registreerimisprotsess ning alles siis proovida uuesti kutsest vastu võtta."])},
    "acceptInviteRequiresLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutse vastuvõtmiseks peate esmalt oma teenusepakkuja kontole sisse logima."])},
    "acceptInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerikutse võeti edukalt vastu. \nTeid suunatakse partnerite üksikasjade kuvale."])},
    "acceptInviteVisitorModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vajalik sisselogimine"])},
    "companyWantsYouToBecomeTheirPartner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("company")), " kutsub teid oma partneriks Touringery-s."])},
    "createInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerikutse saatmine ebaõnnestus."])},
    "createInviteModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uus partner"])},
    "createInviteSubmitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada kutse"])},
    "createInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerikutse saatmine õnnestus."])},
    "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerluse kutse"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutse"])},
    "inviteFormInfoHelperFirstParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partneri kutse saadetakse täpsustatud e-posti aadressile. E-post sisaldab linki, mis viib adressaadi partnerikutse üksikasjade vaatesse. Seal saavad nad kutse vastu võtta või tagasi lükata."])},
    "inviteFormInfoHelperSecondParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutse vastuvõtmiseks peab külastaja sisse logima teise teenusepakkuja kasutajana. \nTagasilükkamise võib teha iga külastaja, kellel on juurdepääs lingile."])},
    "inviteInFinalStatusText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsele on juba vastatud."])},
    "reinviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutse uuesti saatmine ebaõnnestus."])},
    "reinviteLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada kutse uuesti"])},
    "reinviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutse uuesti uuesti saadetud."])},
    "rejectInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keeldu kutsest"])},
    "rejectInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutse tagasilükkamine ebaõnnestus."])},
    "rejectInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutse lükati tagasi."])},
    "statusLabel": {
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksepteeritud"])},
      "DECLINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keeldutud"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aegunud"])},
      "OPENED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatud"])},
      "SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadetud"])}
    },
    "whatHappensWhenYouAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerlus võimaldab teil näidata teisi pakkumisi enda ümber. \nTeie ja teie partner saate endale ka väikese protsendi müügist, kui selleni jõutakse otse teie linkide kaudu."])}
  },
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parool"])},
  "passwordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korda parooli"])},
  "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parool muudetud!"])},
  "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makse"])},
  "paymentBeneficiaryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saaja nimi"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makse detailid"])},
  "paymentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseteave"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseviis"])},
  "paymentOption": {
    "bankLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pangamakse"])},
    "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krediitkaart"])}
  },
  "payoutHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamakse"])},
  "payoutInfo": {
    "completedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamakse on tehtud."])},
    "initiatedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamakse protsess on alustatud."])},
    "noPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamakset ei tehta. Põhjuseks oleks tühistamine täies mahus tagasimaksega."])},
    "notReadyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaikimisi staatus. Muutub peale reserveeringu lõpetamist või tühistamist."])}
  },
  "payoutStatus": {
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamakstud"])},
    "initiated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alustatud"])},
    "noPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamakseta"])},
    "notReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ootel"])}
  },
  "payoutStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamakse olekud"])},
  "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inimest"])},
  "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protsent"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periood"])},
  "periodAvailability": {
    "availabilityHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatus"])},
    "availabilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali päev ja kellaaeg, millal ressurss on saadaval"])}
  },
  "periodUnit": {
    "monthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuu"])},
    "selectMonthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige kuu"])},
    "selectPeriodLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali periood"])},
    "selectUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periood"])},
    "weekLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nädal"])}
  },
  "permissions": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendid"])},
    "helper": {
      "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uue ettevõtte lisamine"])},
      "readCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näeb infot kalendris"])},
      "readClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näeb kliendiinfot"])},
      "readCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näeb kanalihalduri ja seadete menüüpunkte"])},
      "readReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näeb aruandeid ja raamatupidamise menüüpunkti"])},
      "readResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näeb ressursse"])},
      "readServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näeb müügiartiklite ja lisateenuste infot"])},
      "readTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näeb töökäske"])},
      "readTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näeb pileteid"])},
      "updateClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saab lisada kliente, muuta kliendiandmeid"])},
      "updateCompanyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saab luua ja muuta ettevõtte lepinguid, maksete infot, kanalihalduri sätteid ja partnerlusseadeid"])},
      "updateCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saab muuta ettevõtte üldinfot, ettevõtte asukohainfot"])},
      "updateCompanyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saab lisada ja muuta ettevõtte kasutajaõigustega seonduvat"])},
      "updateCompanyWorkflowHelpers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saab lisada ja muuta kirjapõhjade ja check-in vormiga seonduvat"])},
      "updateReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "updateResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saab ressursse muuta, lisada"])},
      "updateServiceContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saab lisada uue müügiartikli, saab lisada uue lisateenuse. Saab muuta müügiartikli ja lisateenuse üldinfot ja multimeedia sisu."])},
      "updateServiceIsPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saab müügiartiklit ja lisateenust avaldada ja arhiveerida"])},
      "updateServicePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saab muuta teenuse ressursse, tegutsemisaegu, sündmuse toimumisaegu, hindu, majutuse spetsiifilist infot nii müügiartikli vaatest kui ka kalendrist"])},
      "updateTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saab lisada ja muuta töökäske"])},
      "updateTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piletite valideerimine"])}
    },
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruanded"])},
    "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressursid"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartiklid"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seaded"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsud"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piletid"])},
    "updateCompanyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte maksed, lepingud"])},
    "updateCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte seaded"])},
    "updateCompanyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte kasutajad"])},
    "updateCompanyWorkflowHelpers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte töövoo abiseaded"])},
    "updateServiceContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartiklite sisu"])},
    "updateServiceIsPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartiklite avaldamine"])},
    "updateServicePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartiklite sätted"])}
  },
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoninumber"])},
  "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pilti"])},
  "pleaseAgreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jätkamiseks palun märkige, et olete tingimustega nõustunud"])},
  "pleaseSelectBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun valige krediitkaart või pank"])},
  "pleaseSelectPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palun valige maksemeetod"])},
  "plurals": {
    "adult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " täiskasvanut"]), _normalize([_interpolate(_named("count")), " täiskasvanu"]), _normalize([_interpolate(_named("count")), " täiskasvanut"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " last"]), _normalize([_interpolate(_named("count")), " laps"]), _normalize([_interpolate(_named("count")), " last"])])},
    "elderly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " eakat"]), _normalize([_interpolate(_named("count")), " eakas"]), _normalize([_interpolate(_named("count")), " eakat"])])},
    "numberOfAdditionalServices": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " lisateenust"]), _normalize([_interpolate(_named("count")), " lisateenus"]), _normalize([_interpolate(_named("count")), " lisateenust"])])},
    "numberOfGuests": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " külastajat"]), _normalize([_interpolate(_named("count")), " külastaja"]), _normalize([_interpolate(_named("count")), " külastajat"])])},
    "numberOfNights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " ööd"]), _normalize([_interpolate(_named("count")), " öö"]), _normalize([_interpolate(_named("count")), " ööd"])])},
    "numberOfParticipants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " osavõtjat"]), _normalize([_interpolate(_named("count")), " osavõtja"]), _normalize([_interpolate(_named("count")), " osavõtjat"])])},
    "numberOfRooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " tuba"]), _normalize([_interpolate(_named("count")), " tuba"]), _normalize([_interpolate(_named("count")), " tuba"])])},
    "numberOfTickets": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " piletit"]), _normalize([_interpolate(_named("count")), " pilet"]), _normalize([_interpolate(_named("count")), " piletit"])])}
  },
  "politicallyExposed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riikliku taustaga isik"])},
  "preferences": {
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päeva"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distants (km)"])},
    "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["külalist"])},
    "includedInPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnas sisaldub"])},
    "labels": {
      "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligipääsetavus"])},
      "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mugavused"])},
      "checkInHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in kellaajad"])},
      "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leping"])},
      "difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raskusaste"])},
      "furtherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisainfo"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keeled"])},
      "numOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastajate arv"])},
      "stayDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastuse kestvus"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihtgrupp"])}
    },
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimaalselt"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaalselt"])},
    "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eelistused"])}
  },
  "preferredLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suhtluskeel"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eelvaade"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasi"])},
  "previousMonthResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eelmise kuu andmed"])},
  "price": {
    "additionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisateenused"])},
    "additionalServicesVAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Lisateenuste käibemaks (20%)"])},
    "createTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereeri pilet"])},
    "descriptionPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinna kirjeldus (valikuline)"])},
    "fullPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täishind"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinna nimetus"])},
    "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hind"])},
    "servicePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli hind"])},
    "serviceVAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli käibemaks (20%)"])}
  },
  "priceDynamicRows": {
    "addRowLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisage hinnamõju"])},
    "durationFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas kestvus mõjutab hinda?"])},
    "durationInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olete täpsustanud, et kestus mõjutab hinda. \nSee tähendab, et saate määrata iga kestuse ajaühiku hinnamuutused, mis muudavad nende lõplikku hinda."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestvuse hinnamõjud koosnevad järgmistest väljadest"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestuse hinnamõjude kirjeldus"])},
      "isImportantHourlyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnipõhise hinnastuse puhul on kestus alati oluline ja seda ei saa välja lülitada."])},
      "isImportantNightlyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ööpõhise hinnastuse puhul on kestus alati oluline ja seda ei saa välja lülitada."])},
      "isImportantPackagedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketipõhise hinnastus ei ole kestvus oluline ja seda ei saa sisse lülitada."])},
      "isPriorityHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui see on märgitud, rakendatakse seda hinnamuudatust ka kõikidele varasematele tundidele, jättes kõrvale kõik muud hinnamuudatused, mis neile võisid rakenduda."])},
      "isPriorityNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui see on märgitud, rakendatakse seda hinnamuudatust ka kõikidele varasematele öödele, jättes kõrvale kõik muud hinnamuudatused, mis neile võisid rakenduda."])},
      "lowerBoundHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antud hinnamõju tundide vahemiku alampiir.\nAlampiir on inklusiivne, sellele rakendub täpsustatud hinnamõju."])},
      "lowerBoundNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antud hinnamõju ööde vahemiku alampiir.\nAlampiir on inklusiivne, sellele rakendub täpsustatud hinnamõju."])},
      "rowsHourlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaikimisi määratakse iga broneeringu tunni hinnaks eelnevalt määratud baashind. \nSiin saate muuta iga tunni hinda, lisades hinnamuutuse reegleid. \nLisateavet hinnamõju reeglite kohta leiab pealkirja kõrval oleval teabeikoonil."])},
      "rowsNightlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaikimisi määratakse iga broneeringu öö hinnaks eelnevalt määratud baashind. \nSiin saate muuta iga öö hinda, lisades hinnamuutuse reeglid. \nLisateavet hinnamõju reeglite kohta leiab pealkirja kõrval oleval teabeikoonil."])},
      "rowsPackagedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestus ei mõjuta paketipõhist hinnastust.\nHind kehtib kogu broneeringu eest, mitte iga tunni/öö eest."])},
      "upperBoundHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antud hinnamõju tundide vahemiku ülempiir. \nÜlempiir on eksklusiivne, hinnamõjud sellele ei rakendu.\nÜlempiir ei ole nõutav, kuid piiramatu võib olla ainult üks kestuse reegel."])},
      "upperBoundNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antud hinnamõju ööde arvu vahemiku ülempiir. \nÜlempiir on eksklusiivne, hinnamõjud sellele ei rakendu.\nÜlempiir ei ole nõutav, kuid piiramatu võib olla ainult üks kestuse reegel."])}
    },
    "durationRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestuspõhised hinnamõjud"])},
    "groupSizeFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas grupi suurus mõjutab hinda?"])},
    "groupSizeInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olete täpsustanud, et grupi suurus mõjutab hinda. See tähendab, et peate looma vähemalt ühe hinnamõju."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi suuruse hinnamõjud koosnevad järgnevatest väljadest"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi suuruse hinnamõju kirjeldus"])},
      "isPriorityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui see on märgitud, rakendatakse seda hinnamuudatust ka kõikidele varasematele rühmaliikmetele, jättes kõrvale kõik muud hinnamuudatused, mis neile võisid rakenduda."])},
      "lowerBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antud hinnamõju inimeste arvu vahemiku alampiir.\nAlampiir inklusiivne, sellele rakendub täpsustatud hinnamõju."])},
      "rowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaikimisi, kui rühma suurus on oluline, kasutatakse lõpphinna saamiseks rühma suurust korrutisena. \nSiin saate muuta iga täiendav rühmaliikme mõju hinnale määrates hinnamuudatusi. \nLisateavet pealkirja kõrval oleval teabeikoonil."])},
      "upperBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antud hinnamõju inimeste arvu vahemiku ülempiir. \nÜlempiir on eksklusiivne, hinnamõjud sellele ei rakendu.\nÜlempiir ei ole nõutav, kuid piiramatu võib olla ainult üks kestuse reegel."])}
    },
    "groupSizeRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi suurusel põhinevad hinnamõjud"])},
    "hourUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tundi"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iga tunni kohta"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tund"])}
    },
    "lowerBoundLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alates"])},
    "nightUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ööd"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iga öö kohta"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öö"])}
    },
    "noOverlapRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnamõjude tingimused ei tohi kattuda"])},
    "onlyOneLimitlessRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainult ühe hinnamõju tingimusel võib olla määramata (piiramatu) ülempiir"])},
    "personUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inimest"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iga inimese kohta"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inimene"])}
    },
    "priceChangeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väärtus, mis lisatakse lõpphinnale, kui reserveering vastab reegli tingimusele."])},
    "priceChangeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnamõju"])},
    "priorityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakenda eelnevatele"])},
    "quantityUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogust"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iga koguse kohta"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogus"])}
    },
    "relatedResourceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millise seotud ressurssi vajamineval kogusel põhineb antud hinnamõju."])},
    "relatedResourceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud ressurss"])},
    "relatedResourcePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali"])},
    "resourceConditionsPerRelatedResourceRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud ressursi hinnamõjude puhul saab kattumise piirang toimuda ainult nende hinnamõjude vahel, mis on ühendatud sama seotud ressursiga."])},
    "resourcesFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas seotud ressurssid mõjutavavad hinda?"])},
    "resourcesInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olete täpsustanud, et seotud ressurssid mõjutavad hinda. See tähendab, et peate looma vähemalt ühe hinnamõju."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressurssi hinnamõjud koosnevad järgnevatest väljadest"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud ressursside hinnamõjude kirjeldus"])},
      "isPriorityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui see on märgitud, rakendatakse seda hinnamuudatust ka kõikidele varasematele seotud ressurssi tükkidele/tundidele, jättes kõrvale kõik muud hinnamuudatused, mis neile võisid rakenduda."])},
      "lowerBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antud hinnamõju ressursside tükkide/tundide vahemiku alampiir.\nAlampiir on inklusiivne, sellele rakendub täpsustatud hinnamõju."])},
      "rowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui ressursid on olulised, saate määrata, kuidas iga lisatud ressurss mõjutab lõpphinda.\nInimesi esindavad ressurssid mõjutavad hinda reserveeringu tundide arvu põhjal.\nObjekte esindavad ressurssid mõjutavad hinda reserveeringuks vajamineva tükkide arvu põhjal.\nLisateavet pealkirja kõrval oleval teabeikoonil."])},
      "upperBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antud hinnamõju ressursside tükkide/tundide vahemiku ülempiir. \nÜlempiir on eksklusiivne, hinnamõjud sellele ei rakendu.\nÜlempiir ei ole nõutav, kuid piiramatu võib olla ainult üks kestuse reegel."])}
    },
    "resourcesRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressursipõhised hinnamõjud"])},
    "restrictionsSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piirangud"])},
    "upperBoundLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuni"])}
  },
  "pricingProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnastusdetailid"])},
  "pricingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnastuse tüüp"])},
  "pricingTypes": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmus"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnipõhine"])},
    "moneyJar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahapurk"])},
    "nightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ööpõhine"])},
    "packaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketipõhine"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogusepõhine"])}
  },
  "pricingTypesInfo": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piletihinnad (täiskasvanute, eakate, laste) on kogu sündmuse jaoks igale osavõtjale."])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestatud hind on iga tunni jaoks ja korrutatakse läbi reserveeringu pikkusega.\nHetkel saab antud hinnastuse puhul määrata minimaalset ja maksimaalset reserveeringu kestvust.\nTÄHTIS:\nHetkel algusaegu ise määrata ei saa, klient saab algusaja ise valida kui see mahub määratud tegutsemiseaega ja ei ole reserveeritud.\nGrupi suurus ei mõjuta hinda. Kahe tunnine reserveering on sama hinnaga sõltumata sellest kas grupp koosneb ühest või kümnest osavõtjast.\nHetkel on toetatud ainult üks samaaegne reserveering."])},
    "moneyJar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenusepakkuja poolt defineeritud hinnaartikli lõplik hind kasutaja jaoks"])},
    "nightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hind arvutatakse reserveeringu ööde arvu põhjal.\nTÄHTIS:\nPraegu arvutatakse kogu reserveeringu hind esimese öö hinna põhjal."])},
    "packaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestatud hind on kogu reserveeringu kestvusele.\nValides selle hinnastuse tüübi saab määrata mis on antud paketi kestvus.\nTÄHTIS:\nHetkel algusaegu ise määrata ei saa, klient saab algusaja ise valida kui see mahub määratud tegutsemiseaega ja ei ole reserveeritud.\nGrupi suurus ei mõjuta hinda. Kahe tunnine reserveering on sama hinnaga sõltumata sellest kas grupp koosneb ühest või kümnest osavõtjast.\nHetkel on toetatud ainult üks samaaegne reserveering."])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hind arvutatakse koguse põhjal"])}
  },
  "priority": {
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriitiline"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõrge"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madal"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskmine"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriitilisuse aste"])}
  },
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privaatsuspoliitika"])},
  "productCalendarDetails": {
    "noSalesForDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antud kuupäevaks ei ole kaupu ostetud"])}
  },
  "profileSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutajaprofiili sätted"])},
  "publicNoServiceBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näib, et sattusite valesse kohta"])},
  "publicNoServiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh ei!"])},
  "publicService": {
    "archivedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikkel ei ole saadaval"])}
  },
  "publicTransport": {
    "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lennujaam"])},
    "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buss"])},
    "harbor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadam"])},
    "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rong"])},
    "tram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tramm"])},
    "trolley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troll"])}
  },
  "purchasedAtDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostukuupäev"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogus"])},
  "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnang"])},
  "readLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loe vähem"])},
  "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Loe rohkem"])},
  "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Põhjus"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teostaja"])},
  "recipientWithPlural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teostaja/Teostajad"])},
  "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teostajad"])},
  "recommendedCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in kell"])},
  "recommendedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out kell"])},
  "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lükkan tagasi"])},
  "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasi lükatud"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreeru"])},
  "registration": {
    "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsige ettevõtet"])}
  },
  "registrationCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreerumise riik"])},
  "registrationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto loomine ebaõnnestus"])},
  "registrationSuccessEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreerumine õnnestus! E-postkasti saadeti aktiveerimise kiri."])},
  "registryNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reg nr"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasi lükatud"])},
  "relate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo"])},
  "relatedAvailableServices": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nendel kuupäevadel saadaval"])}
  },
  "relatedService": {
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loe veel"])}
  },
  "relatedServices": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali lisaks"])},
    "partnersHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali lisaks partneritelt"])}
  },
  "relatedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud kasutajad"])},
  "related_check_in_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud check-in küsimused"])},
  "related_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud ressursid"])},
  "related_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud müügiartiklid"])},
  "related_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud kirjapõhjad"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eemalda"])},
  "removeAllLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eemalda kõik"])},
  "report": {
    "deposit": {
      "header": {
        "commissionsDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komisjonitasu"])},
        "depositsBeforePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagatiste saldo perioodi alguses"])},
        "depositsDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laekunud tagatised"])},
        "depositsEndOfPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagatiste saldo perioodi lõppus"])},
        "paidAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laekumise kuupäev"])},
        "partnersPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerite väljamaksed"])},
        "payoutAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamakse kuupäev"])},
        "refundedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagastamise küüpäev"])},
        "refundedDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagastamised"])},
        "serviceProviderPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenusepakkuja väljamaksed"])}
      }
    },
    "errorFetchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruande loomine ebaõnnestus"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereeri"])},
    "generateCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereeri csv"])},
    "generatePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereeri pdf"])},
    "partners": {
      "header": {
        "payoutAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamaksed"])},
        "payoutTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamakse saaja"])}
      }
    },
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruanded"])},
    "sales": {
      "header": {
        "clientsCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi riik"])},
        "clientsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliendi nimi"])},
        "commissionFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komisjonitasu"])},
        "net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neto"])},
        "partnerPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partneri osa"])},
        "payoutDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väljamakse kuupäev"])},
        "priceLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnarida"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogus"])},
        "refundFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagastamistasu"])},
        "refundedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagasimaksmise kuupäev"])},
        "salesArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikkel"])},
        "salesReportGenerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiaruanne genereeritud"])},
        "sumWithVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summa koos KM-ga"])},
        "sumWithoutVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summa ilma KM-ta"])},
        "totalVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käibemaks kokku"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüüp"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KM(%)"])}
      }
    },
    "table": {
      "additionalService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisateenus"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmus"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenus"])},
      "housing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutus"])},
      "moneyjar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahapurk"])}
    },
    "tabs": {
      "bookKeeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raamatupidamine"])},
      "depositReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagatiste aruanne"])},
      "partnersReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerite aruanne"])},
      "salesReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiaruanne"])},
      "statisticsReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistika aruanne"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokku"])},
    "touringeryCommission": {
      "header": {
        "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neto"])},
        "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müük kokku"])}
      }
    }
  },
  "reports": {
    "statistics": {
      "accomodatedEstonianNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesti elanikud - ööbimiste arv"])},
      "accomodatedEstonianPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesti elanikud - majutatute arv"])},
      "accomodatedForeignNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väliskülastajad - ööbimiste arv"])},
      "accomodatedForeignPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väliskülastajad - majutatute arv"])},
      "accomodatedNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ööbimiste arv"])},
      "accomodatedPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutatute arv"])},
      "bedPlacesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voodikohad"])},
      "campingPlacesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laagri- ja haagissuvilaplatside kohad\t"])},
      "countryCodeHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riigi kood"])},
      "openDaysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahtioleku päevad"])},
      "roomNightsSoldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksuste kasutamine (müüdud tubaööpäevade arv)"])},
      "roomsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksuste arv"])},
      "tripPurpose": {
        "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tööreis"])},
        "conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..osavõtt konverentsist\t"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu (nt ööbimised transiitturismil, raviturismil jms)"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutatute ja ööbimiste arv kokku\t"])},
        "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhkusereis"])}
      }
    }
  },
  "requestLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telli link"])},
  "requestNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telli uus parool"])},
  "requestSentLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päring saadetud! Saatsime parooli taastamise lingi."])},
  "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erisoove"])},
  "requiredSettingsAlert": {
    "beneficialOwnersNotFilledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegelik kasusaaja/kasusaajad"])},
    "companyContractNotAgreedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nõustu lepingutingimustega"])},
    "companyNoServiceContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenusepakkuja ja kliendi vaheline müügileping"])},
    "dynamicShowAllLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kuva rohkem (", _interpolate(_named("count")), ")"])},
    "hideMoreSettingsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peida rohkem"])},
    "paymentInfoNotFilledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseandmed väljamaksete jaoks"])}
  },
  "requirementsFilledError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esines viga. Kontrollige, kas kõik on õigesti täidetud"])},
  "resendConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada tellimuse kinnitus"])},
  "resendConfirmationEmailAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siit saate tellimuse kinnituse kliendile uuesti saata."])},
  "resendConfirmationEmailAlertWithLastDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Siit saate tellimuse kinnituse kliendile uuesti saata. Viimati saadeti: ", _interpolate(_named("emailConfirmationAt")), "."])},
  "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveering"])},
  "reservationData": {
    "cannotChangePeriodForExternalSourcesAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioodi ei saa muuta, kuna reserveering pärineb välisest allikast."])},
    "originalGuestsAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Külastajate arv on muutunud, algselt oli see: ", _interpolate(_named("guests"))])}
  },
  "reservationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringu kuupäev"])},
  "reservationEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringu lõpp"])},
  "reservationHousingUnit": {
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksuse salvestamine ebaõnnestus"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksuse salvestamine õnnestus"])}
  },
  "reservationLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservatsiooni pikkus"])},
  "reservationSalesEndTimeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online müügi lõpp"])},
  "reservationStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringu algus"])},
  "reservationStatus": {
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühistatud"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpetatud"])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aegunud"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makstud"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ootel"])}
  },
  "reservationStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveeringu olekud"])},
  "reservationType": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seose tüüp"])},
    "oneCapacityPerPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üks koht ühe inimese kohta"])},
    "oneQuantityPerEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üks tükk ürituse kohta"])},
    "oneQuantityPerPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üks tükk ühe inimese kohta"])},
    "oneQuantityPerReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üks tükk/tund ühe reserveeringu kohta"])}
  },
  "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressurss"])},
  "resourceCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressursi loomine õnnestus"])},
  "resourceCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressursi loomine ebaõnnestus"])},
  "resourceDefaultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll/Nimetus"])},
  "resourceDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressursi kustutamine õnnestus"])},
  "resourceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupp"])},
  "resourceGroupCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi loomine"])},
  "resourceGroupCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi loomine õnnestus"])},
  "resourceGroupCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi loomine ebaõnnestus"])},
  "resourceGroupUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi muutmine"])},
  "resourceGroupUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi uundamine ebaõnnestus"])},
  "resourceGroupUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupi uuendamine õnnestus"])},
  "resourceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupid"])},
  "resourceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressursi nimetus"])},
  "resourceObjectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimetus"])},
  "resourcePeriodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisesta kuupäev, millal ressurss on saadaval"])},
  "resourceSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressursi salvestamine õnnestus"])},
  "resourceSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressursi salvestamine ebaõnnestus"])},
  "resourceTabs": {
    "resourceCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressursside kalender"])},
    "resourceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupid"])},
    "resourceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressursid"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsud"])}
  },
  "resourceUserTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll"])},
  "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressursid"])},
  "respondedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reageeris"])},
  "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastused"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll"])},
  "room": {
    "alertinfotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusüksus on öömaja ühele külalisele / külaliste kooslusele. Sisestage majutusüksuste kogus või erinevuste korral iga üksus eraldi."])},
    "maxGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastajate maksimumarv"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuba"])},
    "roomInfoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täpsustage majutusüksuse tubade arv, et sisestada tubadele spetsiifiline info"])},
    "roomType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toa tüüp"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toad"])},
    "unitCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategooria"])}
  },
  "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mugavused"])},
  "roomLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üldinfo"])},
  "roomsSoldEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesti elanikud majutate arv"])},
  "roomsSoldNonEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väliskülastajad majutate arv"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ridu lehekülje kohta"])},
  "rrule": {
    "end:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpp:"])},
    "endDate:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpuaeg:"])},
    "eventInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmuse informatsioon"])},
    "every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iga"])},
    "everyDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". kuupäeval iga"])},
    "everyDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iga päev"])},
    "everyMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iga kuu"])},
    "everyOtherDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päeva järel"])},
    "everyOtherMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuu järel"])},
    "everyOtherWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nädala järel"])},
    "everyTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["korra järel"])},
    "everyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iga nädal"])},
    "everyWorkDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igal tööpäeval"])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esimene"])},
    "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neljas"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reede"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esmaspäev"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuu"])},
    "recurresEvery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kordub iga"])},
    "repetitionFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kordussagedus"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["laupäev"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teine"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pühapäev"])},
    "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kolmas"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neljapäev"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teisipäev"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kolmapäev"])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvesta"])},
  "saveAndContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvesta ja mine edasi"])},
  "saveNewCoordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuenda vormi koordinaate"])},
  "scanner": {
    "anonymousCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonüümne kaamera"])},
    "cameraAccessSecureContextOnlyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juurdepääs kaamerale on toetatud ainult turvalises kontekstis, näiteks https või localhost."])},
    "cameraBasedScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaamerapõhine skannimine"])},
    "cameraStreamingNotSupportedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brauser ei toeta kaamera voogesitust."])},
    "chooseAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige Teine"])},
    "chooseImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige Pilt"])},
    "chooseImageNoImageChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige pilt – pilti pole valitud"])},
    "codeScanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koodiskanner"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])},
    "fileBasedScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failipõhine skannimine"])},
    "getUserMediaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga userMedia hankimisel, viga ="])},
    "idle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ootel"])},
    "launchingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaamera käivitamine..."])},
    "loadingImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pildi laadimine..."])},
    "navigatorMediaDevicesNotSupportedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seade ei toet navigator.mediaDevices-i, ainuke toetatud cameraIdOrConfig on sel juhul deviceId parameeter (tekst)."])},
    "noCameraFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaamerat ei leitud"])},
    "noCameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaameraid pole"])},
    "noImageChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühtegi pilti pole valitud"])},
    "notAllowedPermissionDeniedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NotAllowedError: luba on keelatud"])},
    "orDropImageToScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Või lohistage skannimiseks pilt"])},
    "orDropImageToScanOtherFilesNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Või lohistage pilt skannimiseks (muid faile ei toetata)"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luba"])},
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahendus baaserub"])},
    "qrCodeParseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-koodi parsimise tõrge, viga ="])},
    "querySupportedDevicesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toetatud seadmete kohta ei saa päringuid teha, tundmatu viga."])},
    "reportIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teatage probleemidest"])},
    "requestCameraPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taotlege kaamera lubasid"])},
    "requestingCameraPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taotlemas kaamera lubasid..."])},
    "scanAnImageFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skannige pildifail"])},
    "scanUsingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skannige otse kaameraga"])},
    "scannerPaused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skanner on peatatud"])},
    "scanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skaneerimine"])},
    "selectCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige Kaamera"])},
    "startScanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alustage skannimist"])},
    "stopScanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpeta skannimine"])},
    "switchOffTorch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lülitage taskulamp välja"])},
    "switchOffTorchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taskulambi väljalülitamine ebaõnnestus"])},
    "switchOnTorch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lülitage taskulamp sisse"])},
    "switchOnTorchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taskulambi sisselülitamine ebaõnnestus"])},
    "zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suumi"])}
  },
  "scheduledEmails": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajastatud e-kirjad"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirju pole"])},
    "scheduledAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajastatud"])},
    "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada kiri"])},
    "sendEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirja saatmine ebaõnnestus"])},
    "sendEmailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirja saatmine õnnestus"])},
    "sentAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimati saadetud"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjapõhi"])}
  },
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsi"])},
  "searchExistingClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsige olemasolevate klientide hulgast"])},
  "searchForAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsi aadressi"])},
  "searchToSeeResultsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisestage soovitud kuupäevad ja külastajate arv"])},
  "seeAllClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaata kõiki kliente"])},
  "seeAllResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaata kõiki ressursse"])},
  "seeAllServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaata kõiki müügiartikleid"])},
  "seeAllTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaata kõiki töökäske"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaata lähemalt"])},
  "selectAdditionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo lisateenuseid"])},
  "selectAdditionalServicesToRelate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali alljärgnevate seast lisateenused, mida soovid siduda"])},
  "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali ettevõte"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali riik"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali kuupäev"])},
  "selectRelatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo ressursse"])},
  "selectResourcesToRelate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali alljärgnevate seast ressursid, mida soovid siduda"])},
  "sendInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada kutsed"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada sõnum"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikkel"])},
  "serviceAdditionalService": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siin saad siduda eelnevalt müügiartikklite menüüst sisestatud lisatooteid. Valitud lisatooteid kuvatakse ostukorvis enne maksmist."])}
  },
  "serviceAdvancedTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edasijõudnutele"])},
  "serviceArchiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli arhiveerimine ebaõnnestus"])},
  "serviceArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikkel edukalt arhiveeritud"])},
  "serviceCheckInQuestions": {
    "addQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa küsimus"])},
    "addRelationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali küsimused, mille soovid müügiartikli check-in vormile lisada või sellelt eemaldada"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küsimust muutes rakenduvad muudatused kõigile seotud müügiartiklitele"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud check-in küsimused"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in küsimusi saate seadistada ka seadete alt. Siin näete antud müügiartikliga seotud check-in küsimusi ja saate lisada uusi seoseid."])},
    "saveRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvesta seosed"])},
    "selectQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo küsimusi"])},
    "serviceCreateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõigi müügiartiklite check-in vormi lisaküsimuste nägemiseks minge Seaded -> Check-in vorm"])}
  },
  "serviceContact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktinfo"])},
    "headerDefaultsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väärtusteta väljad kasutavad vaikimisi ettevõtte profiili seadeid, nagu on näha kohatäidetes."])},
    "headerGeneralInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määrake klientide jaoks müügiartikli spetsiifiline kontaktteave."])},
    "nameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktisiku nimi. \nVäli on tühjaks jätmisel klientide eest peidetud."])},
    "overrideNotificationsGeneralInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Märkimisel kasutatakse antud e-posti aadressi ettevõtte e-posti asemel selle teenusega seotud e-kirjade teavituste saatmiseks."])},
    "overrideNotificationsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutage e-posti teavituste jaoks"])},
    "overrideNotificationsSettingsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-kirjade saatmise märguannete seaded kehtivad endiselt."])}
  },
  "serviceDuplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli kopeerimine õnnestus"])},
  "serviceDuplicationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli kopeerimine ebaõnnestus"])},
  "serviceEvent": {
    "availableCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saadav mahutavus"])},
    "cancelAtHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühistamisaeg"])},
    "cancellationTimeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siin saad määrata kuupäeva ja kellaaja, mis ajaks peab olema täidetud minimaalne osavõtjate arv/müüdud miinimum kogus pileteid. Kui miinimum osavõtjate arv ei ole täidetud sündmus tühistub automaatselt. Seni ostetud piletite eest saadud raha tagastatakse, osavõtjaid teavitatakse automaatselt. Sündmuse tühistamisega kaasnev ülekannete kulu tuleb hüvitada korraldajal."])},
    "capacityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui sinu sündmuse toimumine eeldab minimaalset osavõtjate arvus, siis sisesta see siia. Kui sündmus toimub igal juhul, siis ei ole vaja miinimumi märkida, sisesta vaid maksimaalse osavõtjate arv."])},
    "eventCapacityHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahutavus"])},
    "eventPeriodHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmuse toimumisaeg"])},
    "maxCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum"])},
    "minCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miinimum"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staatus"])},
    "timeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali kalendrist sündmuse toimumise alguse kuupäev, vajuta kella ikoonile ja vali alguse kellaaeg."])},
    "updateEventTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuenda sündmust"])}
  },
  "serviceEventCalendar": {
    "onlyEventsFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näita ainult sündmusega"])}
  },
  "serviceEventsArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmuste arhiveerimine õnnestus"])},
  "serviceEventsArchivedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmuste arhiveerimine ebaõnnestus"])},
  "serviceFilter": {
    "privateState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nähtavus"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olek"])}
  },
  "serviceGeneral": {
    "categoryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali lähim võimalik."])},
    "descriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vajadusel venita aken alt paremalt nurgast suuremaks."])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need on teenusepakkuja siseseks kasutamiseks ja võimaldavad eri müügiartikleid oma vajaduste järgi grupeerida ja nimekirjavaates filtreerida nende põhjal."])},
    "typeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategooria täpsustus."])}
  },
  "serviceGroup": {
    "groupItemIsUnavailableForSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikkel ei ole valikute põhjal saadaval"])}
  },
  "serviceLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa müügiartikli sildid"])},
  "serviceLocation": {
    "accessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Märgi kõik toimivad võimalused."])},
    "addressInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsi aadressi kasutab Googli otsingut – sisesta oma aadress nii nagu sa oled Googlist leitav."])},
    "howToGetThereInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjelda millise nime all, kuidas oled kaardirakendustes leitav ja/või teekonda lähimast tõmbekeskusest sinu juurde."])},
    "locationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liigu kaardil hiirega soovitud kohta ja kliki, tekib nööpnõel, nihuta nõel vajadusel täpsesse kohta."])},
    "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali asukoht"])},
    "transportInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Märgi lähim peatus ja kaugus sihtkohast."])}
  },
  "serviceLocationInfo": {
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koordinaadid täidetakse aadressi otsingutulemusel klõpsates. Võite ka sisestada koordinaadid käsitsi või kaardil nööpnõela nihutades ja \"Uuenda vormi koordinaate\" nupule vajutades "])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvatakse asukohtade loendis, et aidata tuvastada konkreetseid asukohti"])}
  },
  "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli nimi"])},
  "serviceNotSavedWarning": {
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühista muudatused"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sul on salvestamata muudatusi!"])}
  },
  "servicePeriod": {
    "activeHoursInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli saadavus nädala ja päeva lõikes."])},
    "arrivalLengthOfStayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saabumisel põhinev peatumise pikkus"])},
    "lengthOfStayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peatumise pikkus"])},
    "periodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määra periood, mille jooksul müügiartiklit pakud. Kui müügiartikkel on saadaval aastaringselt, siis perioodiks kalendriaasta või ka enam. Kui tahad, et periood ei aeguks siis jäta perioodi lõpp täpsustamata."])},
    "restriction": {
      "info": {
        "arrivalLengthOfStayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See on minimaalne ja maksimaalne ööde arv, mida külalised saavad broneerida, kui saabuvad kindlal kuupäeval. Need piirangud tuleb läbida broneerimisperioodi saabumiskuupäeva alusel."])},
        "bookingArrivalLengthOfStay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need piirangud on samaväärsed Booking.com-i minimaalse ja maksimaalse saabumispäeva peatumise pikkuse piirangutega."])},
        "bookingLengthOfStay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need piirangud on samaväärsed Booking.com-i minimaalse ja maksimaalse peatumise pikkuse piirangutega."])},
        "lengthOfStayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See on minimaalne ja maksimaalne ööde arv, mida külalised saavad broneerida. Need piirangud tuleb läbida kõikidel broneerimisperioodi kuupäevadel."])},
        "restrictionSectionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindlaksmääratud piirangud rakenduvad kõikidele antud perioodi kuupäevadele."])},
        "specifiedMaxRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui see on täpsustatud, siis see peab olema vähemalt võrdne nii peatumise pikkuse kui ka saabumispõhise peatumise pikkuse miinimumväärtustega."])},
        "syncedToBookingGeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui integreerimine on aktiivne ja majutus on ühendatud Booking.com-i toaga, siis seda piirangut rakendatakse ka tubade hinnaplaanidele."])},
        "unspecifiedOptionBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui valik on määramata, siis piirangut ei sünkrooniseerita Booking.com-i. \nSee tähendab, et kõik varem seatud piirangud jäävad seal aktiivseks."])},
        "unspecifiedOptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui see valik on määramata, siis piirangut ei rakendata."])},
        "unspecifiedOptionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määramata valik"])}
      }
    },
    "restrictionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piirangud"])},
    "stayOptionNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täpsustamata"])},
    "weekslotsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegutsemisajad"])}
  },
  "servicePeriodList": {
    "addAnotherPeriodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Lisa uus periood, kui hooajaliselt müügiartikkel saadaval erinevatel kellaaegadel."])}
  },
  "servicePeriodPricing": {
    "descriptionHelperInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinna nimi on kasutajale kuvatav hinnaartikli nimetus. Kirjeldus on informatiivne väli, mida kasutajale ei kuvata"])},
    "discountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siin saad valida millist soodustust soovid pakkuda, mis tingimustel ja millises mahus.Vajadusel saad lisada mitu allahindluse tüüpi."])},
    "identificatorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisesta unikaalne identifikaator, mida süsteem kasutab hinna tuvastamiseks. Indentifikaatoriks võib olla nt hinna nimi. Ärge kasutage tühikuid ega erisümboleid."])},
    "isMinimumPriceWarningAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praegusele valikule on rakendatud teenuse miinimumhind."])},
    "minPriceFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miinimumhind"])},
    "minPriceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui täpsustatud, siis see määratakse reserveeringu lõpphinnaks, kui originaalne lõpphind (k.a allahindlused) oleks sellest väiksem. Miinimumhind ei mõjuta lisateenuseid, nende maksumus lisatakse peale miinimumhinna rakendamist."])},
    "priceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisesta lõplik hind koos maksudega"])},
    "pricingPropertiesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuidas müügiartikli hindu arvutatakse hinnastuse tüübi põhjal:"])},
    "ticketCheckboxInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iga müüdud üksuse kohta genereeritakse kliendile QR-koodiga pilet"])}
  },
  "servicePreference": {
    "difficultyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui müügiartiklil on raskusaste, siis määra, kui ei, siis määratlemata."])}
  },
  "servicePreferences": {
    "accessibilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali pakutav."])},
    "amenitiesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali hinnas sisalduvad lisaväärtused, teenused, võimalused."])},
    "contractInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige leping, mis sätestab kliendi ja teenusepakkuja vahelised tingimused."])},
    "contractInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lepinguid saate seadistada Seaded - Lepingud alt"])},
    "difficultyLabels": {
      "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihtne"])},
      "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raske"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskmine"])},
      "neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määratlemata"])}
    },
    "languagesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali millistes keeltes kliente teenindatakse."])},
    "minReservationTimeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisesta viimane aeg, millal on võimalik piletit soetada. Kui piirangut ei ole, siis jäta tühjaks."])},
    "targetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui on oluline info siis vali sihtgrupid, kui ei jäta tühjaks."])}
  },
  "servicePreview": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eelnevalt sisestatud info põhjal koostatud vaade, mida näeb sinu klient. Kui kõik oluline on olemas, siis vajuta lehekülje all olevat avalda nuppu. Genereeritakse levitatav url/link, mida saad paigutada oma müügikanalitesse (FB, koduleht, partnerite lehed…). Kuni esimese müügitehinguni on kõik andmed veel muudetavad. Peale esimest müügitehingut ei saa enam muuta toimumisaega ja hinda."])}
  },
  "servicePrivateStateFilter": {
    "allLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva kõiki"])},
    "privateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva privaatseid"])},
    "publicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva avalikke"])}
  },
  "serviceProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenusepakkuja"])},
  "serviceProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenusepakkujad"])},
  "servicePublicURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli avalik URL:"])},
  "servicePublishButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalda"])},
  "serviceResource": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui kasutad ressursside planeerimist, sisesta info, kui mitte, liigu edasi."])},
    "newResourceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui vajalikku ressursi ei ole sisestatud, saad seda teha siin."])}
  },
  "serviceResources": {
    "removeResourceConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas olete kindel, et soovite selle ressursi eemaldada?"])}
  },
  "serviceStateFilter": {
    "showActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva aktiivseid"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva kõiki"])},
    "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva arhiveeritud"])},
    "showUnpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva avaldamata"])}
  },
  "serviceTabs": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartiklid"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piletid"])},
    "ticketsValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pileti valideerimine"])}
  },
  "serviceTemplates": {
    "addRelationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali kirjapõhjad, mille soovid müügiartikliga siduda"])},
    "addTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo kirjapõhi"])},
    "createNewLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo uus kirjapõhi"])},
    "editRelationsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjapõhja seosed"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud kirjapõhjad"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjapõhju saate seadistada ka seadete alt. Siin näete antud müügiartikliga seotud kirjapõhju ning saate lisada uusi seoseid."])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seoste salvestamine ebaõnnestus"])},
    "saveRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvesta seosed"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seosed on edukalt salvestatud"])},
    "selectTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seo kirjapõhju"])}
  },
  "serviceTicket": {
    "addAnotherTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisa uus pilet"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuupäevad"])},
    "defaultPricesSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnad"])},
    "defaultQuantitiesSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piletite arv"])},
    "defaultSlotsSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajavahemikud"])},
    "isActiveLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiivne"])},
    "isDurationLimitedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajavahemikule 10:00-15:00 1-tunnise pileti kestusega, kui pilet aktiveeritakse kell 14:30, kas see kehtib kuni 15:00 (ajavahemiku lõpp) või 15:30 (kestuse lõpp)"])},
    "isDurationLimitedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pileti kestus on piiratud ajavahemiku lõpuga"])},
    "isTimeslotDurationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas ajavahemikul 10:00-15:00 kehtib pilet kogu aja või ainult 1 tunni aktiveerimisajast."])},
    "isTimeslotDurationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pileti kehtivusaeg vastab ajavahemikele"])},
    "noPricesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pileti hinnaperioodid puuduvad"])},
    "noQuantitiesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lubatud piletite arvu ei ole täpsustatud ühekski ajavahemikuks. Puuduva piletiarvuta ajavahemike ei pakuta müügiks."])},
    "noSlotsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pileti ajaperioodid puuduvad"])},
    "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kogus"])},
    "slotOptionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajavahemikud"])},
    "ticketTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilet"])},
    "timelineUpdateForm": {
      "addSlotHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisamiseks vajuta enter või ava menüü ikoonist"])},
      "noSlotsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valikute algusaegu ei ole lisatud, ilma algusaegadeta ei toimu müüki"])},
      "pricesSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinnaperioodid"])},
      "slotsSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valikute algusajad"])}
    },
    "typeDurationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kestus"])},
    "typeTimeslotLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajavahemik"])},
    "updateTimelineFormSelectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piletid ja Kuupäevad"])},
    "updateTimelineTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuenda hinnastust"])},
    "useCustomValuesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eriväärtused"])},
    "useDefaultValuesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaikeväärtused"])}
  },
  "serviceTimeline": {
    "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piletid & Hinnastus"])},
    "timelineHeaderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajajoon"])}
  },
  "serviceType": {
    "eventLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmus"])},
    "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenus"])},
    "groupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali, kui soovid müüa teisi müügiartikleid koos ühisest vaatest."])},
    "groupLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupp"])},
    "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutus"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli tüüp"])},
    "moneyJarLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahapurk"])},
    "productDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali, kui soovid luua toote müügiartikli. Tooteid saab müüa otse või läbi teiste müügiartiklite."])},
    "productLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaup"])},
    "salesItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikkel"])},
    "ticketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vali, kui soovid müüa pileteid."])},
    "ticketLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilet"])}
  },
  "serviceUnits": {
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tund"])},
    "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KG"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])},
    "ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ML"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muu"])},
    "otherUnitPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täpsusta ühik"])},
    "pcs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TK"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inimene"])},
    "pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lemmikloom"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühik"])}
  },
  "serviceView": {
    "duplicateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopeerida saab avaldatud müügiartiklit. Kopeeritakse kogu müügiartikkel, v.a (sündmuse puhul) toimumisajad. Kopeeritud müügiartikkel on mitteavaldatud."])},
    "isArchivedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhiveeritud"])},
    "isNotPublishedTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaldamata"])},
    "isPrivateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privaatseid müügiartikleid ei saa kliendid otse osta ja neid ei kuvata seotud müügiartiklite all."])},
    "isPrivateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privaatne"])},
    "isPublicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalik"])},
    "isPublishedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaldatud müügiartikkel on avalikult nähtav."])},
    "isPublishedTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaldatud"])},
    "publishError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli avaldamine ebaõnnestus."])},
    "publishSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikkel edukalt avaldatud."])}
  },
  "service_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmus"])},
  "service_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegutsemisajad & Hinnastus"])},
  "service_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikkel"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartiklid"])},
  "setDurationManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuaalselt kestuse määramine"])},
  "setNewPassword": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määra uus parool"])},
    "submitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Vale tunnus või parool"])},
    "tokenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vale identifitseerimisvõti"])}
  },
  "setUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täpsustage kasutaja andmed"])},
  "settings": {
    "ServicePublishWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müügiartikli avaldamiseks tuleb enne täita järgnev informatsioon"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seaded"])},
    "tabLabel": {
      "bookingWidget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalik vaade"])},
      "channelList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalid"])},
      "channelManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanalihaldur"])},
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in vorm"])},
      "companyEmails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teavitused"])},
      "compucash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash integratsioon"])},
      "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lepingud"])},
      "emailTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mallid"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üldseaded"])},
      "googleCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google kalender"])},
      "inviteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutsed"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asukohainfo"])},
      "partnerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerid"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte info"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vormid"])},
      "trigon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigon"])},
      "userRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutajaõigused"])}
    }
  },
  "settingsCheckIn": {
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo küsimus"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga kustutamisel"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kustutamine õnnestus"])},
    "displayForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva vorm"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisaküsimused"])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisaküsimus"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küsimus"])}
  },
  "settingsContracts": {
    "archiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga arhiveerimisel"])},
    "archiveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhiveerimine õnnestus"])},
    "archivedSalesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arhiveeritud müügiartikleid"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selle lepinguga on seotud tellimusridu. Lepingu muutmisel vana leping arhiveeritakse ja müügiartiklite seosed kantakse üle uuele versioonile"])},
    "clientConsents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud tellimusridu"])},
    "confirmArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas olete kindel, et soovite arhiveerida?"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas olete kindel, et soovite kustutada?"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo leping"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lepingud"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegemist on Teenusepakkuja ja lõppkliendi vahel sõlmitava lepinguga, mis võib olla müügiartiklite lõikes erinev. Teenusepakkuja võib kasutada meie poolt koostatud tüüpprojekti, seda vajadusel redigeerida või kopeerida siia oma lepinguvormid."])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leping"])},
    "relatedActiveSalesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaldatud müügiartikleid"])},
    "relatedPublishedServicesHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See leping on seotud järgmiste müügiartiklitega"])},
    "saveAsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvesta uuena"])},
    "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuva arhiveeritud"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leping"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimati muudetud"])}
  },
  "settingsLocation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asukohad"])},
    "titleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõik teenusepakkuja asukohad, mida saab määrata müügiartiklitele."])}
  },
  "settingsPayments": {
    "getFromAriregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Värskenda Äriregistrist"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täida kohustuslikud väljad panga nimi, saaja nimi ja IBAN, ning seejärel vajuta all paremal „salvesta“ nuppu."])},
    "noAriregisterData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andmeid ei leitud"])}
  },
  "settingsProfile": {
    "agreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olen tutvunud ja nõustun veebikeskkonna"])},
    "basicInformationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kõik väljad on kohustuslikud, v.a km-kohuslane. Sisestage ettevõtte andmed käsitsi või hankige andmed äriregistrist."])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lepinguga"])},
    "contractLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenusepakkuja ja Touringery vahelised lepingutingimused"])},
    "descriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte kirjeldusse kirjuta missioon, slogan või oluline sõnum, mis käib kõigi pakutavate müügiartiklite kohta."])},
    "locationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alusta sisestamist otsingu aknas. Kasutame Googli aadressiotsingut, seega sisesta nii nagu oled Googlist leitav. Kui ikkagi täpset aadressi ei leia, siis saad sisestada käsitsi maakonna, linna, jne."])},
    "socialNetworkAccountsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisesta olemasolevad, neid näitame müügiartikli vaates, iga müügiartikli juures."])},
    "vatNumberInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kui km pole kohustuslik, jätke väli tühjaks."])}
  },
  "settingsTemplates": {
    "contentPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirja eelvaade"])},
    "createInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koostage kirjapõhi, ajastage saatmine ja siduge teenustega. Saate sisestada dünaamilisi muutujaid, mis asendatakse lõplikus tekstis tegelike andmetega"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo kirjapõhi"])},
    "createNewExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo teenuse kirjapõhi"])},
    "createNewHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loo majutuse kirjapõhi"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjapõhja kustutamine ebaõnnestus"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjapõhja kustutamine õnnestus"])},
    "experienceDurationChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastuse päevade arv"])},
    "experienceEndChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lõpukuupäev ja kellaaeg"])},
    "experienceStartChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sündmuse alguse kuupäev ja kellaaeg"])},
    "forHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutusele"])},
    "forService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teenusele"])},
    "housingDurationChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Külastuse ööde arv"])},
    "housingEndChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out hiliseim aeg"])},
    "housingStartChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in kuupäev ja kellaaegade vahemik"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjapõhjad"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siin saate seadistada kliendile automaatselt saadetavaid e-maile. E-maili saatmist saab siduda majutuse ja teenuse broneerimisega."])},
    "relatedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud teenuseid"])},
    "relatedTimings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seotud ajastusi"])},
    "restrictionCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ära saada, kui check-in vorm on esitatud"])},
    "restrictionCheckInInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirja saatmisel kontrollitakse, kas reserveeringu teinud klient on check-in vormi täitnud. Kiri saadetakse ainult juhul, kui vorm on esitamata."])},
    "restrictionNoCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ära saada, kui check-in vorm on esitamata"])},
    "restrictionNoCheckInInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["irja saatmisel kontrollitakse, kas reserveeringu teinud klient on check-in vormi täitnud. Kiri saadetakse ainult juhul, kui vorm on esitatud."])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjapõhja salvestamine ebaõnnestus"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjapõhi on salvestatud"])},
    "sendRetroactively": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada tagasiulatuvalt ka juba loodud reserveeringutele"])},
    "sendRetroactivelyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teie valikul saadame kirjapõhja ka varem loodud reserveeringutele. Seda juhul, kui kirja seadistatud saatmisaeg ei jää broneeringu suhtes minevikku"])},
    "serviceCreateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siin saate seadistada antud müügiartikliga seonduvaid kirjapõhju. Kõiki kirjapõhju saate seadistada Seaded -> Kirjapõhjad alt"])},
    "serviceCreateWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjapõhja sisu, ajastust jm sätteid muutes rakenduvad muudatused kõigile kirjapõhjaga seotud müügiartiklitele"])},
    "serviceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige müügiartiklid, mille reserveerimisel kiri välja läheb"])},
    "timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajastus"])},
    "timingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valige, millal kiri välja läheb"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pealkiri"])}
  },
  "settingsUsers": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutajad"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisesta kasutaja nimi ja meiliaadress, määra roll ja saada kutse. Peale meilile tulnud teate kinnitamist saab uus kasutaja asuda tööle vastavalt määratud rollile."])},
    "tabs": {
      "companyRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ettevõtte rollid"])},
      "usersRignts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutajate õigused"])}
    },
    "userRolesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seadistage rollid ja neile kehtivad õigused. Määrake igale kasutajale vastav roll, et kontrollida, mida kasutaja Touringery keskkonnas näha ja muuta saab."])}
  },
  "showAllAmenities": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Näita kõiki ", _interpolate(_named("numOfAmenities")), " mugavust"])},
  "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näita vähem"])},
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näita rohkem"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi sisse"])},
  "socialNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sotsiaalmeedia kontod"])},
  "somethingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midagi läks valesti"])},
  "specialRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Erisoovid"])},
  "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erisoovid"])},
  "specifyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täpsusta aadressi"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algus"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alguskuupäev"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maakond"])},
  "statisticsReport": {
    "companyInformation": {
      "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majandusüksuse nimi"])},
      "organizationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majandusüksus"])}
    }
  },
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olek"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tänav"])},
  "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamkategooria"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokkuvõte"])},
  "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastutaja"])},
  "support": {
    "card": {
      "title": {
        "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võta ühendust"])},
        "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saada sõnum"])}
      }
    },
    "contactHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.00-17.00"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "facebookGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebooki grupp"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutajatugi"])},
    "supportExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selle vormi kaudu saate saata infot Touringery platformi haldajatele, raporteerida vigu ja anda tagasisidet, et saaksime Touringery veebikeskkonda paremaks muuta."])},
    "tabLabel": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakteerumine"])},
      "helpCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abi"])}
    },
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])}
  },
  "switchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaheta ettevõtet"])},
  "tabs": {
    "servicePeriodsForEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hind"])}
  },
  "takeMeThere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vii mind sinna"])},
  "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsk"])},
  "taskAcceptSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsk vastu võetud"])},
  "taskColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsu värv"])},
  "taskColorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülesande värv tähistab kalendrivaates ülesandekaardi värvi. Mis parandab kontrasti ja muudab kalendri loetavamaks."])},
  "taskCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsu loomine õnnestus"])},
  "taskCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsu loomine ebaõnnestus"])},
  "taskDeleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsu kustutamine ebaõnnestus"])},
  "taskDeletedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsu kustutamine õnnestus"])},
  "taskDurationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsu kestus on saadaval ainult siis, kui töökäskude tüübiks on seatud avatud aeg. Töökäsu kestus võimaldab määrata kestuse vähem kui algus- ja tähtaja vahe, et vastuvõtja saaks töökäskudega tegelema hakata siis, kui selleks vaba aega jääb."])},
  "taskEndedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsk on lõppenud ja seda ei saa enam muuta"])},
  "taskGeneratedCreatedSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatiseeritud töökäsku '", _interpolate(_named("taskTitle")), "' loodi edukalt"])},
  "taskGeneratedCreationError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatiseeritud töökäsu '", _interpolate(_named("taskTitle")), "' loomine ebaõnnestus"])},
  "taskGeneratedDeleteError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatiseeritud töökäsu '", _interpolate(_named("taskTitle")), "' kustutamine ebaõnnestus"])},
  "taskGeneratedDeleteSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatiseeritud töökäsku '", _interpolate(_named("taskTitle")), "' kustutati edukalt"])},
  "taskGeneratedUpdateError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatiseeritud töökäsu '", _interpolate(_named("taskTitle")), "' uuendamine ebaõnnestus"])},
  "taskGeneratedUpdatedSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatiseeritud töökäsku '", _interpolate(_named("taskTitle")), "' uuendati edukalt"])},
  "taskGenerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatiseeritud töökäsud"])},
  "taskGeneratorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siin saate määratleda töökäske, mis luuakse vastavate muutujate järgi"])},
  "taskHasEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsk on lõppenud"])},
  "taskInformationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäskude andmed on salvestastud"])},
  "taskInformationSavedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga töökäskude andmete salvestamisel"])},
  "taskMarkedAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsk on märgitud tehtuks"])},
  "taskMarkedAsNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsk on märgitud mittetehtuks"])},
  "taskPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsu periood"])},
  "taskRefuseSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsk tagasi lükatud"])},
  "taskRefuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lükka töökäsk tagasi?"])},
  "taskReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeldetuletus teostajale"])},
  "taskReminderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeldetuletusaeg teavitab tegijat e-maili teel eesootavast töökäsust"])},
  "taskWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoiatus vastutajale"])},
  "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsud"])},
  "teamPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Õigused"])},
  "termsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üldtingimused"])},
  "textEditor": {
    "enterLinkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisesta link"])}
  },
  "thisMonthResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruandekuu andmed"])},
  "ticket": {
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostja"])},
    "fetchTicketError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pileti andmete pärimine ebaõnnestus"])},
    "invalidScannedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skannitud koodi sisu on ootamatu vorminguga"])},
    "notValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideerimata"])},
    "paidAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makstud"])},
    "ticketLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilet"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piletid"])},
    "ticketsValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideeri"])},
    "unValidateTicketSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pileti validatsioon on edukalt peatatud"])},
    "unvalidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuda valideeritust"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtib alates"])},
    "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtib kuni"])},
    "validateTicketError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pileti valideerimine ebaõnnestus"])},
    "validateTicketSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilet on edukalt valideeritud"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideeritud"])},
    "validatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideerimisaeg"])},
    "validatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideerija"])},
    "validationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideerimine"])},
    "validationState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olek"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtivus"])}
  },
  "ticketValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideerimine õnnestus"])},
  "ticketValidationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideerimine ebaõnnestus"])},
  "ticketValidity": {
    "disclaimer": {
      "daysFromPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päeva alates ostust"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kuni"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtib"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtib alates"])},
      "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtib kuni"])},
      "validDuringWholeTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilet kehtib kogu valitud ajavahemiku jooksul."])},
      "validUntilIsLimitedByEndDynamic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pilet kehtib ", _interpolate(_named("duration")), " alates aktiveerimisest või kuni ajavahemiku lõpuni, kumbiganes saabub varem."])},
      "validUntilNotLimitedByEndDynamic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pilet kehtib ", _interpolate(_named("duration")), " alates aktiveerimisest."])}
    },
    "fixedDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fikseeritud kuupäevad"])},
    "fromUntilDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algus- ja lõpukuupäev"])},
    "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Võite sisetada hinna kehtivusaja, mida kuvatakse antud perioodi hinnastusega seotud piletitel. Määratlege algus- ja lõpukuupäev või kehtivus alates ostuhetkest"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinna kehtivusaeg"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puudub"])},
    "periodFromPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periood alates ostust"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehtivusaja tüüp"])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pealkiri"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuni"])},
  "toDetailView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ava"])},
  "total": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summa"])},
    "payBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagastamisele kuulub"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasumisele kuulub"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokku"])},
    "totalWithDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokku koos allahindlusega"])},
    "totalWithoutVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokku ilma KM"])}
  },
  "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hind kokku"])},
  "totalTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sissetulek majutusteenuse müügist"])},
  "travelPurpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisi eesmärk"])},
  "travelingToDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sihtkohta jõudmine"])},
  "travellersCountry": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisija riik"])},
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riigi kood"])},
    "housingOvernight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ööbimiste arv"])},
    "roomsSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutatute arv"])}
  },
  "travellingPurposesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majutatute jaotus reisi eesmärgi järgi"])},
  "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
  "trigon": {
    "checkBoxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühendus Trigoniga sisse lülitatud"])},
    "emailPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigoni unikaalne email arvete jaoks"])},
    "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigon integratsiooni lubamisel saadetakse kõigist arve e-kirjadest koopia automaatselt Trigonile, kus arved digitaliseeritakse hõlpsamaks töötlemiseks. Trigonist saab unikaalse identifikaatoriga emaili, kuhu ühenduse loomisel Touringery edaspidi kõik arved saadab."])},
    "settingChangeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midagi läks valesti seadete salvestamisel"])},
    "settingChangedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seaded muudetud"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühendus Trigoniga"])}
  },
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüüp"])},
  "types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüübid"])},
  "unCheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühjenda valikud"])},
  "unauthorized": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teil pole sellele vaatele juurdepääsuluba"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volitamata"])}
  },
  "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ühik"])},
  "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuni"])},
  "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulemas"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuenda"])},
  "updateCartItemError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostukorvi uuendus ebaõnnestus"])},
  "updateCartItemSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostukorv edukalt uuendatud"])},
  "updateInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuenda ostukorvi"])},
  "uploadNewAvatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadige üles uus avatar"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutaja"])},
  "userNotAssociatedWithCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutaja pole seotud ühegi ettevõttega"])},
  "userRoleSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutaja rolli salvestamine ebaõnnestus"])},
  "userRoleSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutaja rolli salvestamine õnnestus"])},
  "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puhkus"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KM"])},
  "vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KMKR number"])},
  "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["külaline"])},
  "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["külalist"])},
  "warningInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töökäsu hoiatusaeg hoiatab vastuvõtjat ja adminit meili teel reageerimata töökäsu eest"])},
  "week": {
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reede"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esmaspäev"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laupäev"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pühapäev"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neljapäev"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teisipäev"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolmapäev"])}
  },
  "weekSlots": {
    "buttons": {
      "setClear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tühi"])},
      "setNineToFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9-17 tööpäevadel"])},
      "setTenToEight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10-20 igapäev"])},
      "setWholeWeek ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terve nädal"])}
    }
  },
  "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millal"])},
  "whenAreYouGoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Millal soovite minna?"])},
  "where": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kus"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töö"])},
  "wrongUserNameOrPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vale kasutajanimi või parool!"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jah"])},
  "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sinu-profiil"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postiindeks"])}
}
export default {
  "CheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン"])},
  "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "Search for address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所を検索する"])},
  "When": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いつ"])},
  "aboutTheExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳しくは"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け入れる"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認されました"])},
  "access": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクセス"])},
    "byCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["車で"])},
    "byPublicTransport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公共交通機関をご利用の場合"])},
    "byTourBus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["観光バスで"])},
    "isHardSurfacedRoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬い路面"])},
    "isSignPosted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["道標や道路標識"])},
    "onFoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["徒歩で"])}
  },
  "activation": {
    "activateUserError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーのアクティベーションに失敗しました"])},
    "activateUserSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーは正常にアクティベートされました"])},
    "invalidActivationCodeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なアクティベーション コードです"])}
  },
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])},
  "addAnotherDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引を追加"])},
  "addAnotherHousingUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジングユニットを追加する"])},
  "addAnotherPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別の期間を追加する"])},
  "addAnotherPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別の価格を追加"])},
  "addAnotherUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別のユーザーを追加する"])},
  "addAttachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添付ファイルを追加する"])},
  "addBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネスを追加する"])},
  "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しく追加する"])},
  "addNewBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいビジネスを追加する"])},
  "addNewClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいクライアントを追加する"])},
  "addNewResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいリソースを追加する"])},
  "addNewTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいタスクを追加する"])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートに追加"])},
  "addToCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートに追加されました"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加した"])},
  "addedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加された日付"])},
  "additionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加情報"])},
  "additionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加サービス/製品"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
  "address_": {
    "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建物"])},
    "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郡"])},
    "farmApartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["農場/アパート"])},
    "villageSuburbTown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["村/郊外/町"])}
  },
  "admin": {
    "companyListPage": {
      "filters": {
        "showTestCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テストサービスプロバイダーを表示"])}
      },
      "headers": {
        "isTestCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テストサービスプロバイダー"])},
        "lastUserLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前回のログイン"])},
        "publishedServicesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事を公開しました"])},
        "servicesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事"])},
        "usersCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])}
      },
      "updateIsTestCompanyErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["サービス プロバイダー「", _interpolate(_named("company")), "」のテスト ステータスの更新に失敗しました:"])},
      "updateIsTestCompanySuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["サービス プロバイダー「", _interpolate(_named("company")), "」のテスト ステータスが正常に更新されました。"])}
    },
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者"])},
    "postponedRefundsPage": {
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日"])},
      "retryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再試行"])},
      "retryRefundErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["払い戻しの再試行は失敗しました"])},
      "retryRefundSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["払い戻しの再試行は成功しました"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["払い戻し延期"])}
    },
    "tabs": {
      "commissionReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["委員会報告書"])},
      "depositReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金報告書"])},
      "providerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスプロバイダー"])}
    }
  },
  "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人"])},
  "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アメニティ"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["額"])},
  "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アパート"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本気ですか？"])},
  "arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到着日"])},
  "attendants:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加人数:"])},
  "automatically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動的に"])},
  "availableTickets": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("maxGroupSize")), " 個の利用可能なチケットが残っています"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
  "baseMultimedia": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像の追加、タイトル、表示順の変更、削除ができます。"])}
  },
  "basicInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本情報"])},
  "bed_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッド"])},
  "beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッド"])},
  "bedsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッド"])},
  "beneficialOwners": {
    "beneficialOwners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受益者"])},
    "beneficialOwnersLocationInAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受益者に関する詳細情報は、次の法律の第 9 条に記載されています。"])},
    "linkToAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エストニアのマネーロンダリングおよびテロ資金供与防止法"])},
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受益者は指定されていません。"])},
    "whyWeCollectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touringery は、現地の法律および当社の取引処理者の要件に従って、サービスプロバイダーの受益者に関する情報を収集します。"])}
  },
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求する"])},
  "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
  "bookKeeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["簿記"])},
  "book_keeping": {
    "goToTrigonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigon 会計プログラムに移動します"])}
  },
  "bookingCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約が正常に作成されました"])},
  "bookingCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約の作成に失敗しました"])},
  "bookingForm": {
    "buttons": {
      "addBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約を追加する"])}
    },
    "clientSection": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客情報"])}
    },
    "editExistingClientAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいクライアントを作成するのではなく、既存のクライアントの連絡先情報を更新しています。\n\n新しいクライアントを作成するには、既存のクライアントを選択からクリアする必要があります"])},
    "fields": {
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間隔"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客数"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
      "timeslotDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約期間"])},
      "timeslotStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約開始"])},
      "timeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])}
    },
    "loadingDatePickerTimeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在庫状況を読み込み中"])},
    "noTimeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料のタイムスロットが見つかりません"])},
    "removeClientLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントを削除する"])},
    "serviceSection": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品"])}
    },
    "timeslotsNotLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料のタイムスロットを表示するには、セール記事を選択してから日付を選択してください"])}
  },
  "bookingIntegration": {
    "activateConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続をアクティブにする"])},
    "activateRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルーム接続を有効にする"])},
    "bookingAgreeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統合の制限と Booking.com チャネルへの影響を理解し、同意します"])},
    "bookingProperty": {
      "checkPropertyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続ステータスを更新する"])},
      "refreshRoomRatesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客室と料金プランをリフレッシュ"])}
    },
    "cancelConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続をキャンセルする"])},
    "cancelRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋の接続をキャンセルする"])},
    "closedRestrictionsCheckboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.comで閉鎖中の制限を確認しました"])},
    "completedErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com との統合に失敗しました。\n接続をキャンセルして再試行するか、サポートにお問い合わせください。"])},
    "completedSuccessMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Booking.com との統合が正常に完了しました。 \n", _interpolate(_named("new_reservations_created")), " 新しい予約が作成されました"])},
    "connectRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋に接続する"])},
    "connectToPropertyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロパティに接続する"])},
    "connecting": {
      "activatePropertyFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com で必要な手順が完了したので、こちら側で接続とマッピングを開始します。"])},
      "activatePropertySecondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まず最初に、booking.com で宿泊施設との接続を作成します。\nそのためには、booking.com から宿泊施設の宿泊施設 ID を入力し、「接続を有効にする」を押す必要があります。"])},
      "activatePropertyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["財産とのつながり"])},
      "activateRoomsFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続が有効になったら、施設の部屋をトゥーランジェリーの対応する住宅にマッピングします。\nハウジングコネクションを有効にするには、booking.com からリストされているすべての部屋をマッピングする必要があります。"])},
      "activateRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋のマッピング"])},
      "activeConnectionFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋のマッピングと接続が正常に完了すると、Touringery は空室状況、価格、制限データを Booking.com に同期し始めます。\n同期の速度は、全体的な統合アクティビティと、すべての接続を同期するために必要な更新の数によって異なります。"])},
      "activeConnectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブな接続"])},
      "closedRestrictionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統合を設定する前に、既存のクローズド制限を削除する必要があります。 \nAPI では以前に終了した制限を削除できず、接続プロバイダーがアクティブ化されている場合は Booking.com カレンダーを通じて制限を削除できないため、これが必要です。"])},
      "closedRestrictionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉鎖された制限事項"])},
      "connectToConnectivityProviderLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続プロバイダーのセットアップと連携"])},
      "connectivityProviderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初に行う必要があるのは、Booking.com でサポートされている接続プロバイダーに接続することです。"])},
      "connectivityProviderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続プロバイダー"])},
      "connectivityProviderZodomusText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サポートされている接続プロバイダーの名前は「Zodomus」です。"])},
      "connectivityTutorialLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["そのために必要な手順は、次の Booking.com の記事で紹介されています。"])},
      "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この統合では、客室と宿泊料金を備えた既存の Booking.com 施設が必要です。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続方法"])}
    },
    "deleteConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続の削除"])},
    "description": {
      "centralizedToTouringeryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この統合により、Touringery を通じて施設の空室状況、価格、制限を管理できるようになります。"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])}
    },
    "housingHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストから一致する Touringery ハウジングを選択してください"])},
    "limitations": {
      "bookingReservationsFunctionalityText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com で行われた予約は、Touringery のカレンダーに表示されます。\nいくつかの基本的なクライアント情報を確認することができます。\nメールテンプレートとチェックイン機能も適用されます。"])},
      "durationPriceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間に基づく価格変更は Booking.com に同期されません。\nこれらはトゥランジェリーの価格にのみ影響します。"])},
      "initialConnectionUpdatesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初回接続時にすべての価格設定と可用性を同期する必要があるため、大量の更新が必要になります。\n更新の量は、コネクテッド ハウジングの価格設定期間の複雑さと、Touringery での既存の予約数によって異なります。"])},
      "numberOfUpdatesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新数"])},
      "priceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
      "reservationsLimitedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今後の新規および既存の予約は Booking.com から Touringery に同期されますが、システム内の機能は制限されます。"])},
      "reservationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約"])},
      "standardPriceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当社の住宅の価格設定期間に指定された全額が、Booking.com の宿泊料金の標準価格として設定されます。"])},
      "standardPricingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com では複数の料金タイプを提供していますが、当社では標準料金モデルのみをサポートしています。"])},
      "syncBookingReservationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一方、Booking.com での予約に関する更新は個別に処理され、共有キューの長さの影響を受けません。"])},
      "syncOnDemandText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その後、Touringery で関連する変更が行われたときに同期が行われます。\nこれらには、居住期間、関連する好み、関連する予約および閉鎖の変更が含まれますが、これらに限定されません。"])},
      "syncingFrequencyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touringery から Booking.com への変更の同期は即座には行われません。\nすべての更新は共有キューに送られます。つまり、Booking.com プロパティの更新にかかる時間は、その中での更新の位置によって異なります。"])},
      "syncingTimeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新頻度"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制限事項"])}
    },
    "propertyActivationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロパティのアクティブ化でのエラー"])},
    "propertyActivationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロパティが正常にアクティブ化されました"])},
    "propertyCancellationnError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続キャンセルエラー"])},
    "propertyCancellationnSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続が正常にキャンセルされました"])},
    "propertyHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com との統合を開始するには、まず予約に宿泊施設 ID を入力します。\nこれは、booking.com 管理サイトのヘッダー、Booking ロゴのすぐ隣にあります。"])},
    "propertyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロパティID"])},
    "roomIdLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.comの部屋ID"])},
    "roomsActivationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジング接続が正常にアクティブになりました"])},
    "roomsctivationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジング接続をアクティブにする際のエラー"])},
    "statusActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ化された"])},
    "statusError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
    "statusInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活性"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.comチャネルマネージャー"])},
    "updateRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋の接続を更新する"])},
    "zodomusConnectedCheckboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com で接続プロバイダーとして Zudomus を設定しました"])}
  },
  "bookingWidget": {
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートに追加"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択する"])},
    "chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択済み"])},
    "errors": {
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した日付/時間範囲が空いていることを確認してください"])},
      "consecutiveSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した時間範囲が連続していることを確認してください"])},
      "slotsMaxDuration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["選択した時間範囲が", _interpolate(_named("maxDuration")), "を超えないことを確認してください"])},
      "slotsMinDuration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["選択した時間範囲が少なくとも", _interpolate(_named("minDuration")), "であることを確認してください"])}
    },
    "goToBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入へ進む"])},
    "groupSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループサイズ"])},
    "noAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この期間の空きはありません"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果がありません"])},
    "pickService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスを選んで空きを確認してください"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
    "whatWillYouBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何を購入しますか？"])},
    "wholeDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " (終日)"])}
  },
  "bookingsCalendar": {
    "onlyTransactionsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トランザクションのみを表示"])},
    "reservationDetails": {
      "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲスト"])},
      "openDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オープン予約"])},
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約元"])},
      "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別なお願い"])},
      "seeAllBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このゲストからのすべての予約を表示"])},
      "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計金額"])}
    }
  },
  "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建物"])},
  "businessDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事業内容"])},
  "businessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社概要"])},
  "businessName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商号"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダー"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約をキャンセルする"])},
  "cancelMessageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここでキャンセルの理由を説明できます"])},
  "cancelOrder": {
    "cancelServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文キャンセル時のエラー。"])},
    "cancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文は正常にキャンセルされました"])},
    "cannotCancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現時点ではこの注文をキャンセルできません"])},
    "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この注文をキャンセルしてもよろしいですか?"])},
    "confirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文をキャンセルする"])},
    "removeOrderItemConfirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この商品を注文から削除してもよろしいですか?"])},
    "removeOrderItemConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文から削除"])},
    "removeOrderItemServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文から商品を削除する際のエラー"])},
    "removeOrderItemSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文から正常に削除されました"])}
  },
  "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["容量"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カート"])},
  "cartOverview": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["注文番号 #", _interpolate(_named("referenceNumber")), " の概要"])},
  "cartPage": {
    "cartConfirmErrorAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カート確認時のエラー"])},
    "cartConfirmSuccessAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートは正常に確認されました。"])},
    "cartConfirmedOrderExpiresAtAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ショッピング カートが確認されました。有効期限は ", _interpolate(_named("expiresAt")), " に切れます。"])},
    "clientConfirmedOrderExpiresAtAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["カートに加えて顧客情報も確認されており、有効期限は ", _interpolate(_named("expiresAt")), " です。"])},
    "noItemsInCartText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショッピングカートは空です"])},
    "orderStatusCancelledAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文はキャンセルされます。"])},
    "orderStatusPaidAlertWithTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文は支払い済みの状態です。\nチケットをダウンロードします。"])},
    "orderStatusPaidAlertWithoutTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文は支払い済みの状態です。"])},
    "orderStatusPendingAlertWithTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引は正常に完了しました。\n最終確認は遅れて電子メールで配信されます。\nチケットをダウンロードします。"])},
    "orderStatusPendingAlertWithoutTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引は正常に完了しました。\n最終確認は遅れて電子メールで配信されます。"])},
    "orderStatusTimedOutAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文がタイムアウトしました。"])}
  },
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリー"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリー"])},
  "ceoOrBoardMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常務取締役または取締役会メンバー"])},
  "changeHousingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊データを変更する"])},
  "channel": {
    "activateChannelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルに正常に接続されました。"])},
    "activateRoomsSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物件の部屋は正常に接続されました。"])},
    "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネル"])}
  },
  "channels": {
    "activateChannelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルに接続できませんでした。"])},
    "activatePropertyRoomsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋を接続する"])},
    "activateRoomsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロパティルームの接続に失敗しました。"])},
    "booking-widget-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開ビュー"])},
    "bookingActivation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいBooking.comチャネル"])}
    },
    "bookingProperty": {
      "checkPropertyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続ステータスの更新に失敗しました。"])},
      "checkPropertySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続ステータスが更新されました。"])},
      "refreshRoomRatesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設の部屋と関連料金プランを更新できませんでした。"])},
      "refreshRoomRatesSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設の客室と関連料金プランが正常に更新されました。"])}
    },
    "bookingPropertyChannelTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["プロパティ ", _interpolate(_named("property"))])},
    "channel-manager-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルマネージャー"])},
    "confirmPropertyDeactivationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このチャンネルを無効にしてもよろしいですか?"])},
    "connectPropertyChannelLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続する"])},
    "connectRoomsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの施設内の Booking.com の客室を接続するには、その標準料金プランと一致する住宅物件を選択する必要があります。"])},
    "deleteChannelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルの削除に失敗しました。"])},
    "deleteChannelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルが正常に削除されました。"])},
    "fetchPropertyChannelDetailsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネル詳細の読み込みに失敗しました。"])},
    "header": {
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロパティID"])}
    },
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルが見つかりません。"])},
    "noPropertyRoomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物件内に部屋が見つかりません。"])},
    "propertyConnectionNeededForRoomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊施設に接続すると部屋が表示されます。"])},
    "ratePlanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金プラン"])},
    "roomHousingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した住宅用品の住宅ユニット数は、部屋のユニット数と同じか、それ以下である必要があります。\nそうしないと、Booking.com が受け入れる可用性よりも高い可用性を同期しようとするとエラーがスローされます。"])},
    "roomRatePlanInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touringery からのデータに基づいて更新する必要がある料金プラン。\n標準料金プランのみがサポートされています。"])},
    "roomTitleWithNameAndId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルームID"])},
    "statusLabel": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活性"])},
      "PROPERTY_ACTIVATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分的に接続されています"])}
    },
    "tabLabel": {
      "bookingWidget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開ビュー"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネル"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネル"])}
  },
  "checkAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空き状況を確認する"])},
  "checkIn": {
    "aboutClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントについて"])},
    "aboutClientStaying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約が他の人のために購入された場合は、次のオプションを選択しないでください。\n複数の部屋をご予約の場合は、ご宿泊予定の予約のみ下記オプションをお選びください。"])},
    "aboutTravelCompanions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同行者全員のフォームに記入してください"])},
    "aboutTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行データ"])},
    "aboutYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのデータ"])},
    "addTravelCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行仲間を追加する"])},
    "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加の質問"])},
    "additionals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加の質問"])},
    "additionalsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択したサービスのチェックイン フォームに追加される追加の質問を作成できます。\n答えは最大 1000 文字のテキストフィールドです"])},
    "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アダルト"])},
    "ageGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年齢層"])},
    "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本気ですか？"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問を変更する場合は、すでに保存されている質問にも遡って変更されることに注意してください。"])},
    "checkInDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックインの詳細"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供"])},
    "confirmCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供されたデータが正しいことを確認します"])},
    "detailsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行の詳細"])},
    "downloadForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォームをダウンロード"])},
    "estimatedCheckInTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン予定時間"])},
    "groupSizeHasChanged": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["訪問者数が変更されたため、元の ", _interpolate(_named("originalCount")), " の代わりに ", _interpolate(_named("guestsPlural")), " が指定されました。これによりサービスが変更される可能性があります"])},
    "hoursRange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("check_in_hours_range")), " の間"])},
    "housingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宅詳細"])},
    "isClientStayingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントはこの予約に滞在しています"])},
    "isForConferenceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カンファレンスに参加する"])},
    "isNotSubmittedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データを送信してフォームを保存してください"])},
    "isSubmittedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォームが送信されました"])},
    "isWrongReservationTypeAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在の予約ステータスではフォームを送信できません"])},
    "openCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートへ行く"])},
    "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行の目的"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データ保存エラー"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データは正常に保存されました"])},
    "serviceSelectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事を選択すると、関連する質問が表示されます"])},
    "statusSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出済み"])},
    "statusUnSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未提出"])},
    "tavelCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅仲間"])},
    "tavelCompanions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行仲間"])},
    "travelData": {
      "clientHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント"])}
    },
    "travelPurpose": {
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他の"])}
    },
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休暇"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仕事"])}
  },
  "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供たち"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択"])},
  "chooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを選択してください"])},
  "chooseSubCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブカテゴリを選択してください"])},
  "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市民権"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市区町村"])},
  "cityRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["村/町/地区"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリア"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント"])},
  "clientBookings": {
    "tableHeaders": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間隔"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲスト"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
      "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエスト"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソース"])}
    }
  },
  "clientCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントが正常に作成されました"])},
  "clientCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントの作成に失敗しました"])},
  "clientInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客情報"])},
  "clientSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客情報が保存されます"])},
  "clientSavedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント情報の保存に失敗しました"])},
  "clientTab": {
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求する"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバック"])}
  },
  "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント"])},
  "clients_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントの名前"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉まっている"])},
  "color": {
    "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["青"])},
    "blue_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ティール"])},
    "blue_purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バイオレット"])},
    "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緑"])},
    "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オレンジ"])},
    "purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紫"])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赤"])},
    "red_orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["朱色"])},
    "red_purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赤紫色"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["色"])},
    "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黄色"])},
    "yellow_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シャルトルーズ"])},
    "yellow_orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンバー"])}
  },
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
  "commentUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントの追加に失敗しました"])},
  "commentUpdateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントが追加されました！"])},
  "companyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社が正常に作成されました"])},
  "companyCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の作成に失敗しました"])},
  "companyLocation": {
    "confirmDeleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この場所を削除してもよろしいですか?"])},
    "createLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["場所の作成に失敗しました"])},
    "createLocationModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["場所の作成"])},
    "createLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["場所が正常に作成されました"])},
    "deleteLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["場所の削除に失敗しました"])},
    "deleteLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["場所が正常に削除されました"])},
    "fetchLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置情報の読み込みに失敗しました"])},
    "generalBlockTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般情報"])},
    "updateLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置情報の更新に失敗しました"])},
    "updateLocationModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新場所"])},
    "updateLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置情報が正常に更新されました"])}
  },
  "companyLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社のロゴ"])},
  "companyNotifications": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社からのお知らせ"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の電子メールに送信される自動電子メール通知を構成する"])},
    "notification": {
      "eventCancelDelayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント中止延期"])},
      "eventCancelDelayedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントの自動キャンセルが一時的に遅れるたびに送信されます。\n遅延の理由としては、支払いの保留が考えられます。"])},
      "eventCancelPassed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントの最小参加者に達しました"])},
      "eventCancelPassedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動キャンセルの確認時にイベントの最小参加者数に達した場合に送信されます。\nこれは、特定のイベントの自動キャンセルが無効になることを意味します。"])},
      "eventCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント中止"])},
      "eventCancelledDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントが自動的にキャンセルされるたびに送信されます"])},
      "orderPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規注文"])},
      "orderPaidDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスプロバイダーの販売商品の支払いが完了するたびに送信されます。つまり、新しい注文が作成されるたびに送信されます。"])},
      "partnerInviteResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップの招待への応答"])},
      "partnerInviteResponseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企業へのパートナーシップへの招待が受諾されたときに送信されます"])},
      "partnerPayoutChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップの支払い率の変更"])},
      "partnerPayoutChangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーが支払い率を変更したときに送信されます"])},
      "partnerStatusChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップステータスの変更"])},
      "partnerStatusChangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップのステータスが変更されたときに送信されます"])},
      "reservationCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約がキャンセルされました"])},
      "reservationCancelledDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様が予約をキャンセルするたびに送信されます"])}
    },
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールを送る"])}
  },
  "companyRole": {
    "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割の追加"])},
    "alertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者の役割は変更または削除できません。\n社内に管理者の役割を持つ人が少なくとも 1 人いる必要があります"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しく作る"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読む"])},
    "removeRoleConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この役割を削除してもよろしいですか?"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップデート"])}
  },
  "companyRoles": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "permissios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["権限"])}
  },
  "compucash": {
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash側のクライアント名"])},
    "clientSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash側のクライアントシークレット"])},
    "dataIsStillSyncing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucashのデータはまだ同期中です。後でもう一度お越しください。"])},
    "deleteProduct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Compucash製品 ", _interpolate(_named("productId")), " - ", _interpolate(_named("productName")), " を削除"])},
    "deleteProductConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この製品を削除してもよろしいですか？"])},
    "deleteProductInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この削除操作は、Touringeryに保存されたCompucash製品データのみを削除します。関連するTouringeryサービスは削除されません。"])},
    "doubleCheckCompucashData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash統合データを再確認してください。詳しくは、TouringeryまたはCompucashにお問い合わせください。"])},
    "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash統合に必要なデータで、CompucashからTouringeryに製品を同期します。必要な値はCompucashに問い合わせてください。"])},
    "makeIntoService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスに接続"])},
    "makeIntoServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash製品をサービスに接続"])},
    "newServiceForCompucashProduct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Compucash製品", _interpolate(_named("productId")), "用の新しいサービス"])},
    "productDeprecatedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後のCompucash同期応答にはこの製品が含まれていませんでした。おそらく削除されたか、Web販売が禁止されているためです。関連するサービスは直接販売を許可しないようにプライベートに設定されています。もしこのサービスが必要ない場合は、サービス設定でアーカイブするオプションがあります。もしこれが間違いであれば、Compucashで製品を確認してください。"])},
    "productID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品ID"])},
    "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品名"])},
    "serviceSettingsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash製品設定"])},
    "serviceSettingsHeaderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス提供者がCompucashと接続している場合、ここで既に公開されている販売記事をCompucash製品にリンクできます。"])},
    "serviceSettingsUserAddedRelatedCompucashId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucashの製品ID"])},
    "serviceSettingsUserAddedRelatedCompucashIdInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これはCompucashの製品IDで、Touringeryにある既存の製品を新しく作成するのではなく、Compucashと同期させるために使用されます。"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])},
    "sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同期"])},
    "syncFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucashの同期に失敗しました。Touringeryにお問い合わせください。"])},
    "syncFinishedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucashの同期が正常に完了しました。"])},
    "syncStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同期を開始しました"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash統合設定"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期待される消費税"])},
    "withoutServiceToggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスのない製品のみを表示"])}
  },
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認する"])},
  "confirmAndPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払う"])},
  "confirmDialog": {
    "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本気ですか？"])}
  },
  "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
  "confirmationEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約メールが正常に送信されました"])},
  "confirmationEmailSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール送信時に問題が発生しました"])},
  "contactServiceProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続く"])},
  "coordinates": {
    "DD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD (10 進度)"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["座標"])}
  },
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピーしました！"])},
  "copyUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URLをコピー"])},
  "countdown": {
    "inPast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去に"])}
  },
  "countryOfResidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["居住国"])},
  "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郡"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成する"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを作成する"])},
  "createBulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一括作成"])},
  "createNewResourceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいグループを作成する"])},
  "createServiceDraftError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事の下書きの作成に失敗しました"])},
  "currentCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在"])},
  "customerLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客ラベルを追加する"])},
  "customerLabelsAndComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客のラベルとコメント"])},
  "customerRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様の評価"])},
  "dataIsSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データが保存されます"])},
  "dataLoadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "dataSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データの保存に失敗しました"])},
  "dataSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データは正常に保存されました"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
  "dayNoHourOption": {
    "fromTheStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一日の始まり"])},
    "untilEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一日の終わり"])}
  },
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日々"])},
  "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["締め切り"])},
  "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遅れ"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消去"])},
  "deleteClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントの削除"])},
  "deleteResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースの削除"])},
  "deleteTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクの削除"])},
  "departure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出発日"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
  "disableTrackList": {
    "addItemModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規閉店"])}
  },
  "disabledTrackItem": {
    "allExcludedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全て"])},
    "confirmDeleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本気ですか？"])},
    "deleteTrackErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージャーの削除に失敗しました。"])},
    "deleteTrackSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージャは正常に削除されました。"])},
    "exclusionTypeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉鎖の種類"])},
    "form": {
      "endLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了（除く）"])},
      "startLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スタート（付属）"])}
    },
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉鎖"])},
    "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量"])},
    "trackType": {
      "fullDisable": {
        "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのゲストを閉じる"])},
        "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのユニットを閉じます"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて閉じる"])}
      },
      "quantityDisable": {
        "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部のゲストを閉じる"])},
        "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いくつかのユニットを閉じる"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部を閉じる"])}
      }
    },
    "updateItemErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉鎖を更新できませんでした。"])},
    "updateItemSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージャが正常に更新されました。"])},
    "updateModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新の終了"])}
  },
  "disabledTrackList": {
    "addItemButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージャを追加"])},
    "createItemErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージャーの作成に失敗しました。"])},
    "createItemSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージャが正常に作成されました。"])},
    "experience": {
      "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲスト"])}
    },
    "housing": {
      "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宅ユニット"])}
    },
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉鎖はありません。"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロージャー"])}
  },
  "discount": {
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引"])},
    "inDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数日で"])},
    "inHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数時間以内"])},
    "numOfDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何日"])},
    "numOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲストは何名ですか"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
    "untilDaysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(日数)"])},
    "untilGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ゲストの数)"])},
    "untilSumLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（いくら）"])},
    "untilYearLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（何歳）"])}
  },
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引"])},
  "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["距離"])},
  "distanceToStop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停止までの距離"])},
  "documentExpDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["書類の有効期限"])},
  "documentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["書類番号"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終わり"])},
  "downloadInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求書のダウンロード"])},
  "downloadTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットをダウンロード"])},
  "downloadTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットをダウンロードする"])},
  "dragHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["またはここにドラッグします"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重複"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間隔"])},
  "durationManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動での持続時間"])},
  "durationPicker": {
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間隔"])}
  },
  "durations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間"])},
  "dynamicNameTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " 合計"])},
  "dynamicNight": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["夜"]), _normalize(["夜"]), _normalize(["夜"])])},
  "dynamicNightWithCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 泊"]), _normalize([_interpolate(_named("count")), " 泊"]), _normalize([_interpolate(_named("count")), " 泊"])])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変化"])},
  "editCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常に変更されました"])},
  "editUsers": {
    "changeRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの役割を変更する"])},
    "editUsersLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの編集"])},
    "goToRolesView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロールビューに移動"])},
    "resendInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待状を再送信する"])}
  },
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール"])},
  "emailNotificationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約済みメール"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終わり"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日"])},
  "erfLogo": {
    "altText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欧州地域開発基金のロゴ"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
  "eventCalendarDetails": {
    "eventReservationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約"])},
    "noEventPeriodsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントはありません。"])},
    "noReservationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約なし。"])}
  },
  "eventCancelAtInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cancelDuration")), " 後に合計で少なくとも ", _interpolate(_named("minCapacity")), " チケットが販売された場合に発生します。"])},
  "eventCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントが正常に作成されました"])},
  "eventDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいイベントを作成する場合は、このオプションを選択します。\nイベントは、特定の時間に開催される 1 回限りのセール記事です。"])},
  "eventError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントの作成に失敗しました"])},
  "eventOrderItemRefundableUntil": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("until")), " (イベントの 2 日前) までは全額返金可能です。\nそれ以降のキャンセルの場合は返金はありません。"])},
  "eventResultCard": {
    "priceForGroupText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("groupSize")), " の価格"])}
  },
  "eventService": {
    "eventIsCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントは中止になりました。"])},
    "eventSalesEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントチケットの販売は終了いたしました。"])}
  },
  "eventUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントの更新に失敗しました"])},
  "eventUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントが正常に更新されました"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
  "eventsCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントが正常に作成されました"])},
  "eventsCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントの作成に失敗しました"])},
  "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス"])},
  "experienceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいサービスを作成する場合は、このオプションを選択します。"])},
  "experienceSearch": {
    "calendarLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空き情報を読み込んでいます。これには時間がかかる場合があります。"])}
  },
  "experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス"])},
  "farm/apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["農場/アパート"])},
  "feedbackSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックは正常に送信されました"])},
  "feedbackSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックの送信に失敗しました"])},
  "fileUploadFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルのアップロードに失敗しました!"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファーストネーム"])},
  "fixedTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定タイムスロット"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れですか？"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から"])},
  "full_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全な住所"])},
  "gender": {
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女性"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他の"])}
  },
  "general_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般情報"])},
  "general_information_unfilled_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォームに進む前に、一般情報を入力して保存する必要があります"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成する"])},
  "generatedEventsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成されたイベント"])},
  "goToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートへ行く"])},
  "googleCalendar": {
    "eventModal": {
      "relatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連リソース"])},
      "relatedService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連サービス"])}
    },
    "settings": {
      "authError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleアカウントへのログインエラー"])},
      "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
      "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TouringeryのカレンダーをGoogleカレンダーと同期するには、Googleアカウントでサインインし、利用規約に同意する必要があります。"])},
      "modal": {
        "calendarName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダー"])},
        "calendarType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダータイプ"])},
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントのどれくらい前ですか？"])},
        "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例"])},
        "exampleHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コテージ"])},
        "exampleHousingUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊ユニット 1,2"])},
        "exampleTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清掃"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
        "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フルネーム"])},
        "googleCalendarDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleカレンダーの説明"])},
        "googleCalendarEventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleカレンダーイベント名"])},
        "googleCalendarSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleカレンダーの設定"])},
        "googleCalendarSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleカレンダーの名前"])},
        "groupSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループサイズ"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苗字"])},
        "moneyjarEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["リソース「", _interpolate(_named("name")), "」に今日売られたマネージャージャー：", _interpolate(_named("quantity"))])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
        "productEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["本日販売された「", _interpolate(_named("name")), "」: ", _interpolate(_named("quantity"))])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
        "resourceDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Touringeryシステムからリソースの自動カレンダー \"", _interpolate(_named("name")), "\""])},
        "resourceEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "：数量 ", _interpolate(_named("quantity"))])},
        "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダー設定の保存に失敗しました"])},
        "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダーの設定が保存されました！"])},
        "sendReminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント前にリマインダーを受け取る"])},
        "serviceDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Touringeryシステムから販売アイテムの自動カレンダー \"", _interpolate(_named("name")), "\""])},
        "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品名"])},
        "syncActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同期が有効"])},
        "taskEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (", _interpolate(_named("serviceName")), ", ", _interpolate(_named("unitName")), ")"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleカレンダーの設定を編集"])},
        "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊ユニット"])},
        "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お待ちください、保存に少し時間がかかることがあります..."])}
      },
      "table": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非アクティブ"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
        "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リマインダー"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleカレンダーの設定"])}
    },
    "type": {
      "EVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
      "EXPERIENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス"])},
      "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊"])},
      "MONEYJAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お金の瓶"])},
      "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品"])},
      "RESOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソース"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク"])}
    }
  },
  "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ名"])},
  "groupedServices": {
    "discountPercentageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "groupedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "requiredLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "selectServicesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "selectServicesToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲスト"])},
  "guestsCountriesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["居住国別の宿泊者数の分布"])},
  "guestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲストについて"])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここ"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
  "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家"])},
  "housing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジング"])},
  "housingCalendar": {
    "changeReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約変更"])},
    "goToCartView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートビュー"])},
    "goToReservationView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約ビュー"])},
    "noTimelineEventsInPeriodText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この期間中は予約や休業はありません。"])},
    "showInfo": {
      "availableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能な単位"])}
      },
      "disabledUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除外される単位"])}
      },
      "reservationsCount": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約済みユニット"])}
      },
      "reservedAndAvailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(予約済みの ", "|", " が利用可能) ユニット"])}
      },
      "selectLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["見せる"])},
      "totalAndAvailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(合計 ", "|", " 個が利用可能) ユニット"])}
      },
      "totalAndUnavailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(合計 ", "|", " 個が利用できません) ユニット"])}
      },
      "totalUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総ユニット数"])}
      }
    },
    "unspecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不特定"])}
  },
  "housingCalendarDetails": {
    "availableGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能"])},
    "availableHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能"])},
    "closedHousingUnitsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉まっている"])},
    "guestsPerUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユニットあたりのゲスト数"])},
    "housingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジング"])},
    "noReservationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約なし。"])},
    "reservationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約"])},
    "reservedHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約済み"])},
    "totalGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "totalHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])}
  },
  "housingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいハウジングを作成したい場合は、このオプションを選択してください"])},
  "housingOrderCard": {
    "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アダルト"])},
    "adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大人"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供"])},
    "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子供たち"])}
  },
  "housingOvernightEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エストニアからの一泊旅行者"])},
  "housingOvernightNonEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他国からの一泊旅行者"])},
  "housingRoom": {
    "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋のアメニティ"])},
    "seeAllAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて表示する"])}
  },
  "housingSalesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["収容人数"])},
  "housingSalesTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宅販売総額"])},
  "housingUnit": {
    "displayAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての部屋を表示する"])},
    "doorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドアコード"])},
    "housingUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宅ユニット"])},
    "identificator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["識別子"])},
    "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このタイプの宿泊料金の金額と一意の識別子を挿入します"])},
    "infoHelperDoorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドア コード (必須ではありません) を電子メール テンプレートの変数として使用できます"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジングユニット"])}
  },
  "housings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジング"])},
  "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幾つか"])},
  "howManyAreYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは何人ですか?"])},
  "howToGetThere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["そこに着く方法"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "idCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDコード"])},
  "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["識別子"])},
  "insertEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メールを挿入"])},
  "invitationsSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待状が正常に送信されました"])},
  "invitationsSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待状の送信に失敗しました"])},
  "inviteNewUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいユーザーを招待する"])},
  "inviteUsersForm": {
    "roleAdminInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者 - 社内ですべてのことを行うことができます。複数の会社がある場合は、すべての会社で行うことができます。"])},
    "roleUserInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー - 自分宛てのタスク、カレンダーを表示および編集できます (変更に関する通知は管理者に送信されます)。"])}
  },
  "inviteUsersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待されたユーザーは Touringery に登録され、サイトへの招待メールが届きます。"])},
  "labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客ラベルを追加する"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
  "languageEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語"])},
  "languageEt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エストニア語"])},
  "languageFi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィンランド語"])},
  "languageJa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本語"])},
  "languageRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロシア"])},
  "languageServiceGeneralBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィールドに情報を追加するときは、アプリケーションに指定した言語を使用します。"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苗字"])},
  "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後の更新"])},
  "lastView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後のビュー"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中..."])},
  "loadingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置"])},
  "locationPublicTransport": {
    "addTransportLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公共交通機関を追加する"])},
    "distanceKmLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キロ"])}
  },
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
  "logoExplanationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイドメニュー、ログインフォーム、登録フォームにロゴが表示されます。\n表示する際、ロゴのサイズはスペースに応じて拡大縮小されますが、最大高さは 108 ピクセルです。"])},
  "manageAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの管理"])},
  "markAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了としてマークする"])},
  "markAsNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完了としてマークする"])},
  "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大"])},
  "mealPlanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食事プラン"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージ"])},
  "minAdvanceTimePastWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["事前予約最低時間を過ぎています"])},
  "minParticipantsInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cancelTime")), "までに少なくとも ", _interpolate(_named("minGroupSize")), " 個のチケットが販売されている必要があります"])},
  "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分"])},
  "moneyJarCalendarDetails": {
    "noPaidOrderItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この日に購入された金瓶はありません。"])},
    "orderItemsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お金の瓶"])},
    "totalUnitsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売台数"])}
  },
  "moneyJarDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在庫制限などを考慮せずにアイテムを販売できる新しいサービスのような販売記事を作成したい場合は、このオプションを選択してください。"])},
  "multimedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マルチメディア"])},
  "multimediaTab": {
    "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像を追加する"])},
    "baseFileLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一度に最大5ファイル"])},
    "fetchMultimediaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事マルチメディアの読み込みに失敗しました"])},
    "fileDeleteExistingHeaderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のヘッダーの削除に失敗しました"])},
    "fileDeleteFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("heading")), "」という名前のファイルを削除できませんでした"])},
    "fileHeadingIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["見出しは必須です。"])},
    "fileHeadingTooLong": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["見出しの最大長は 200 文字です。\n現在は ", _interpolate(_named("length")), " 文字です。"])},
    "fileOverLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ファイル「", _interpolate(_named("name")), "」のサイズが 5MB を超えています"])},
    "fileUpdateFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("heading")), "」という名前のファイルを更新できませんでした"])},
    "fileUploadFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「", _interpolate(_named("heading")), "」という名前のファイルのアップロードに失敗しました"])},
    "fileUploadHeaderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいヘッダーのアップロードに失敗しました"])},
    "fileWrongFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ファイル「", _interpolate(_named("name")), "」形式はサポートされていません。\nサポートされている: SVG、JPG、JEPG、PNG"])},
    "filesSaveFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルの保存に失敗しました"])},
    "filesSaveFailedPartially": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部のファイルの保存に失敗しました"])},
    "filesSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルは正常に保存されました"])},
    "gallery": {
      "altTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altタグ"])},
      "altTagHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真が表示されない場合に備えて、説明テキストを追加します"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["見出し"])},
      "headingHelperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真の見出しを追加します。\n最大長は 200 文字です。"])},
      "maxSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推奨最大サイズ: 5MB"])},
      "minDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推奨される最小サイズ: 960x 5 40 px"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギャラリー"])}
    },
    "instructions": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明書"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魅力的で高品質の画像を追加します。"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像のサイズと寸法については、考慮すべき方向性があることに注意してください。"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真のファイル名は英語であることが望ましいです。"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["知的財産があなたのものであること、およびマルチメディアを観光情報システムにアップロードすることが許可されていることを確認してください。\nマルチメディアを追加すると、追加した情報を使用するための無料の世界規模のシンプル ライセンスを管理者に付与します。"])}
    },
    "isServiceHeaderLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘッダー写真"])},
    "ticket": {
      "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像を追加"])},
      "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売商品のチケットに画像を追加できます"])},
      "minDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推奨される最小寸法: 幅が 150 ピクセル"])},
      "noPricesAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示する価格がありません"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットプレビュー"])},
      "showPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビューを表示する"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット"])}
    }
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
  "nearbyTransportStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["近くの公共交通機関の停留所"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい"])},
  "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいイベント"])},
  "newResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいリソース"])},
  "newService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい販売記事"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次"])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["夜"])},
  "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["夜"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ"])},
  "noAvaiableDatesInMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今月は予約可能な日付が含まれていません"])},
  "noAvailabilityRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらの日付には十分な空き状況がありません。\nこのプロバイダーの他のサービスを見る"])},
  "noAvailabilityWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらの日付には十分な空き状況がありません。\n別の日付をお試しください"])},
  "noEventAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント期間はありません。\nフィルタを確認するか、少なくとも 1 つの期間を作成してください。"])},
  "noExistingClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のクライアントはいません"])},
  "noOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプションなし"])},
  "noPermissionsForRouteAccessError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["アクセスが拒否されました！\nページ「", _interpolate(_named("route")), "」を表示する権限がありません。"])},
  "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示する結果がありません"])},
  "noRightsErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクセスが拒否されました。\nこのアクションを実行する権限がありません"])},
  "noSearchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["そのクエリでは結果がありません"])},
  "noTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクがありません"])},
  "notAccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け入れられません"])},
  "notDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ完成してない"])},
  "notReadyYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このセクションは工事中です"])},
  "numberOfRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["室数"])},
  "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開ける"])},
  "openDetailView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細ビューを開く"])},
  "openPartnershipDetailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナービューを開く"])},
  "openTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オープンタイムスロット"])},
  "optionSelectedLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("length")), " オプションが選択されました"])},
  "order": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文"])}
  },
  "orderClientPage": {
    "addressDiscaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求書が必要な場合は、住所を入力する必要があります"])},
    "agreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約を読み、同意します"])},
    "confirmClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客情報確認時のエラー"])},
    "confirmClientSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント情報が正常に確認されました"])},
    "contactInfoDiscaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当社は必要な場合にお客様に連絡するためにのみお客様のデータを使用します"])},
    "tourTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般用語"])},
    "updateClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文クライアントの更新中にエラーが発生しました"])},
    "updateClientSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文クライアントが正常に更新されました"])}
  },
  "orderConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文確認"])},
  "orderSummary": {
    "cancellationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文のキャンセル"])},
    "refundableLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金可能"])},
    "refundedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金されました"])}
  },
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["起源"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])},
  "overviewCalendar": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダー"])},
    "calendarSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダー選択"])},
    "calendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダー"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("eventsNr")), " もっと"])},
    "multidayEventsInTimeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイムライン内で複数日にわたるイベントを表示"])},
    "noCalendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダーなし"])},
    "noOrdersInPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この期間に注文はありません"])},
    "orderCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文の概要"])},
    "orderNo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["注文番号 #", _interpolate(_named("referenceNumber"))])},
    "orderType": {
      "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
      "CONFIRMED_OFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文"])},
      "CONFIRMED_ORDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認済み注文"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
      "LOCKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロック済み"])},
      "OFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オファー"])}
    },
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文"])},
    "otherDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他の日"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リセット"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて選択"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週"])}
  },
  "overviewTabs": {
    "bookingCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダー"])},
    "bookingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])}
  },
  "participants": {
    "maxGroupSizeText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("groupSize")), "/", _interpolate(_named("maxGroupSize")), " が選択されました"])},
    "participantsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加者"])}
  },
  "partnership": {
    "acceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップを受け入れる"])},
    "acceptPartnershipInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["双方がパートナーシップを承諾した場合、あなたの販売記事がお互いのページに表示されます。\n収益分配機能も再び有効になります。"])},
    "backToPartnersList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのパートナーシップを見る"])},
    "companyHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初のパートナー"])},
    "confirmAcceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承諾の確認"])},
    "confirmUnacceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非アクティブ化の確認"])},
    "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップの詳細"])},
    "partnerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セカンドパートナー"])},
    "payoutPercentageExampleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["たとえば、アクティブなパートナーシップ中、あなたの販売記事は、パートナーとその他のパートナーに加えて、パートナーの販売記事ページの下部に表示されます。\nパートナーは、顧客が自分のページで販売記事をクリックし、注文と支払いを続行すると、シェアを受け取ります。"])},
    "payoutPercentageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企業がパートナーと共有する意思のある支払いの割合。\n共有は、パートナーの販売記事を通じて直接販売に至った場合にのみ発生します。"])},
    "payoutPercentageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーの支払いシェア"])},
    "payoutPercentageModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いシェアが変更されるとパートナーに通知されます。"])},
    "statusLabel": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活性"])}
    },
    "tabs": {
      "inviteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待します"])},
      "partnerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナー"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナー"])},
    "unacceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップを無効にする"])},
    "unacceptPartnershipInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップを無効にすると、販売記事がお互いのページに表示されなくなります。\n収益分配機能も双方で無効になります。\n以前の収益分配による将来の支払いは影響を受けません。"])},
    "updatePartnershipAcceptanceErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップを更新できませんでした。"])},
    "updatePartnershipAcceptanceSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップが正常に更新されました。"])},
    "updatePartnershipPayoutPercentageErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーの支払いシェアを更新できませんでした。"])},
    "updatePartnershipPayoutPercentageSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーの支払いシェアが正常に更新されました。"])},
    "updatePayoutPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配当シェアを変更する"])}
  },
  "partnershipInvite": {
    "acceptInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待を受け入れる"])},
    "acceptInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーの招待を受け入れることができませんでした。"])},
    "acceptInviteRegisterFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touringery にサービス プロバイダー アカウントをまだお持ちでない場合は、招待を再試行する前に登録プロセスを実行する必要があります。"])},
    "acceptInviteRequiresLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待を受け入れるには、まずサービス プロバイダーのアカウントにログインする必要があります。"])},
    "acceptInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーの招待が正常に受け入れられました。\nパートナーの詳細ビューにリダイレクトされます。"])},
    "acceptInviteVisitorModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインが必要です"])},
    "companyWantsYouToBecomeTheirPartner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("company")), " はあなたを Touringery のパートナーとして招待しています。"])},
    "createInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーへの招待を送信できませんでした。"])},
    "createInviteModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパートナー"])},
    "createInviteSubmitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待を送る"])},
    "createInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーの招待が正常に送信されました。"])},
    "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップへの招待"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待する"])},
    "inviteFormInfoHelperFirstParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーの招待は、指定された電子メールに送信されます。\n電子メールにはリンクが含まれており、受信者はパートナーの招待詳細ビューに移動します。\nそこで、彼らは招待を受け入れるか拒否するかを選択できます。"])},
    "inviteFormInfoHelperSecondParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待を受け入れるには、訪問者は別のサービスプロバイダーのユーザーとしてログインする必要があります。\n拒否は、リンクにアクセスできる訪問者であれば誰でも行うことができます。"])},
    "inviteInFinalStatusText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待はすでに応答を受け取りました。"])},
    "reinviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再招待の送信に失敗しました。"])},
    "reinviteLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待状をもう一度送信する"])},
    "reinviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待が再度送信されました。"])},
    "rejectInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待を拒否する"])},
    "rejectInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待を拒否できませんでした。"])},
    "rejectInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待は拒否されました。"])},
    "statusLabel": {
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認されました"])},
      "DECLINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否されました"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期限切れ"])},
      "OPENED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開いた"])},
      "SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信済み"])}
    },
    "whatHappensWhenYouAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーシップにより、周囲に他の製品を表示できるようになります。\nまた、リンクを通じて直接売上に到達した場合、あなたとあなたのパートナーは売上のわずかな割合を請求することになります。"])}
  },
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
  "passwordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを認証する"])},
  "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード変更済み！"])},
  "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い"])},
  "paymentBeneficiaryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受取人名"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払詳細"])},
  "paymentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い情報"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払方法"])},
  "paymentOption": {
    "bankLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行支払い"])},
    "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカード"])}
  },
  "payoutHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い"])},
  "payoutInfo": {
    "completedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いが完了しました。"])},
    "initiatedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いプロセスが開始されました。"])},
    "noPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いは行われません。\nこれは、全額返金でキャンセルが行われた場合に発生します。"])},
    "notReadyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デフォルトの支払いステータス。\n予約完了またはキャンセル後に変更されます。"])}
  },
  "payoutStatus": {
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い済み"])},
    "initiated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始済み"])},
    "noPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いなし"])},
    "notReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中"])}
  },
  "payoutStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いステータス"])},
  "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人々"])},
  "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パーセンテージ"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間"])},
  "periodAvailability": {
    "availabilityHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用性"])},
    "availabilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースが利用可能な日時を選択してください"])}
  },
  "periodUnit": {
    "monthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "selectMonthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月を選択してください"])},
    "selectPeriodLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間を選択してください"])},
    "selectUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間"])},
    "weekLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週"])}
  },
  "permissions": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダー"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアント"])},
    "helper": {
      "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい会社を追加できます"])},
      "readCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダー情報が確認できる"])},
      "readClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客情報が見られる"])},
      "readCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルマネージャーと設定内容を表示できます"])},
      "readReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["簿記やレポートの内容を閲覧できる"])},
      "readResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースを参照できる"])},
      "readServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事や付加サービス情報がご覧いただけます"])},
      "readTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク情報を確認できる"])},
      "readTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット情報が見られる"])},
      "updateClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客情報を編集できる"])},
      "updateCompanyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社契約、支払いデータ、チャネルマネージャー、パートナーシップ設定を編集できます"])},
      "updateCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の一般情報と会社の所在地情報を編集できます"])},
      "updateCompanyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社のユーザーの役割を編集し、権限を設定できます"])},
      "updateCompanyWorkflowHelpers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社のメールテンプレートとチェックインフォームの質問を編集できます"])},
      "updateReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "updateResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースを編集できる"])},
      "updateServiceContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい販売商品や新しい付加サービスを追加することができます。\n販売記事および追加サービスの一般的なコンテンツとマルチメディアを編集できます"])},
      "updateServiceIsPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事や追加サービスの公開とアーカイブが可能"])},
      "updateServicePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事のメニュー項目やカレンダーから、販売記事のリソース、営業時間、価格、住宅固有の情報を編集できます"])},
      "updateTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクを編集できる"])},
      "updateTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット認証の権利õ"])}
    },
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポート"])},
    "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソース"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット"])},
    "updateCompanyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の支払い、契約"])},
    "updateCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の設定"])},
    "updateCompanyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企業ユーザー"])},
    "updateCompanyWorkflowHelpers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社のワークフローヘルパー"])},
    "updateServiceContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事の内容"])},
    "updateServiceIsPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事の発行"])},
    "updateServicePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事ポリシー"])}
  },
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
  "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真"])},
  "pleaseAgreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続行するには条件に同意したことをマークしてください"])},
  "pleaseSelectBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済プロバイダーを選択してください"])},
  "pleaseSelectPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い方法を選択してください"])},
  "plurals": {
    "adult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["大人 ", _interpolate(_named("count")), " 人"]), _normalize(["大人 ", _interpolate(_named("count")), " 人"]), _normalize(["大人 ", _interpolate(_named("count")), " 人"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["子供 ", _interpolate(_named("count")), " 人"]), _normalize(["子供 ", _interpolate(_named("count")), " 人"]), _normalize(["子供 ", _interpolate(_named("count")), " 人"])])},
    "elderly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["高齢者 ", _interpolate(_named("count")), " 人"]), _normalize(["高齢者 ", _interpolate(_named("count")), " 人"]), _normalize(["高齢者 ", _interpolate(_named("count")), " 人"])])},
    "numberOfAdditionalServices": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 追加サービス"]), _normalize(["追加サービス ", _interpolate(_named("count")), " 件"]), _normalize([_interpolate(_named("count")), " の追加サービス"])])},
    "numberOfGuests": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["ゲスト ", _interpolate(_named("count")), " 名"]), _normalize(["ゲスト ", _interpolate(_named("count")), " 名"]), _normalize([_interpolate(_named("count")), " 人のゲスト"])])},
    "numberOfNights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 泊"]), _normalize([_interpolate(_named("count")), " 泊"]), _normalize([_interpolate(_named("count")), " 泊"])])},
    "numberOfParticipants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["参加者 ", _interpolate(_named("count")), " 人"]), _normalize(["参加者 ", _interpolate(_named("count")), " 名"]), _normalize(["参加者 ", _interpolate(_named("count")), " 名"])])},
    "numberOfRooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " 室"]), _normalize([_interpolate(_named("count")), " 部屋"]), _normalize([_interpolate(_named("count")), " 部屋"])])}
  },
  "politicallyExposed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["政治的に露出した人物"])},
  "preferences": {
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日々"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["距離(km)"])},
    "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲスト"])},
    "includedInPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金に含まれるもの"])},
    "labels": {
      "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクセシビリティ"])},
      "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アメニティ"])},
      "checkInHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン時間"])},
      "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約"])},
      "difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["困難"])},
      "furtherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["さらに詳しい情報"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
      "numOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客数"])},
      "stayDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滞在期間"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])}
    },
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小"])},
    "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["環境設定"])}
  },
  "preferredLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優先言語"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビュー"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前の"])},
  "previousMonthResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前月の結果"])},
  "price": {
    "additionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加サービス"])},
    "additionalServicesVAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加サービス VAT (20%)"])},
    "createTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットの作成"])},
    "descriptionPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格の説明 (オプション)"])},
    "fullPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正規価格"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格名"])},
    "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
    "servicePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品価格"])},
    "serviceVAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品物付加価値税 (20%)"])}
  },
  "priceDynamicRows": {
    "addRowLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格変更を追加"])},
    "durationFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間は価格に影響しますか?"])},
    "durationInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間が価格に影響することを指定しました。\nこれは、最終価格を変更する各期間時間単位の価格変更を指定できることを意味します。"])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間価格の変更は次のフィールドで構成されます"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間価格変更の説明"])},
      "isImportantHourlyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間単位の価格設定タイプでは、期間は常に重要であり、無効にすることはできません。"])},
      "isImportantNightlyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["夜間料金タイプの場合、継続時間は常に重要であり、無効にすることはできません。"])},
      "isImportantPackagedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パッケージ化された価格設定タイプでは期間は重要ではないため、有効にすることはできません。"])},
      "isPriorityHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックすると、この価格変更はそれ以前のすべての時間帯にも適用され、適用されている可能性のある他の価格変更が上書きされます。"])},
      "isPriorityNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックすると、この料金変更は、適用されている可能性のある他の料金変更を上書きして、以前のすべての宿泊にも適用されます。"])},
      "lowerBoundHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定された価格変更限界の時間の下限。\n下限は含まれており、価格変更が適用されます。"])},
      "lowerBoundNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定された価格変更範囲における宿泊数の下限。\n下限は含まれており、価格変更が適用されます。"])},
      "rowsHourlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デフォルトでは、予約の各時間の価格は、以前に指定した基本価格に設定されます。\nここで、価格変更ルールを追加することで、時間ごとの価格を変更できます。\n詳細については、見出しの横にある情報アイコンをご覧ください。"])},
      "rowsNightlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デフォルトでは、予約の各泊の料金は、事前に指定された基本料金に設定されます。\nここで、価格変更ルールを追加することで、毎泊の価格を変更できます。\n詳細については、見出しの横にある情報アイコンをご覧ください。"])},
      "rowsPackagedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間はパッケージタイプの価格には影響しません。\n料金は時間/泊ごとではなく、予約全体の料金となります。"])},
      "upperBoundHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定された価格変更限界の時間の上限。\n上限は排他的であり、価格変更は適用されません。\n\n上限は必須ではありませんが、無制限にできる期間ルールは 1 つだけです。"])},
      "upperBoundNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定された価格変更限度における宿泊数の上限。\n上限は排他的であり、価格変更は適用されません。\n\n上限は必須ではありませんが、無制限にできる期間ルールは 1 つだけです。"])}
    },
    "durationRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間に基づく価格変更"])},
    "groupSizeFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループの人数は料金に影響しますか?"])},
    "groupSizeInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ サイズが価格設定に影響することを指定しました。\nこれは、少なくとも 1 つの価格変更を指定する必要があることを意味します。"])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループサイズの料金変更は次のフィールドで構成されます"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループサイズの料金変更の説明"])},
      "isPriorityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックすると、この価格変更は以前のグループ メンバーすべてにも適用され、それらのメンバーに適用された可能性のある他の価格変更は上書きされます。"])},
      "lowerBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定された価格変更限界に対する人数の下限。\n下限は含まれており、価格変更が適用されます。"])},
      "rowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デフォルトでは、グループ サイズが重要な場合、最終価格を取得するための乗数としてグループ サイズが使用されます。\nここでは、価格の変更を定義することで、各追加人が価格にどのように影響するかを変更できます。\n詳細については、見出しの横にある情報アイコンをご覧ください。"])},
      "upperBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定された価格変更限界に対する人数の上限。\n上限は排他的であり、価格変更は適用されません。\n\n上限は必須ではありませんが、無制限にできる期間ルールは 1 つだけです。"])}
    },
    "groupSizeRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループサイズに応じた料金変更"])},
    "hourUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1時間当たり"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])}
    },
    "lowerBoundLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から"])},
    "nightUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["夜"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一泊"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["夜"])}
    },
    "noOverlapRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格変更条件は重複できません"])},
    "onlyOneLimitlessRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 つの価格変更条件のみが、不特定 (無制限) の上限を持つことができます。"])},
    "personUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一人当たり"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人"])}
    },
    "priceChangeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約がルールの条件に一致する場合に、最終価格に追加される値。"])},
    "priceChangeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格変更"])},
    "priorityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前に適用"])},
    "quantityUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量ごと"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量"])}
    },
    "relatedResourceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どの関連リソースの必要数量に基づいて価格が変更されます。"])},
    "relatedResourceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連リソース"])},
    "relatedResourcePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択する"])},
    "resourceConditionsPerRelatedResourceRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連リソースの価格変更の場合、重複制限は、同じ関連リソースに接続されている価格変更間でのみ発生します。"])},
    "resourcesFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連リソースは価格に影響しますか?"])},
    "resourcesInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連リソースが価格に影響を与えることを指定しました。\nこれは、少なくとも 1 つの価格変更を指定する必要があることを意味します。"])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソース価格の変更は次のフィールドで構成されます"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連リソースの価格変更の説明"])},
      "isPriorityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックすると、この価格変更は以前のすべての関連リソース数量/時間にも適用され、それらに適用された可能性のある他の価格変更は上書きされます。"])},
      "lowerBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定された価格変更限界に対するリソース量/時間の下限。\n下限は含まれており、価格変更が適用されます。"])},
      "rowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースが重要な場合は、追加された各リソースが最終価格にどのように影響するかを指定できます。\n\n人を表すリソースの場合、料金は時間数に基づいて変わります。\n\nオブジェクトを表すリソースの場合、予約に必要なリソースの量に基づいて価格が変わります。\n詳細については、見出しの横にある情報アイコンをご覧ください。"])},
      "upperBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定された価格変更限界におけるリソース量/時間の上限。\n上限は排他的であり、価格変更は適用されません。\n\n上限は必須ではありませんが、無制限にできる期間ルールは 1 つだけです。"])}
    },
    "resourcesRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースの基本価格の変更"])},
    "restrictionsSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制限"])},
    "upperBoundLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["それまで"])}
  },
  "pricingProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格設定のプロパティ"])},
  "pricingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格タイプ"])},
  "pricingTypes": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎時"])},
    "moneyJar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お金の壺"])},
    "nightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎晩"])},
    "packaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パッケージ化された"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])}
  },
  "pricingTypesInfo": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット料金（大人、高齢者、子供）はイベント全体の料金です。"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力した料金は 1 時間あたりの料金で、予約期間が乗算されます。\n\n現在、最小期間と最大期間を指定できます。\n\n重要：\n\n現在、開始時間を制限することはできません。クライアントは営業時間に合わせて予約なしで選択できます。\n\n料金はグループの人数には影響されません。 \n2時間予約の場合、1名様でも10名様でも同料金となります。\n\n現在、同時予約は 1 件のみサポートされています。"])},
    "moneyJar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客の最終価格。\nサービスプロバイダーが販売商品を定義する"])},
    "nightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金は予約した宿泊日数に基づいて計算されます。\n\n重要：\n\n現在、予約全体の料金は 1 泊目の料金に基づいています。"])},
    "packaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力した価格は予約期間全体に対するもので、この価格タイプを選択するときに指定できます。\n\n重要：\n\n現在、開始時間を制限することはできません。それはクライアント次第です。\n\n料金はグループの人数には影響されません。 \n2時間予約の場合、1名様でも10名様でも同料金となります。\n\n現在、同時予約は 1 件のみサポートされています。"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格は数量に基づいて計算されます"])}
  },
  "priority": {
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["致命的"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高い"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低い"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中くらい"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緊急"])}
  },
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシー"])},
  "productCalendarDetails": {
    "noSalesForDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この日付に購入された製品はありません"])}
  },
  "profileSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロファイル設定"])},
  "publicNoServiceBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間違った目的地に向かっているようです。"])},
  "publicNoServiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["なんてこった！"])},
  "publicService": {
    "archivedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品はありません"])}
  },
  "publicTransport": {
    "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空港"])},
    "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バス"])},
    "harbor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["港"])},
    "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電車"])},
    "tram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トラム"])},
    "trolley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トロリーバス"])}
  },
  "purchasedAtDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入日"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量"])},
  "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レート"])},
  "readLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読む量を減らす"])},
  "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続きを読む"])},
  "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理由"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信者"])},
  "recipientWithPlural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信者/受信者"])},
  "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信者"])},
  "recommendedCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックインは次のとおりです"])},
  "recommendedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["でチェックアウトしてください"])},
  "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否する"])},
  "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否した"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録する"])},
  "registration": {
    "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社を探す"])}
  },
  "registrationCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録国"])},
  "registrationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの作成に失敗しました"])},
  "registrationSuccessEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録が成功しました！\nアクティベーションメールがメールボックスに送信されました"])},
  "registryNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レジスタコード"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否されました"])},
  "relate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関係する"])},
  "relatedAvailableServices": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した日付で利用可能"])}
  },
  "relatedService": {
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続きを見る"])}
  },
  "relatedServices": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加で入手"])},
    "partnersHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーから追加で入手する"])}
  },
  "relatedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連ユーザー"])},
  "related_check_in_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックインに関連する質問"])},
  "related_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連リソース"])},
  "related_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連販売記事"])},
  "related_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連するメールテンプレート"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取り除く"])},
  "report": {
    "deposit": {
      "header": {
        "commissionsDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料"])},
        "depositsBeforePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期前残高"])},
        "depositsDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受領した預金"])},
        "depositsEndOfPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間後の残高"])},
        "paidAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金日"])},
        "partnersPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーへの支払い"])},
        "payoutAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い日"])},
        "refundedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金日"])},
        "refundedDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R払い戻し"])},
        "serviceProviderPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスプロバイダーの支払い"])}
      }
    },
    "errorFetchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポートの生成に失敗しました"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成する"])},
    "generateCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSVを生成する"])},
    "generatePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDFを生成する"])},
    "partners": {
      "header": {
        "payoutAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い"])},
        "payoutTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペイアウトレシーバー"])}
      }
    },
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポート"])},
    "sales": {
      "header": {
        "clientsCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クライアントの国"])},
        "clientsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客名"])},
        "commissionFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミッションフェ"])},
        "net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネット"])},
        "partnerPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーの取り分"])},
        "payoutDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い日"])},
        "priceLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格ライン"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量"])},
        "refundFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金手数料"])},
        "refundedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金日"])},
        "salesArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品"])},
        "salesReportGenerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売レポートが生成されました"])},
        "sumWithVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VATを含む合計額"])},
        "sumWithoutVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT抜きの合計"])},
        "totalVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計バット"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バット（％)"])}
      }
    },
    "table": {
      "additionalService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加サービス"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["経験"])},
      "housing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジング"])},
      "moneyjar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マネージャー"])}
    },
    "tabs": {
      "bookKeeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["簿記"])},
      "depositReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金報告書"])},
      "partnersReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナーレポート"])},
      "salesReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売レポート"])},
      "statisticsReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統計レポート"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "touringeryCommission": {
      "header": {
        "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネット"])},
        "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総売上高"])}
      }
    }
  },
  "reports": {
    "statistics": {
      "accomodatedEstonianNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エストニア在住者 - 宿泊日数"])},
      "accomodatedEstonianPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エストニア居住者 - 収容観光客数"])},
      "accomodatedForeignNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外国人観光客 - 宿泊日数"])},
      "accomodatedForeignPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外国人観光客の受け入れ者数"])},
      "accomodatedNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊日数"])},
      "accomodatedPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["観光客収容者数"])},
      "bedPlacesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッドの場所"])},
      "campingPlacesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンプ場とトレーラーパークの場所の数"])},
      "countryCodeHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国コード"])},
      "openDaysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開設日数"])},
      "roomNightsSoldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宅の利用状況（販売部屋泊数）"])},
      "roomsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宅戸数"])},
      "tripPurpose": {
        "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出張"])},
        "conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..カンファレンスへの参加"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他（トランジットツーリズム、医療ツーリズムなどでの宿泊）"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊客数と宿泊日数の合計"])},
        "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休日の旅行"])}
      }
    }
  },
  "requestLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクのリクエスト"])},
  "requestNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを要求する"])},
  "requestSentLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエストを送信しました！\nパスワードリセットリンクを送信しました。"])},
  "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエスト"])},
  "requiredSettingsAlert": {
    "beneficialOwnersNotFilledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受益者/所有者"])},
    "companyContractNotAgreedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約条件に同意する"])},
    "companyNoServiceContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス提供者と顧客との間の売買契約"])},
    "dynamicShowAllLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["もっと見る (", _interpolate(_named("count")), ")"])},
    "hideMoreSettingsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと隠す"])},
    "paymentInfoNotFilledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いの支払い詳細"])}
  },
  "requirementsFilledError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーが発生しました。\nすべてが正しく入力されているかどうかを確認してください"])},
  "resendConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文確認の送信"])},
  "resendConfirmationEmailAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここで注文確認を顧客に再送信できます。"])},
  "resendConfirmationEmailAlertWithLastDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ここで注文確認を顧客に再送信できます。\n前のメールが送信されました: ", _interpolate(_named("emailconfirmationAt")), "。"])},
  "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約"])},
  "reservationData": {
    "cannotChangePeriodForExternalSourcesAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部からの予約のため期間を変更できません。"])},
    "originalGuestsAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ゲストの数は変更されました。当初は ", _interpolate(_named("guests")), " でした。"])}
  },
  "reservationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約日"])},
  "reservationEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約終了"])},
  "reservationHousingUnit": {
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジングユニットの保存中にエラーが発生しました"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジングユニットは正常に保存されました"])}
  },
  "reservationLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約の長さ"])},
  "reservationSalesEndTimeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネット販売終了日"])},
  "reservationStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約開始"])},
  "reservationStatus": {
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了しました"])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期限切れ"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有料"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中"])}
  },
  "reservationStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約状況"])},
  "reservationType": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関係タイプ"])},
    "oneCapacityPerPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定員はお一人様1つまで"])},
    "oneQuantityPerEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントごとに 1 つの数量"])},
    "oneQuantityPerPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量はお一人様1個まで"])},
    "oneQuantityPerReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約ごとに 1 数量/時間"])}
  },
  "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソース"])},
  "resourceCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースが正常に作成されました"])},
  "resourceCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースの作成に失敗しました"])},
  "resourceDefaultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割・役職"])},
  "resourceDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースが正常に削除されました"])},
  "resourceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])},
  "resourceGroupCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループの作成"])},
  "resourceGroupCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループが正常に作成されました"])},
  "resourceGroupCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループの作成に失敗しました"])},
  "resourceGroupUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループの更新"])},
  "resourceGroupUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループの更新に失敗しました"])},
  "resourceGroupUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループが正常に更新されました"])},
  "resourceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])},
  "resourceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソース名"])},
  "resourceObjectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル"])},
  "resourcePeriodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースが利用可能な日付を入力してください"])},
  "resourceSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースが正常に保存されました"])},
  "resourceSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソースの保存に失敗しました"])},
  "resourceTabs": {
    "resourceCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダー"])},
    "resourceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グループ"])},
    "resourceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソース"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク"])}
  },
  "resourceUserTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割"])},
  "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソース"])},
  "respondedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答者"])},
  "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応答"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割"])},
  "room": {
    "alertinfotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジングユニットは、ゲストまたはグループのゲストのための宿泊施設です。\n異なるハウジングユニットを個別に追加してください。\nまたは、（同様の）住宅ユニットの数を追加します"])},
    "maxGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大宿泊人数"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋"])},
    "roomInfoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この住宅ユニットの部屋の数を入力して、各部屋の情報を指定します"])},
    "roomType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋タイプ"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋"])},
    "unitCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリー"])}
  },
  "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部屋のアメニティ"])},
  "roomLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般的な情報"])},
  "roomsSoldEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エストニアからの旅行者"])},
  "roomsSoldNonEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他国からの旅行者"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページあたりの行数"])},
  "rrule": {
    "end:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終わり："])},
    "endDate:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日："])},
    "eventInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント情報"])},
    "every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎"])},
    "everyDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。\n日付ごと"])},
    "everyDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日"])},
    "everyMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月"])},
    "everyOtherDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
    "everyOtherMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "everyOtherWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週"])},
    "everyTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回"])},
    "everyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎週"])},
    "everyWorkDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎営業日"])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初め"])},
    "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第4"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金曜日"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月曜日"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "recurresEvery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ごとに再発します"])},
    "repetitionFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繰り返し周波数"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土曜日"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2番"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日曜日"])},
    "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三番目"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木曜日"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["火曜日"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水曜日"])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
  "saveAndContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存して続行"])},
  "saveNewCoordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地図座標を取得する"])},
  "scanner": {
    "anonymousCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匿名カメラ"])},
    "cameraAccessSecureContextOnlyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラ アクセスは、https や localhost などの安全なコンテキストでのみサポートされます。"])},
    "cameraBasedScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラベースのスキャン"])},
    "cameraStreamingNotSupportedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラのストリーミングはブラウザでサポートされていません。"])},
    "chooseAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別のものを選択してください"])},
    "chooseImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像の選択"])},
    "chooseImageNoImageChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像を選択 - 画像が選択されていません"])},
    "codeScanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コードスキャナー"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])},
    "fileBasedScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルベースのスキャン"])},
    "getUserMediaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userMedia 取得エラー、エラー ="])},
    "idle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイドル状態"])},
    "launchingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラを起動中..."])},
    "loadingImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像を読み込んでいます..."])},
    "navigatorMediaDevicesNotSupportedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デバイスは navigator.mediaDevices をサポートしていません。この場合サポートされているのは deviceId パラメーター (文字列) である CameraIdOrConfig のみです。"])},
    "noCameraFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラが見つかりません"])},
    "noCameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラなし"])},
    "noImageChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像が選択されていません"])},
    "notAllowedPermissionDeniedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NotAllowedError: アクセス許可が拒否されました"])},
    "orDropImageToScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["または画像をドロップしてスキャンします"])},
    "orDropImageToScanOtherFilesNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["または、スキャンする画像をドロップします (他のファイルはサポートされていません)"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["許可"])},
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搭載"])},
    "qrCodeParseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR コード解析エラー、エラー ="])},
    "querySupportedDevicesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サポートされているデバイスをクエリできません。不明なエラーです。"])},
    "reportIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題を報告する"])},
    "requestCameraPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラの許可をリクエストする"])},
    "requestingCameraPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラの許可を要求します..."])},
    "scanAnImageFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像ファイルをスキャンする"])},
    "scanUsingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラを使用して直接スキャンする"])},
    "scannerPaused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキャナーが一時停止しました"])},
    "scanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["走査"])},
    "selectCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カメラの選択"])},
    "startScanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキャンを開始する"])},
    "stopScanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキャンを停止する"])},
    "switchOffTorch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トーチをオフにする"])},
    "switchOffTorchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トーチをオフにできませんでした"])},
    "switchOnTorch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トーチのスイッチを入れる"])},
    "switchOnTorchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トーチをオンにできませんでした"])},
    "zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ズーム"])}
  },
  "scheduledEmails": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予約メール"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールはありません"])},
    "scheduledAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["に予定されています"])},
    "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールを送る"])},
    "sendEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メールの送信に失敗しました"])},
    "sendEmailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールが正常に送信されました"])},
    "sentAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後に送信されたのは"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])}
  },
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
  "searchExistingClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存顧客から探す"])},
  "searchForAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所を検索する"])},
  "searchToSeeResultsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付と訪問者情報を入力して空き状況を確認してください"])},
  "seeAllClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのクライアントを表示"])},
  "seeAllResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのリソースを表示"])},
  "seeAllServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのセール記事を見る"])},
  "seeAllTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのタスクを表示"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続きを見る"])},
  "selectAdditionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加サービスを選択する"])},
  "selectAdditionalServicesToRelate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連する追加サービスを以下から選択します"])},
  "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社を選択してください"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国を選択"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付を選択してください"])},
  "selectRelatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連リソースを選択してください"])},
  "selectResourcesToRelate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連する以下のリソースを選択してください"])},
  "sendInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待状を送信する"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージを送る"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品"])},
  "serviceAdditionalService": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここでは、販売記事メニューから以前に入力した追加の製品をリンクできます。\n選択した追加製品は、支払い前にショッピング カートに表示されます。"])}
  },
  "serviceAdvancedTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高度な"])},
  "serviceArchiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事のアーカイブに失敗しました"])},
  "serviceArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事が正常にアーカイブされました"])},
  "serviceCheckInQuestions": {
    "addQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問を追加"])},
    "addRelationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事のチェックイン フォームに追加または削除する質問を選択してください"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更は関連するすべての販売記事に適用されます"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックインに関連する質問"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての質問については、「設定 - チェックインの質問」を参照してください。\nここでは、現在のサービス項目に質問を追加できます"])},
    "saveRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リレーションの保存"])},
    "selectQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連する質問を編集する"])},
    "serviceCreateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての販売記事のチェックイン フォームの追加質問は、[設定] -> [チェックイン フォーム] にあります。"])}
  },
  "serviceContact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先"])},
    "headerDefaultsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["値のないフィールドは、プレースホルダーに示されているように、デフォルトで会社プロファイル設定になります。"])},
    "headerGeneralInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客向けに販売商品固有の連絡先情報を指定します。"])},
    "nameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先担当者の名前。\nフィールドを空のままにすると、クライアントから非表示になります。"])},
    "overrideNotificationsGeneralInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックを入れると、本サービスに関するメール通知の送信に会社メールの代わりに指定のメールが使用されます"])},
    "overrideNotificationsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メール通知に使用します"])},
    "overrideNotificationsSettingsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メール通知の送信設定は引き続き適用されます。"])}
  },
  "serviceDuplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事が正常に複製されました"])},
  "serviceDuplicationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事の複製に失敗しました"])},
  "serviceEvent": {
    "availableCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能な容量"])},
    "cancelAtHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル時間"])},
    "cancellationTimeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここでは、最低参加者数に達する必要がある日付と時刻/販売されるチケットの最低枚数を設定できます。\n最少催行人数に満たない場合、イベントは自動的に中止となります。\nこれまでに購入したチケットに対して受け取った金額は返金され、参加者には自動的に通知されます。\nイベント中止に伴う移動費用は主催者が負担するものとします。"])},
    "capacityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントに最小参加者数が必要な場合は、ここに最小参加者数を入力します。\nいずれにせよイベントが開催される場合は、最小参加者数を指定する必要はなく、最大参加者数を入力するだけです。"])},
    "eventCapacityHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["容量"])},
    "eventPeriodHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント時間"])},
    "maxCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大"])},
    "minCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態"])},
    "timeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダーからイベントの開始日を選択し、時計アイコンをクリックして開始時間を選択します。"])},
    "updateEventTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップデートイベント"])}
  },
  "serviceEventCalendar": {
    "onlyEventsFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントありのみ表示"])}
  },
  "serviceEventsArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントは正常にアーカイブされました"])},
  "serviceEventsArchivedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントのアーカイブに失敗しました"])},
  "serviceFilter": {
    "privateState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可視性"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["州"])}
  },
  "serviceGeneral": {
    "categoryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["できるだけ近いものを選択してください。"])},
    "descriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必要に応じて、ウィンドウを右下隅からより大きなウィンドウに引き伸ばします。"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらはサービスプロバイダーの内部使用向けであり、さまざまな販売アイテムをニーズに応じてグループ化し、それに基づいてリストビューでフィルタリングすることを可能にします。"])},
    "typeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリ指定。"])}
  },
  "serviceGroup": {
    "groupItemIsUnavailableForSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択内容に基づいて販売記事が利用できません"])}
  },
  "serviceLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスラベル"])},
  "serviceLocation": {
    "accessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機能するすべてのオプションにマークを付けます。"])},
    "addressInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所の検索には Google 検索を使用します。Google で検索できる住所を入力します。"])},
    "howToGetThereInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どの名前で、地図アプリケーションでどのように見つけられるか、最寄りの観光スポットからのルートを説明してください。"])},
    "locationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地図上の目的の場所にマウスを移動してクリックすると、ピンが表示されます。必要に応じてピンを正確な場所に移動します。"])},
    "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["場所を選択してください"])},
    "transportInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最寄りの停留所と目的地からの距離をマークします。"])}
  },
  "serviceLocationInfo": {
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「住所の検索」フィールドのオプションをクリックすると、座標が自動的に入力されます。\n手動で入力するか、地図上のピンを移動して「地図座標を取得」ボタンを押してください。"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定の場所を識別するために場所リストに表示されます"])}
  },
  "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品名"])},
  "serviceNotSavedWarning": {
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更を破棄"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存せずに続行しようとしています。"])}
  },
  "servicePeriod": {
    "activeHoursInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事の週別および日別の在庫状況。"])},
    "arrivalLengthOfStayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到着ベースの滞在期間"])},
    "lengthOfStayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滞在日数"])},
    "periodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売商品を提供する期間を指定します。\n販売商品が一年中入手可能な場合は、暦年以上の期間にわたります。\n期間を期限切れにしたくない場合は、期間の終わりを空白のままにしておきます。"])},
    "restriction": {
      "info": {
        "arrivalLengthOfStayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これは、ゲストが特定の日に到着する際に予約できる最小および最大宿泊数です。\nこれらの制限は、予約期間の到着日に基づいて通過する必要があります。"])},
        "bookingArrivalLengthOfStay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらの制限は、Booking.com の到着制限からの最小および最大滞在日数と同等です。"])},
        "bookingLengthOfStay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これらの制限は、Booking.com の最小および最大滞在日数の制限と同等です。"])},
        "lengthOfStayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これはゲストが予約できる最小および最大宿泊数です。\nこれらの制限は、予約期間内のすべての日付で合格する必要があります。"])},
        "restrictionSectionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定された期間内のすべての日付に指定された制限が適用されます。"])},
        "specifiedMaxRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定する場合、滞在期間と到着ベースの滞在期間の両方の最小値以上である必要があります。"])},
        "syncedToBookingGeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統合がアクティブで、住宅が Booking.com の部屋に接続されている場合、この制限は部屋の料金にも適用されます。"])},
        "unspecifiedOptionBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプションが指定されていない場合、制限は Booking.com に同期されません。\n\nこれは、以前に設定された制限が有効なままになることを意味します。"])},
        "unspecifiedOptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このオプションを選択すると、制限は適用されません。"])},
        "unspecifiedOptionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未指定のオプション"])}
      }
    },
    "restrictionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制限"])},
    "stayOptionNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不特定"])},
    "weekslotsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動時間"])}
  },
  "servicePeriodList": {
    "addAnotherPeriodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["季節ごとに販売記事が 1 日のさまざまな時間に入手できる場合は、新しい期間を追加します。"])}
  },
  "servicePeriodPricing": {
    "descriptionHelperInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格名は、エンドユーザーに表示される価格の名前です。\n説明は、エンド ユーザーには表示されないオプションの情報フィールドです。"])},
    "discountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここでは、どのような割引を、どのような条件で、どのくらいの金額で提供するかを選択できます。\n必要に応じて、複数の種類の割引を追加できます。"])},
    "identificatorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["システムが価格を識別するために使用する一意の識別子を挿入します。\nたとえば、価格名などです。\nスペースや特殊文字は使用しないでください。"])},
    "isMinimumPriceWarningAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在の選択には最低価格が適用されています。"])},
    "minPriceFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低価格"])},
    "minPriceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定した場合、元の合計価格がそれよりも低くなる場合 (割引を含む)、指定された値が予約の最終合計価格として適用されます。\n追加サービスの価格は最低価格の影響を受けません。\nそのコストは、最低価格が適用された後に追加されます。"])},
    "priceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税込みの最終価格を入力してください"])},
    "pricingPropertiesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格設定タイプに基づいて販売商品の価格が計算される方法:"])},
    "ticketCheckboxInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これをチェックすると、購入した商品ごとに検証用の QR コードを含むチケットが生成され、クライアントに送信されます。"])}
  },
  "servicePreference": {
    "difficultyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事に困難がある場合は設定し、そうでない場合は未定義とします。"])}
  },
  "servicePreferences": {
    "accessibilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供されるものを選択してください。"])},
    "amenitiesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格に含まれる付加価値、サービス、オプションを選択してください。"])},
    "contractInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客とサービスプロバイダーの間の契約条件を定義する契約を選択します。"])},
    "contractInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「設定 - 契約」セクションで契約を構成できます。"])},
    "difficultyLabels": {
      "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["簡単"])},
      "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["難しい"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中くらい"])},
      "neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中性"])}
    },
    "languagesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客に対応する言語を選択します。"])},
    "minReservationTimeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットを購入できる最新の時間を入力してください。\n制限がない場合は空白のままにしてください。"])},
    "targetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報が重要な場合は対象グループを選択し、そうでない場合は空白のままにします。"])}
  },
  "servicePreview": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前に入力した情報に基づいて、クライアントが表示できるビュー。\n重要な情報がすべて揃っている場合は、ページの下部にある公開ボタンを押してください。\n配布用 URL/リンクが生成され、販売チャネル (FB、ホームページ、パートナーのページなど) に配置できます。\n最初の販売トランザクションまでは、すべてのデータを変更できます。\n初売り後は時間や価格の変更はできません。"])}
  },
  "servicePrivateStateFilter": {
    "allLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開ショー"])},
    "privateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "publicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可視性"])}
  },
  "serviceProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスプロバイダー"])},
  "serviceProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスプロバイダー"])},
  "servicePublicURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事公開URL："])},
  "servicePublishButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
  "serviceResource": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リソース計画を使用する場合は入力し、使用しない場合は次に進みます。"])},
    "newResourceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必要なリソースが入力されていない場合は、ここで入力できます。"])}
  },
  "serviceResources": {
    "removeResourceConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このリソースを削除してもよろしいですか?"])}
  },
  "serviceStateFilter": {
    "showActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブを表示"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて表示する"])},
    "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ済みを表示"])},
    "showUnpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未公開を表示"])}
  },
  "serviceTabs": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カレンダー"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット"])},
    "ticketsValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証する"])}
  },
  "serviceTemplates": {
    "addRelationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特定の販売記事に関連付ける電子メール テンプレートを選択してください"])},
    "addTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートの作成"])},
    "createNewLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい電子メール テンプレートを作成する"])},
    "editRelationsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート関係"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連する電子メール テンプレート"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのテンプレートについては、「設定 - メール テンプレート」を参照してください。\nここで、現在のサービス項目にテンプレートを追加できます"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リレーションの保存中にエラーが発生しました"])},
    "saveRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リレーションの保存"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関係が正常に保存されました"])},
    "selectTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート関係の編集"])}
  },
  "serviceTicket": {
    "addAnotherTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別のチケットを追加"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
    "isActiveLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効"])},
    "ticketTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット"])},
    "updateTimelineTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格を更新"])}
  },
  "serviceTimeline": {
    "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットと価格設定"])},
    "timelineHeaderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイムライン"])}
  },
  "serviceType": {
    "eventLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
    "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス"])},
    "groupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "groupLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジング"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品目の種類"])},
    "moneyJarLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お金の壺"])},
    "productDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品販売記事を作成する場合は、このオプションを選択します。製品は直接販売することも、他の販売記事を通じて販売することもできます。"])},
    "productLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品"])},
    "salesItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品目"])},
    "ticketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットを販売する場合は、このオプションを選択します。"])},
    "ticketLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット"])}
  },
  "serviceUnits": {
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
    "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KG"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])},
    "ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ML"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
    "otherUnitPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単位を指定"])},
    "pcs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCS"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人"])},
    "pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペット"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユニット"])}
  },
  "serviceView": {
    "duplicateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開された販売記事についてはコピーが可能です。\nイベント開催日（イベント開催時）を除くすべての販売記事データが複製されます。\n重複した販売記事は掲載されません。"])},
    "isArchivedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ済み"])},
    "isNotPublishedTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未発表"])},
    "isPrivateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライベートセール記事はクライアントが直接購入することはできず、関連セール記事として表示されません。"])},
    "isPrivateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非公開"])},
    "isPublicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
    "isPublishedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開された販売記事は一般公開されます。"])},
    "isPublishedTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発行済み"])},
    "publishError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事の公開に失敗しました。"])},
    "publishSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事が無事公開されました。"])}
  },
  "service_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
  "service_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
  "service_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売品"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事"])},
  "setDurationManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間を手動で設定する"])},
  "setNewPassword": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを設定する"])},
    "submitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なトークンまたはパスワード"])},
    "tokenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なトークン"])}
  },
  "setUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーデータの設定"])},
  "settings": {
    "ServicePublishWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事を公開するには、まず次の情報を入力する必要があります"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
    "tabLabel": {
      "bookingWidget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開ビュー"])},
      "channelList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャネル"])},
      "channelManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャンネルマネージャー"])},
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックインフォーム"])},
      "companyEmails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
      "compucash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash統合"])},
      "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約"])},
      "emailTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般的な"])},
      "googleCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleカレンダー"])},
      "inviteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置"])},
      "partnerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パートナー"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレート"])},
      "trigon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリゴン"])},
      "userRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの役割"])}
    }
  },
  "settingsCheckIn": {
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問を作成する"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除エラー"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常に削除されました"])},
    "displayForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックインフォームを表示する"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加の質問"])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加の質問"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問"])}
  },
  "settingsContracts": {
    "archiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ中のエラー"])},
    "archiveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常にアーカイブされました"])},
    "archivedSalesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブされた販売記事"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この契約には関連する注文項目があります。\n契約データを変更すると、元の契約がアーカイブされ、サービス関係が転送されます。"])},
    "clientConsents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連する注文商品"])},
    "confirmArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブしてもよろしいですか?"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消去してもよろしいですか？"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約書の作成"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各販売商品に契約を割り当てます。\n顧客はチェックアウトの過程で契約条件への同意を確認します。"])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約"])},
    "relatedActiveSalesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売記事を公開しました"])},
    "relatedPublishedServicesHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約に関連する販売記事を公開"])},
    "saveAsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規として保存"])},
    "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーカイブ済みを表示"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日時"])}
  },
  "settingsLocation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所在地"])},
    "titleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売商品に割り当てることができるすべてのサービス プロバイダーの場所。"])}
  },
  "settingsPayments": {
    "getFromAriregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-Business Register から取得"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行名、受取人名、IBANを必須フィールドに入力し、右下の「保存」ボタンを押してください。"])},
    "noAriregisterData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データなし"])}
  },
  "settingsProfile": {
    "agreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web プラットフォームの内容を読み、同意します。"])},
    "basicInformationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km 債権者を除くすべての必須フィールド。\n会社データを手動で入力するか、企業登録簿からデータを取得します。"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約"])},
    "contractLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスプロバイダーとTouringery間の契約条件"])},
    "descriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の説明には、提供されるすべての販売記事に当てはまる使命、スローガン、または重要なメッセージを書きます。"])},
    "locationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索ウィンドウに入力を開始します。 \nGoogleアドレス検索を利用していますので、Googleで検索されたものをそのまま入力してください。\nそれでも正確な住所が見つからない場合は、郡、市などを手動で入力できます。"])},
    "socialNetworkAccountsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のものを入力すると、販売記事ビューの各販売記事の隣に表示されます。"])},
    "vatNumberInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km が必須でない場合は、フィールドを空のままにしておきます。"])}
  },
  "settingsTemplates": {
    "contentPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンテンツのプレビュー"])},
    "createInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メール テンプレートを作成し、送信時刻をスケジュールし、関連サービスを割り当てます。"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートの作成"])},
    "createNewExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービステンプレートの作成"])},
    "createNewHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハウジングテンプレートの作成"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートの削除に失敗しました"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートは正常に削除されました"])},
    "experienceDurationChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訪問日数"])},
    "experienceEndChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日時"])},
    "experienceStartChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス開始日時"])},
    "forHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住宅用"])},
    "forService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス用"])},
    "housingDurationChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訪問の泊数"])},
    "housingEndChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終チェックアウト時間"])},
    "housingStartChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックイン日時の範囲"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メールのテンプレート"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここでは、顧客に自動的に送信される電子メールを設定できます"])},
    "relatedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連サービス"])},
    "relatedTimings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連するタイミング"])},
    "restrictionCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックインフォームが送信された場合は送信しない"])},
    "restrictionCheckInInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メール送信のチェックを適用します。クライアントがチェックイン フォームを送信した場合、電子メールは送信されません"])},
    "restrictionNoCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェックインフォームが送信されていない場合は送信しないでください"])},
    "restrictionNoCheckInInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メール送信のチェックを適用します。クライアントがチェックイン フォームを送信していない場合、電子メールは送信されません。"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートの保存に失敗しました"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートが保存されました"])},
    "sendRetroactively": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存の予約に遡って送信する"])},
    "sendRetroactivelyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存の予約に電子メールを送信する場合に選択します。\n予約に関してメールテンプレート設定のタイミングを過ぎていればメールが送信されます。"])},
    "serviceCreateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここでは、このサービス記事に関連する電子メール テンプレートを設定できます。\nすべての電子メール テンプレートを編集するには、設定ビューに移動してください"])},
    "serviceCreateWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メール テンプレートのコンテンツ、タイミング、その他の設定を変更すると、その変更はこのテンプレートに関連するすべての販売記事に適用されることに注意してください。"])},
    "serviceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このテンプレートに関連する販売を選択してください"])},
    "timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイミング"])},
    "timingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手紙のタイミングを選ぶ"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル"])}
  },
  "settingsUsers": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー名と電子メール アドレスを入力し、役割を設定して招待を受け取ります。\n新しいユーザーは、電子メールに送信されたメッセージを確認した後、割り当てられた役割に従って作業を開始できます。"])},
    "tabs": {
      "companyRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の役割"])},
      "usersRignts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの権利"])}
    },
    "userRolesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの役割と役割の権限を定義し、各ユーザーに役割を割り当てます。\nユーザーは、そのロールで有効になっているコンテンツのみを表示および編集できます。"])}
  },
  "showAllAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのアメニティを表示"])},
  "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示を少なくする"])},
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと見せる"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
  "socialNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソーシャルネットワークアカウント"])},
  "somethingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何かが失敗しました"])},
  "specialRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別なお願い"])},
  "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別なお願い"])},
  "specifyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所の詳細を指定する"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["始める"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始日"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["州"])},
  "statisticsReport": {
    "companyInformation": {
      "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["組織名"])},
      "organizationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["組織"])}
    }
  },
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状態"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通り"])},
  "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブカテゴリ"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まとめ"])},
  "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スーパーバイザー"])},
  "support": {
    "card": {
      "title": {
        "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ"])},
        "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージを送る"])}
      }
    },
    "contactHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["午前9時～午後5時"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール"])},
    "facebookGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フェイスブックグループ"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サポート"])},
    "supportExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このフォームを送信すると、Touringery サイトのサポート担当者に手紙が送信されます。\nこのフォームの目的は、フィードバックを提供し、サイトに関する技術的な問題を報告することです。"])},
    "tabLabel": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接触"])},
      "helpCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプセンター"])}
    },
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])}
  },
  "switchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社を切り替える"])},
  "tabs": {
    "servicePeriodsForEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])}
  },
  "takeMeThere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私をそこへ連れていって"])},
  "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク"])},
  "taskAcceptSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクが受け入れられました"])},
  "taskColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクの色"])},
  "taskColorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクの色は、カレンダー ビューのタスク カードの色を表します。\nこれによりコントラストが向上し、カレンダーがより読みやすくなります。"])},
  "taskCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクが正常に作成されました"])},
  "taskCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクの作成に失敗しました"])},
  "taskDeleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクの削除に失敗しました"])},
  "taskDeletedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクは正常に削除されました"])},
  "taskDurationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク期間は、タスク タイプがオープン タイムスロットに設定されている場合にのみ使用できます。\nタスクの期間を使用すると、受信者が空き時間にタスクを実行できるように、開始日と期限の差を差し引いた期間を決定できます。"])},
  "taskEndedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクは終了したため、これ以上変更できません"])},
  "taskGeneratedCreatedSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["自動タスク「", _interpolate(_named("taskTitle")), "」が正常に作成されました"])},
  "taskGeneratedCreationError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["自動タスク「", _interpolate(_named("taskTitle")), "」の作成に失敗しました"])},
  "taskGeneratedDeleteError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["自動タスク「", _interpolate(_named("taskTitle")), "」の削除に失敗しました"])},
  "taskGeneratedDeleteSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["自動タスク「", _interpolate(_named("taskTitle")), "」は正常に削除されました"])},
  "taskGeneratedUpdateError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["自動タスク「", _interpolate(_named("taskTitle")), "」の更新に失敗しました"])},
  "taskGeneratedUpdatedSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["自動タスク「", _interpolate(_named("taskTitle")), "」が正常に更新されました"])},
  "taskGenerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動化されたタスク"])},
  "taskGeneratorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ここでは、指定された変数によって自動生成されるタスクを定義できます。"])},
  "taskHasEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクが終了しました"])},
  "taskInformationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク情報が保存されます"])},
  "taskInformationSavedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク情報の保存に失敗しました"])},
  "taskMarkedAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクは完了としてマークされています"])},
  "taskMarkedAsNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクは未完了としてマークされています"])},
  "taskPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク期間"])},
  "taskRefuseSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクが拒否されました"])},
  "taskRefuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクを拒否しますか？"])},
  "taskReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信者へのリマインダー"])},
  "taskReminderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスクリマインダー時刻は、今後のタスクを電子メールで受信者に通知します"])},
  "taskWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上司への警告"])},
  "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク"])},
  "teamPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チームの権限"])},
  "termsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約"])},
  "textEditor": {
    "enterLinkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクを入力"])}
  },
  "thisMonthResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今月の結果"])},
  "ticket": {
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い手"])},
    "fetchTicketError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットデータの取得中にエラーが発生しました"])},
    "invalidScannedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキャンされたコードの内容が予期しない形式です"])},
    "notValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未検証"])},
    "paidAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["で支払われます"])},
    "ticketLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット"])},
    "ticketsValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証する"])},
    "unValidateTicketSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットの検証は正常に一時停止されました"])},
    "unvalidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スイッチの検証ステータス"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
    "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まで有効"])},
    "validateTicketError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケット検証エラー"])},
    "validateTicketSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットは正常に検証されました"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証済み"])},
    "validatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証時間"])},
    "validatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証者"])},
    "validationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証"])},
    "validationState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証状態"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効"])}
  },
  "ticketValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証に成功しました"])},
  "ticketValidationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証に失敗しました"])},
  "ticketValidity": {
    "disclaimer": {
      "daysFromPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入からの日数"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["それまで"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["に有効"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から有効"])},
      "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まで有効"])},
      "validDuringWholeTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットは選択したタイムスロット全体で有効です。"])},
      "validUntilIsLimitedByEndDynamic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["チケットはアクティベーションから ", _interpolate(_named("duration")), " の間、またはタイムスロットの終了までのいずれか早い方まで有効です。"])},
      "validUntilNotLimitedByEndDynamic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["チケットはアクティベーションから ", _interpolate(_named("duration")), " の間有効です。"])}
    },
    "fixedDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定日"])},
    "fromUntilDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始日と終了日"])},
    "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格の有効期間を定義すると、このデータは指定された期間のチケットに存在します。\n日付の形式と購入日までを定義することも、購入から開始する期間を設定することもできます"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格の有効期間"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未定義"])},
    "periodFromPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入からの期間"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期間の種類"])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["に"])},
  "toDetailView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開ける"])},
  "total": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["額"])},
    "payBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金額"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払う金額"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "totalWithDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引を含む合計"])},
    "totalWithoutVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT抜きの合計"])}
  },
  "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計金額"])},
  "totalTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊サービスの販売収入"])},
  "travelPurpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行の目的"])},
  "travelingToDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目的地への旅行"])},
  "travellersCountry": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行者の市民権"])},
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国コード"])},
    "housingOvernight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宿泊旅行者の数"])},
    "roomsSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行者数"])}
  },
  "travellingPurposesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅行目的に応じた宿泊人数の配分"])},
  "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き金"])},
  "trigon": {
    "checkBoxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリゴン連携が有効"])},
    "emailPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigonの請求書専用メール"])},
    "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigon統合を有効にすると、すべての請求書のメールのコピーが自動的にTrigonに送信され、請求書はより簡単に処理できるようにデジタル化されます。Trigonから一意の識別子を持つメールを受け取り、Touringeryはすべての請求書をそこに送信します。"])},
    "settingChangeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定の保存中に問題が発生しました"])},
    "settingChangedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定が正常に保存されました"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリゴン連携"])}
  },
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ"])},
  "types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["種類"])},
  "unCheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのチェックを外します"])},
  "unauthorized": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このビューにアクセスする権限がありません"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無許可"])}
  },
  "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユニット"])},
  "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["それまで"])},
  "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今後の予定"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップデート"])},
  "updateCartItemError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートの更新に失敗しました"])},
  "updateCartItemSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートが正常に更新されました"])},
  "updateInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートの更新"])},
  "uploadNewAvatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいアバターをアップロードする"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
  "userNotAssociatedWithCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーは会社に関連付けられていません"])},
  "userRoleSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー役割の保存に失敗しました"])},
  "userRoleSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー役割が正常に保存されました"])},
  "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["休暇"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バット"])},
  "vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT番号"])},
  "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジター"])},
  "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訪問者"])},
  "warningInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タスク警告時間は、未反応のタスクについて受信者とスーパーバイザーに電子メールで警告します"])},
  "week": {
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金曜日"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月曜日"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土曜日"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日曜日"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木曜日"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["火曜日"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["水曜日"])}
  },
  "weekSlots": {
    "buttons": {
      "setClear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空の"])},
      "setNineToFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["営業日の9～17日"])},
      "setTenToEight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日 10 ～ 20 件"])},
      "setWholeWeek ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一週間中"])}
    }
  },
  "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いつ"])},
  "whenAreYouGoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いつ行きますか？"])},
  "where": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どこ"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仕事"])},
  "wrongUserNameOrPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間違ったユーザー名またはパスワード！"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい"])},
  "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのプロフィール"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])}
}